import socket from './../socket';

export const  CONFIGURATION = 'CONFIGURATION';
export const config = param =>{
  return dispatch => {
    socket.send({
      command: 'watchdogconfig',
      ...param,
    });
    dispatch({type: CONFIGURATION,...param});
  };
};

export const  CONFIGURATION_CLEAR = 'CONFIGURATION_CLEAR';
export const configClear = filename =>{
  return dispatch => {
    dispatch({type: CONFIGURATION_CLEAR,filename});
  };
};

