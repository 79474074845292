import {compact,cloneDeep, get } from 'lodash';

const initialState = {
  kioskid: null,
  id: null,
  sessions: {},
  terminal2sessions: {},
};

export default function vnc(state = initialState, action) {
  switch (action.type) {
    case 'RECEIVE_SESSIONCOMMAND':
      return recieveSessionCommand(state, action);
    case 'RECEIVE_SESSIONNEW':
      return recieveSessionNew(state, action);
    case 'TERMINAL_NEW':
      return terminalNew(state, action);
    case 'TERMINAL_COMMAND':
      return terminalCommand(state, action);
    case 'TERMINAL_CLOSED':
      return terminalClose(state, action);
    case 'RECEIVE_TERMINAL2':
      return receiveTerminal2(state, action);
    case 'TERMINAL2_HIDE':
      return terminal2Hide(state, action);
    case 'TERMINAL2_SHOW':
      return terminal2Show(state, action);
    case 'TERMINAL2_ATTACH':
      return terminal2Attach(state,action);
    case 'HANDLE_LOGOUT':
      return cloneDeep(initialState);
    default:
      return state;
  }
}

function terminalNew(state,action) {
  let {kioskid} = action.data;
  return {...state,kioskid}
}

function terminalCommand(state, action) {
  const {sessions} = state;
  const {id,what,kioskid} = action.data;
  if(!sessions[kioskid]) {
    sessions[kioskid] = {}
  }
  if(!sessions[kioskid][id]) {
    sessions[kioskid][id] = []
  }
  let lastIndex = sessions[kioskid][id].length -1;
  if(sessions[kioskid][id][lastIndex] === "$ ") {
    sessions[kioskid][id][lastIndex] = sessions[kioskid][id][lastIndex] + what
  } else {
    sessions[kioskid][id] = [...sessions[kioskid][id],what];
  }
  return {...state,sessions:{...sessions},kioskid }
}

function terminalClose(state, action) {
  const {sessions} = state;
  const {kioskid,id} = action.data;
  delete sessions[kioskid][id];
  return {...state,kioskid, sessions: {...sessions} }
}

function recieveSessionCommand(state, action) {
  const {sessions,kioskid} = state;
  const {id,what} = action.data;
  if ((!kioskid && what === '[ session timeout ]') ||
    (!kioskid && what ==='[ session closed ]')) {
    return state;
  }
  if(!sessions[kioskid]) {
    sessions[kioskid] = {};
  }
  if(!sessions[kioskid][id]) {
    if(what === '[ session closed ]' ||
      what === '[ session timeout ]') {
      return state;
    }
    sessions[kioskid][id] = [];
  }
  let textWithNewLine = compact(what.split(/\r\n|\r|\n/g));
  sessions[kioskid][id] = [...sessions[kioskid][id],...textWithNewLine];
  return {...state,sessions: {...sessions} }
}

function recieveSessionNew(state, action) {
  const {sessions,kioskid} = state;
  const {id} = action.data;
  if(!sessions[kioskid]) {
    sessions[kioskid]= {};
  }
  sessions[kioskid][id] = [];
  return {...state,sessions: {...sessions},id }
}

function receiveTerminal2(state, action) {
  let kioskid = get( action, 'data.kioskid' );
  let id = get( action, 'data.id' );
  let new_state = { ...state };

  if( !( kioskid in new_state.terminal2sessions ) )
    new_state.terminal2sessions[kioskid] = {};

  let sessions = { ...new_state.terminal2sessions[kioskid] };

  switch( get( action, 'data.action' ) ) {
    case 'list':
      for( let i of get( action, 'data.ids' ) ) {
        if( !( i in sessions ) )
          sessions[i] = { visible: false };
      }
      break;
    case 'add':
      if( !( id in sessions ) ) {
        sessions[id] = { visible: true };
      }
      break;
    case 'closed':
    case 'remove':
    case 'timeout':
      if( id in sessions ) {
        // TODO: close/delete terminal
        delete sessions[id];
      }
      break;
    case 'join':
      if( id in sessions ) {
        sessions[id] = { ...sessions[id], visible: true };
      }
      break;
    case 'leave':
      if( id in sessions ) {
        sessions[id] = { ...sessions[id], visible: false };
      }
      break;
    case 'data':
      if( id in sessions ) {
        let data = new Buffer( get( action, 'data.data' ), 'base64' );
        if( sessions[id].terminal ) {
          sessions[id].terminal.write( data );
        } else {
          sessions[id] = { ...sessions[id], pending_data: [ 
            ...( sessions[id].pending_data || []), data ] };
        }
      }
      break;
  };

  new_state.terminal2sessions = { ...new_state.terminal2sessions, [kioskid]: sessions };

  return new_state;
}

function terminal2Hide(state, action) {
  let kioskid = get( action, 'data.kioskid' );
  let id = get( action, 'data.id' );

  // console.log( state, kioskid, id, action, state.terminal2sessions[kioskid] );

  return { ...state,
    terminal2sessions: { ...state.terminal2sessions,
      [kioskid]: { ...state.terminal2sessions[kioskid],
        [id]: { ...state.terminal2sessions[kioskid][id],
          visible: false,
        },
      },
    },
  };
}

function terminal2Show(state, action) {
  let kioskid = get( action, 'data.kioskid' );
  let id = get( action, 'data.id' );

  console.log( state, kioskid, id, action, state.terminal2sessions[kioskid] );

  return { ...state,
    terminal2sessions: { ...state.terminal2sessions,
      [kioskid]: { ...state.terminal2sessions[kioskid],
        [id]: { ...state.terminal2sessions[kioskid][id],
          visible: true,
        },
      },
    },
  };
}

function terminal2Attach(state, action) {
  // console.log( state, action );
  // triggered from Terminal2ModalGroup::componentDidUpdate
  const { kioskid, id, term, div } = get( action, 'data' );

  const session = state.terminal2sessions[kioskid][id];

  console.log( `terminal2(${kioskid}/${id}): opening new terminal` );
  term.open( div );

  for( let data of get( session, 'pending_data', [] ) ) {
    console.log( `terminal2(${kioskid}/${id}): replaying data`, data );
    term.write( data );
  }

  return { ...state,
    terminal2sessions: { ...state.terminal2sessions,
      [kioskid]: { ...state.terminal2sessions[kioskid],
        [id]: { ...session, terminal: term, pending_data: [] }
      }
    }
  };
}
