import socket from './../socket';

const VIDEO_TYPES = {
  ilot: 'ilot',
  kiosk: 'kiosk',
  web: 'web'
};

export const FETCH_VIDEOS = 'FETCH_VIDEOS';
export const fetchVideos = (type) => {
  return (dispatch, getState) => {
    let payload = {
      command: 'supportvideosget',
      type: VIDEO_TYPES[type]
    };
    socket.send(payload);
    dispatch({ type: FETCH_VIDEOS });
  }
};
