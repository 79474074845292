import {cloneDeep,get,findIndex,compact} from 'lodash';

const initialState = {
  device: [],
  kiosk: [],
};

export default function device(state = initialState, action) {
  switch(action.type) {
    case 'RECEIVE_DEVICEKIOSKLIST':
      return receiveDeviceList(state, action);
    case 'RECEIVE_DEVICEADDTOKIOSK':
      return receiveDeviceAdd(state, action);
    case 'RECEIVE_DEVICEREMOVEFROMKIOSK':
      return receiveDeviceRemoveFromKiosk(state, action);
    case 'RECEIVE_DEVICEREMOVED':
      return receiveDeviceRemoved(state, action);
    case 'HANDLE_LOGOUT':
      return cloneDeep(initialState);
    default:
      return state;
  }
}

function receiveDeviceList(state, action) {
  const {device,kiosk} = get(action,'data');
  return {...state,device,kiosk}
}

function receiveDeviceAdd(state, action) {
  const {device,kiosk} = state;
  const {deviceid} = get(action,'data');
  const dIndex = findIndex(device,['deviceid',deviceid]);
  kiosk.unshift(device[dIndex]);
  device[dIndex] = false;
  return {...state,kiosk:[...kiosk],device:[...compact(device)]}
}

function receiveDeviceRemoveFromKiosk(state, action) {
  const {device,kiosk} = state;
  const {deviceid} = get(action,'data');
  const kIndex = findIndex(kiosk,['deviceid',deviceid]);
  device.unshift(kiosk[kIndex]);
  kiosk[kIndex] = false;
  return {...state,kiosk:[...compact(kiosk)],device:[...device]}
}

function receiveDeviceRemoved(state, action) {
  const {deviceid} = get(action,'data');
  const {device} = state;
  const index = findIndex(device,['deviceid',deviceid]);
  device[index] = false;
  return {...state, device:[...compact(device)]}
}
