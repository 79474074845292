import {cloneDeep,findKey,assign} from 'lodash';
const initialState = {
  status: 'unfetched',
  tags: []
};

export default function tagRanges(state = initialState, action) {
  switch (action.type) {
    case 'RECEIVE_TAGRANGESGET':
      return {
        ...state,
        tags: action.data,
        status: 'fetched'
      };
    case 'RECEIVE_TAGRANGESRESERVE':
      return recieveTagRangesReserve(state, action);
    case 'TAG_RANGES_GET':
      return {
        ...state,
        status: 'pending'
      };
    case 'APPLY_TAGRANGESGET':
      return initialState;
    case 'HANDLE_LOGOUT':
      return cloneDeep(initialState);
    default:
      return state;
  }
}

function recieveTagRangesReserve(state, action) {
  const key = findKey(state,'ranges');
  const tags = cloneDeep(state[key]);
  const data = assign(tags,action.data);

  return {
      ...state,
      tags: data,
    }
}
