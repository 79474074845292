import _ from 'lodash';

const initialState = {
  status:'unfetched',
  adminErrorlog: []
};

export default function adminErrorLog(state = initialState, action) {
  switch(action.type) {
  case 'RECEIVE_ADMINERRORLOGGET':
    let data = _.get(action, 'data.adminlogerrorget');
    return {data, status:'fetched'};
  case 'HANDLE_LOGOUT':
    return _.cloneDeep(initialState);
  default:
    return state;
  }

}
