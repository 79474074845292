import _ from 'lodash';

const initialState = {
  status: 'unfetched',
  list: []
};

export default function kiosks(state = initialState, action) {
  switch (action.type) {
    case 'RECEIVE_PATCHLIST':
      return {
        ...state,
        list: action.data.patches,
        status: 'fetched'
      }
    case 'FETCH_PATCHLIST':
      return {
        ...state,
        status: 'pending'
      }
    case 'APPLY_PATCH':
      return initialState;
    case 'HANDLE_LOGOUT':
      return _.cloneDeep(initialState);
    default:
      return state;
  }
}
