import _ from 'lodash';

const initialState = [];

export default function permissions(state = initialState, action) {
  switch (action.type) {
    case 'RECEIVE_HIERARCHYGET':
      return _.map(action.data.hierarchy.permissions, (permission) => {
        return permission;
      });
    case 'HANDLE_LOGOUT':
      return _.cloneDeep(initialState);
    default:
      return state;
  }
}
