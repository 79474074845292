import {cloneDeep, get } from 'lodash';

const initialState = {
  devices:[]
};

export default function twofractorauthenication(state = initialState, action) {
  switch (action.type) {
    case 'RECEIVE_TWOFACTORAUTHENICATIONGETLIST':
      return receiveTwofactorAuthenicationGetList(state,action);
    case 'RECEIVE_TWOFACTORAUTHENICATIONACTIVE':
      return receiveTwofactorAuthenicationActive(state,action);
    case 'HANDLE_LOGOUT':
      return cloneDeep(initialState);
    default:
      return state;
  }
}

function receiveTwofactorAuthenicationGetList(state,action) {
  let {enabled,deviceid} = get(action,'data',{});
  let devices = state.devices.reduce((array,device) => {
    if(device.deviceID === deviceid) {
      device = {...device,enabled}
    }
    array = [...array,device];
    return array
  },[]);
  return {
    ...state,
    devices: [...devices],
  };
}

function receiveTwofactorAuthenicationActive(state,action) {
  let mobile = get(action,'data.mobile',[]);
  return {
    ...state,
    devices: mobile,
  };
}
