import {
  set,
  map,
  keyBy,
  reduce,
  merge,
  compact,
  flow,
  defaultTo,
  isNil,
  cloneDeep,
} from 'lodash/fp';
import _ from 'lodash';
import buildTable from '../Assets/assetBuildTableFunction';

import {
  FETCH_ALL_ASSETS,
  RECEIVE_ALL_ASSETS,
  RECEIVE_ASSET,
  RECEIVE_ASSETARCHIVEDSET,
  RECEIVE_HISTORY,
  CLEAR_HISTORY,
  CHANGE_ASSET_LIST_QUERY,
} from '../actions/assets';
import { SWITCH_CONTAINERS } from '../actions/containers';
import {AssetNormalize, TableFormat} from "../Assets/AllAssetsHelperFn";

const initialState = {
  containerID:null,
  list: {},
  assets: [],
  searchstring: null,
  listQuery: {
    filter: '',
    limit: 50,
    offset: 0,
  },
  tabledata: [],
  listTotalResults: null,
  history: null,
  isFetching: false,
  lastViewedID: null,
  tablehead: [],
  tablebody: []
};

export class Detail {
  constructor({ detail, editable, fieldName, sort} = {}) {
    this.value = detail;
    this.isEditable = _.toString(editable) === '1';
    this.fieldName = fieldName;
    this.sort = sort;
  }
}

const normalizeDetails = reduce((normalized, detail) => {
  const path = _.split(detail.fieldName,'_');
  if (path.length === 1) {
    path.unshift('general');
  }
  return set(path, new Detail(detail), normalized);
}, {});

const normalizeInventory = ( details ) => {
  return _.reduce( details, (a,v)=>{
    return _.assign(a,{[v.fieldName]: v.detail});
  },{});
};

function normalizeAsset(asset) {
  let {
    assetID,
    assetid,
    assetName,
    assetname,
    archive,
    active,
    details,
    images,
    lastLocation,
    Identifier,
    condition,
    fullinfo,
    tags,
  } = asset;
  const maybeAssetID = defaultTo(assetid, assetID);
  const stockNumber = asset['Stock Number'];
  // Some assets are effectively blank for some reason.
  if (isNil(maybeAssetID)) {
    return null;
  }
  let soloKiosk = _.filter(tags, t => t.kiosktype === 2);
  soloKiosk = _.map(soloKiosk, k =>{
    let value = k.onKioskID;
    let label = k.onWhat;
    return {...k,value,label};
  });
  return {
    assetID: `${maybeAssetID}`,
    name: defaultTo(assetname, assetName),
    isArchived: _.toString(archive) === '1',
    isActive: _.toString(active) === '1',
    inventory: details ? normalizeInventory(details) : null,
    details: details ? normalizeDetails(details) : null,
    images: images || [],
    stockNumber: stockNumber,
    lastLocation,
    Identifier,
    condition,
    fullinfo,
    soloKiosk
  };
}

const normalizeAssetList = flow(map(normalizeAsset), compact, keyBy('assetID'));


function normalizeHistoryItem(historyItem) {
  let imagelist = historyItem.imagelist;
  return {
    createdOn: historyItem.createddt,
    createdBy: historyItem.createduidname,
    closedOn: historyItem.closeddt,
    closedBy: historyItem.closeduidname,
    kioskName: historyItem.kioskname,
    imagelist,
    kioskid:historyItem.kioskid,
    transactionid:historyItem.transactionid,
    tagID: historyItem.tagid,
    transactionType: historyItem.transactiontypename,
    reason: historyItem.reason,
  };
}

export default function assets(state = initialState, { type, data, ...props }) {
  switch (type) {
    case FETCH_ALL_ASSETS: {
      let list = state.list;
      if(state.isArchived !== props.archive || state.containerID !== props.containerID) {
        // do not cache archive asset,
        list = {};
      }
      return {
        ...state,
        containerID:props.containerID,
        list,
        isFetching: true,
      };
    }

    case RECEIVE_ALL_ASSETS: {
      // let list;
      // if(data.searchstring) {
      //   list = normalizeAssetList(data.assets);
      //   // list = {[data.containerid]:normalizeAssetList(data.assets)};
      // } else {
      //   // caching asset list
      //   list = {...state.list,...normalizeAssetList(data.assets)};
      //   // list = {[data.containerid]:{...state.list,...normalizeAssetList(data.assets)}};
      // }
      //
      // return {
      //   ...state,
      //   isArchived:state.listQuery.archive,
      //   list,
      //   isFetching: false,
      //   listTotalResults: data.totalresults,
      // };
      let searchstring = data.searchstring;
      // let filter = state.listQuery.filter;
      let isNewSearch = searchstring !== state.searchstring;
      let isSoldChange = state.listQuery.archive !== data.archive;
      // Clear data when new search is true or cache same search assets
      let assets = isNewSearch || isSoldChange ? data.assets : _.uniqBy([...state.assets,...data.assets],'assetID');
      let list = normalizeAssetList(assets);
      return {
        ...state,
        assets,
        searchstring,
        isArchived:state.listQuery.archive,
        list,
        tabledata: TableFormat(AssetNormalize(list)),
        isFetching: false,
        listTotalResults: data.totalresults,
      };
    }

    case RECEIVE_ASSET: {
      const asset = normalizeAsset(data);
      if (!asset) {
        return state;
      }
      const {assets} = state;
      const assetID = _.get(asset,'assetID');
      const index = _.findIndex(assets,['assetID', assetID]);
      if(index === -1) {
        assets.push({...data,assetID})
      }
      return {
        ...state,
        ...set(`list[${asset.assetID}]`, asset, state),
        assets: [...assets],
        lastViewedID: asset.assetID,
      };
    }

    case RECEIVE_ASSETARCHIVEDSET: {
      // if (!state.list[data.assetid]) {
      //   return state;
      // }
      // const isArchived = data.archive === '1';
      // return set(`list[${data.assetid}].isArchived`, isArchived, state);
      const assets = _.get(state,'assets');
      const assetid = _.get(data,'assetid');
      const index = _.findIndex(assets,['assetID', assetid]);
      if ( index === -1 ) return state;
      const archive = _.get(data,'archive').toString();

      const asset = assets[index];
      if (!asset) return state;
      assets[index] = {...asset,archive};
      let isArchived = archive === '1';
      const list = {...state.list,[assetid]:{...state.list[assetid],isArchived,archive:archive ==='0'}};
      const tabledata = _.get(state,`tabledata`);
      const i = _.findIndex(tabledata,['assetID', assetid]);
      const tablerow = _.head(TableFormat(AssetNormalize({[assetid]:list[assetid]})));
      if (!tablerow) return;
      tabledata[i] = tablerow;

    return { ...state, assets:[...assets], tabledata: [...tabledata], list }
    }

    case RECEIVE_HISTORY: {
      // return { ...state, history:data.history};
      return { ...state, history: map(normalizeHistoryItem, data.history) };
    }

    case CLEAR_HISTORY: {
      return { ...state, history: null };
    }

    case SWITCH_CONTAINERS: {
      return {
        ...state,
        listQuery: initialState.listQuery,
      };
    }

    case CHANGE_ASSET_LIST_QUERY: {
      return {
        ...state,
        assets: [],
        listQuery: merge(state.listQuery, {
          filter: props.filter,
          limit: props.limit,
          archive: props.archive,
          offset: isNil(props.filter) || props.filter === state.listQuery.filter
            ? props.offset
            : initialState.listQuery.offset,
        }),
      };
    }
    case 'RECEIVE_ASSETEVENTHISTORYGET': {
      return assetEventHistory(state,data)
    }
    case 'RESTART_ASSET_FETCH': {
      return {...state,isFetching:false}
    }
    case 'HANDLE_LOGOUT':
      return cloneDeep(initialState);

    default: {
      return state;
    }
  }
}

function assetEventHistory(state,data) {
  const {tablehead, tablebody} = buildTable(data.events);
  return {...state,tablehead, tablebody};
}
