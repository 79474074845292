import React from 'react';
import { Link } from 'react-router-dom';
import { compact, keys, head, map } from 'lodash';

export const assetLink = ( assetid, string ) =>  (
  <Link
    to={`${process.env.PUBLIC_URL}/assets/${assetid}`}
    className="btn link"
  >{string}</Link>
);

export const userLink = ( userid, string ) => (
  <Link
    to={`${process.env.PUBLIC_URL}/users/${userid}`}
    className="btn link"
  >{string}</Link>
);

export const detailLink = ( customerid, string ) => (
  <Link
    to={`${process.env.PUBLIC_URL}/customer/detail/${customerid}`}
    className="btn link"
  >{string}</Link>
);

const buildTable = data => {
  let tablebody = map(data, obj => {
    let {
      assetid,
      assetname,
      username,
      userid,
      customerid,
      customername
    } = obj;

    // Building links for Asset Table
    let build = {};
    if (assetid && assetname) build.assetname = assetLink(assetid,assetname);
    if (userid && username) build.username = userLink(userid,username);
    if (customerid && customername) build.customername = detailLink(customerid,customername);

    return { ...obj, ...build };
  });

  const datakeys = keys(head(data));
  const tablehead = compact(map(datakeys,accessor =>
    (!accessor.includes('id')) ? {Header:accessor,accessor} : ''));

  return { tablehead, tablebody }
};

export default buildTable;
