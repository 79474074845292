import {
  isNumber,
  get,cloneDeep,
  findIndex,
  map,
  reduce,unset,toString} from 'lodash';

const initialState = {};

export default function users(state = initialState, action) {
  switch (action.type) {
    case 'RECEIVE_GROUPLIST':
      // Normalize key names of users
      let users = reduce(action.data.users, (obj,user) => {
        obj[user.id] = {...state[user.id],...normalizeUser(user)};
        return obj;
      },{});
      return {...state,...users};
    case 'RECEIVE_USERNAMESET':
      delete action.data.userid; // userid is not target userid it's current userid
      let updatedUser = normalizeUser(action.data);
      state[updatedUser.userID] = {...state[updatedUser.userID],...updatedUser};
      return {...state};
      // return map(state, (user) => {
      //   if (user.userID === updatedUser.userID) {
      //     return assign({}, user, { fName: updatedUser.fName, lName: updatedUser.lName });
      //   } else {
      //     return user;
      //   }
      // });
    case 'RECEIVE_USERACTIVATE':
      return receiveUserActivate(state, action.data);
    case 'RECEIVE_USERAUTHSGET':
      return receiveAuthentications(state, action.data);
    case 'RECEIVE_USEREXPIRATIONSET':
      return receivUserExpirationSet(state, action.data);
    case 'RECEIVE_USERCREATE':
      let newUser = normalizeUser(action.data[0]);
      state[newUser.userID] = newUser;
      return {...state};
      // return concat(state, newUser);
    case 'RECEIVE_USERINFOMODIFY':
      return recieveUserInfoModify(state,action);
    case 'RECEIVE_USERAUTHSET':
      return recieveUserAuthSet(state,action);
    case 'RECEIVE_USERUPDATECOMPANY':
      return recieveUserUpdateCompany(state,action);
    case 'RECEIVE_USERDEVICEGETUSERMOBILE':
      return recieveUserDeviceGetUserMobile(state,action);
    case 'RECEIVE_USERDEVICECHANGEUSERMOBILESTATE':
      return recieveUserDeviceChangetUserMobileState(state,action);
    case 'HANDLE_LOGOUT':
      return cloneDeep(initialState);
    case 'EDIT_USER_INFO':
      return editUserInfo(state,action);
    default:
      return state;
  }
}

function recieveUserDeviceChangetUserMobileState(state,action) {
  let userid = get(action,'data.userid');
  let device = get(action,'data.device');
  let deviceid = get(device,'deviceid');
  let user = get(state,userid);
  let deviceState = get(device,'state');
  let updateDevice = map(user.devices, d => {
    return d.deviceid === deviceid ? {...d,state:deviceState} : d
  });

  return {...state,[userid]:{...user,devices:updateDevice}};
}

function recieveUserDeviceGetUserMobile(state,action) {
  let userid = get(action,'data.userid');
  let devices = get(action,'data.devices');
  let user = {...state[userid],devices};
  return {...state,[userid]:user};
}

function recieveUserUpdateCompany(state,action) {
  const user = normalizeUser(action.data);
  state[user.userID] = {...state[user.userID], ...user};
  return {...state};
}

function receiveUserActivate(state, data) {
  unset(data,'userid');  // userid is the login user id not the target user. Need to be remove
  let updatedUser = normalizeUser(data);
  state[updatedUser.userID] = {...state[updatedUser.userID],...updatedUser};
  return {...state}
}

function receiveAuthentications(state, data) {
  let {authentications,userinfo,userid} = data;
  userinfo = reduce( userinfo, ( a, v ) => {
    a[get( v, 'key' )] = get( v, 'value' );
    return a;
  }, {} );

  let userID = get(authentications, '[0].userID') || userid;

  if (userID) {
    state[userID] = {
      ...state[userID],
      authentications,
      userinfo
    };
  }
  return {...state}
}

function receivUserExpirationSet(state, data) {
  let userid = get(data[0],'userID');
  state[userid] = {...state[userid],...data[0]};
  // let index = findIndex(state,['userID', userid]);
  // state[index] = data[0];
  return {...state};
}

function normalizeUser(user) {
  let userID = user.id || user.userid || user.userID;
  let active = isNumber(user.a) ?
    user.a
    :
    user.active ?
      Number(user.active)
      :
      0;
  let companyID = user.c || user.companyid || user.companyID;
  return {
    department: user.department,
    active,
    companyID: toString(companyID),
    expirationDate: user.e || user.expirationdate || user.expirationDate,
    fName: user.f || user.fname || user.fName,
    lName: user.l || user.lname || user.lName,
    userID: `${userID}`
  };
}

function recieveUserInfoModify(state, action) {
  let infos = get( action, 'data.result' );
  let i = findIndex( infos,'userid');
  let {userid} = infos[i];
  if(i !== -1) {
    state[userid].userinfo = reduce(infos,(obj,info)=> {
      obj[info.key] = info.value;
      return obj;
    },{});
  }
  return {...state};
}

function recieveUserAuthSet(state, action) {
  const {
    authenticationstring,
    authenticationpassword,
    authenticationtype,
    userid,
  } = get( action, 'data' );

  let user = state[userid];
  let type = ['authenticationType',authenticationtype];
  let authIndex = findIndex(user.authentications,type);
  if(authIndex !== -1) {
    user.authentications[authIndex] = {
      ...user.authentications[authIndex],
      authenticationString: authenticationstring,
      authenticationPassword: authenticationpassword
    };
    state[userid] = user;
  }
  return {...state};
}

function editUserInfo(state, action) {
  let { key, value, userid } = action;
  state[userid] = {
    ...state[userid],
    userinfo: {
      ...state[userid].userinfo,
      [key] : value
    }
  };
  return {...state};
  // let users = [ ...state ];
  // let i = findIndex( users, { userID: userid } );
  //
  // if( i >= 0 ) {
  //   users[i] = { ...users[i], userinfo: { ...users[i].userinfo, [key]: value } };
  // }
  //
  // return users;
}
