import _, {cloneDeep} from 'lodash';

const initialState = {
  detail:[],
};

export default function dealers(state = initialState, action) {
  switch(action.type) {
    case 'RECEIVE_GETDEALERS':
      return receiveGetDealer(state, action);
    case 'HANDLE_LOGOUT':
      return cloneDeep(initialState);
    default:
      return state;
  }
}

function receiveGetDealer(state,action) {
  const detail = _.get(action,'data.dealers');
  return {...state,detail};
}

