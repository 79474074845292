
import _, {cloneDeep} from 'lodash';

const initialState = {
  users: [],
  userinfo: [],
  contacttypes: [],
};

export default function userinfo(state = initialState, action) {
  switch (action.type) {
    case 'RECEIVE_USERINFOGETBYCONTAINER':
      return recieveUserGet(state,action);
    case 'RECEIVE_USERINFOGETBYUSERID':
      return recieveSingleUserGet(state,action);
//    case 'RECEIVE_USERINFOMODIFY':
//      return recieveUserInfoModify(state,action);
    case 'RECEIVE_USERINFOCREATE':
      return recieveUserInfoAdd(state,action);
    case 'RECEIVE_USERINFODELETE':
      return recieveUserInfoDelete(state,action);
    case 'HANDLE_LOGOUT':
      return cloneDeep(initialState);
    default:
      return state;
  }
}

function recieveUserGet(state, action) {
  const users = _.get(action,'data.users');
  const userinfo = _.get(action,'data.userinfo');
  const contacttypes = _.get(action,'data.userinfokeys');
  return { ...state, users, userinfo, contacttypes }
}

function recieveSingleUserGet(state, action) {
  const userinfo = _.get(action,'data.user');
  return { ...state, userinfo }
}

function recieveUserInfoModify(state, action) {
  /*
  const modifyUser = _.get(action,'data.result');
  const id = _.get(modifyUser,'id');
  const userinfoState = _.get(state,'userinfo');
  const index = _.findIndex(userinfoState,{id});
  const userinfo = _.map(userinfoState,(user,i)=>{
    return i === index ? modifyUser : user
  }); */
  let infos = _.get( action, 'data.result' );
  let users = _.get( state, 'users' );

  for( let i_info in infos ) {
    let userID = _.get( infos[i_info], 'userID' );
    let i_user = _.findIndex( users, { userID } );
    let key = _.get( infos[i_info], 'key' );
    let value = _.get( infos[i_info], 'value' );

    if( i_user >= 0 ) {
      users[i_user].userinfo = { ...users[i_user].userinfo, key: value };
    }
  }

  return { ...state, users };
}

function recieveUserInfoAdd(state, action) {
  let data = _.get(action,'data.results');
  let user = _.find(state.users,{userid:data.userid});
  let name = _.get(user,'name');
  let datawithname = {...data,name};
  const userinfo = [...state.userinfo,datawithname];
  return { ...state,userinfo}
}

function recieveUserInfoDelete(state, action) {
  const id = _.get(action,'data.results.id');
  const userinfo = _.filter(state.userinfo,user=> user.id !==id);
  return {...state,userinfo}
}
