import socket from './../socket';
import _ from 'lodash';

export const FETCH_HIERARCHY = 'FETCH_HIERARCHY';
export const fetchHierarchy = () => {
  return (dispatch, getState) => {
    let payload = {
      command: "hierarchyget",
    };
    socket.send(payload);
    dispatch({ type: FETCH_HIERARCHY });
  }
};

export const FETCH_HIERARCHY_PERMISSION = 'FETCH_HIERARCHY_PERMISSION';
export const fetchHierarchyPermission = containerid => {
  return dispatch => {
    let payload = {
      command: "hierarchygetpermissions",
      containerid
    };
    socket.send(payload);
    dispatch({ type: FETCH_HIERARCHY_PERMISSION });
  }
};

export const SET_HIERARCHY = 'SET_HIERARCHY';
export const setHierarchy = ({containers, kiosks}) => {
  return (dispatch, getState) => {
    let payload = {
      command: "hierarchyset",
      hierarchy: {
        containers,
        kiosks
      }
    };
    socket.send(payload);
    dispatch({ type: SET_HIERARCHY });
  }
};

export const CREATE_NEW_CONTAINER = 'CREATE_NEW_CONTAINER';
export const createNewContainer = (containername, parentcontainer, netsuiteid ) => {
  return (dispatch, getState) => {
    let payload = {
      command: "containeradd",
      containername,
      parentcontainer,
      netsuiteid
    };
    socket.send(payload);
    dispatch({ type: CREATE_NEW_CONTAINER });
  }
};

export const DELETE_CONTAINER = 'DELETE_CONTAINER';
export const deleteContainer = (param) => {
  return (dispatch, getState) => {
    let payload = {
      command: "containerremove",
      ...param
    };
    socket.send(payload);
    dispatch({ type: DELETE_CONTAINER });
  }
};

export const UPDATE_CONTAINER_NAME = 'UPDATE_CONTAINER_NAME';
export const updateContainerName = (containerid, containername) => {
  return (dispatch, getState) => {
    let payload = {
      command: "containernameset",
      containerid,
      containername
    };
    socket.send(payload);
    dispatch({ type: UPDATE_CONTAINER_NAME });
  }
};

export const FETCH_TRANSACTION_REASONS = 'FETCH_TRANSACTION_REASONS';
export const fetchTransactionReasons = (containerid) => {
  return (dispatch, getState) => {
    let payload = {
      command: "transactionreasonsget",
      containerid
    };
    socket.send(payload);
    dispatch({ type: FETCH_TRANSACTION_REASONS });
  }
};

export const RENAME_TRANSACTION_REASON = 'RENAME_TRANSACTION_REASON';
export const renameTrasactionReason = (reasonId, newReason) => {
  return (dispatch, getState) => {
    let payload = {
      command: "transactionreasonsnameset",
      reasons: [
        {
          id: reasonId,
          reason: newReason
        }
      ]
    };
    socket.send(payload);
    dispatch({ type: RENAME_TRANSACTION_REASON });
  }
};

export const SET_TRANSACTION_REASONS = 'SET_TRANSACTION_REASONS';
export const setTransactionReasons = (containerid, reasons) => {
  return (dispatch, getState) => {
    // Separate out the active from the inactive
    let [active, inactive] = _.partition(reasons, (reason) => {
      return reason.active > 0;
    });

    // Set any active transaction reasons
    if (_.size(active) > 0 ){
      let payload = {
        command: "transactionreasonsset",
        containerid,
        reasons
      };
      socket.send(payload);
      dispatch({ type: SET_TRANSACTION_REASONS });
    }

    // Attempt to delete any inactive reasons
    if (_.size(inactive) > 0) {
      _.forEach(inactive, (inactiveReason) => {
        let payload = {
          command: "transactionreasonsdelete",
          id: inactiveReason.id
        };
        socket.send(payload);
      });
    }
  }
};


export const SWITCH_CONTAINERS = 'SWITCH_CONTAINERS';
export const switchContainers = (containerID) => {
  localStorage.setItem('currentContainer', containerID);
  return {
    type: SWITCH_CONTAINERS,
    containerID
  }
};

export const CREATE_DEFAULT_GROUPS = 'CREATE_DEFAULT_GROUPS';
export const createDefaultGroups = (containerid) => {
  return (dispatch, getState) => {
    socket.send({
      command: "containercreatedefaultgroups",
      containerid
    });
    dispatch({ type: CREATE_DEFAULT_GROUPS });
  }
};

export const SET_ROOT_CONTAINER = 'SET_ROOT_CONTAINER';
export const setRootContainer = ({containerID,companyID, root}) => {
  return (dispatch, getState) => {
    socket.send({
      command: 'containersetcontainerroot',
      containerid: Number(containerID),
      companyid: Number(companyID),
      root
    });
    dispatch({ type: SET_ROOT_CONTAINER});
  }
};

export const DRAWER_TIER_NAME_GET = 'DRAWER_TIER_NAME_GET';
export const drawerTierNamesGet = (containerID) => {
  return (dispatch, getState) => {
    socket.send({
      command: 'drawertiernamesget',
      containerid:containerID
    });
    dispatch({ type: DRAWER_TIER_NAME_GET, containerID});
  }
};

export const DRAWER_TIER_NAME_CHANGE = 'DRAWER_TIER_NAME_CHANGE';
export const drawerTierNamesChange = ({containerid,tierid,tiername}) => {
  return (dispatch, getState) => {
    socket.send({
      command: 'drawertierchange',
      containerid,
      tierid,
      tiername
    });
    dispatch({ type: DRAWER_TIER_NAME_CHANGE});
  }
};
