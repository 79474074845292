import _, {cloneDeep} from 'lodash';

const initialState = {
  fieldmappings: {},
  fields: {},
  ftp: {},
  scheduled: {},
};

export default function integrations(state = initialState, action) {
  switch (action.type) {
    case "RECEIVE_INTEGRATIONGET":
      return integrationGet(state,action);
    case 'HANDLE_LOGOUT':
      return cloneDeep(initialState);
    default:
      return state;
  }
}

function integrationGet(state,action) {
  let data = _.get(action,'data');
  let fieldmappings = _.get(data,'fieldmappings');
  let fields = _.get(data,'fields');
  let ftp = _.get(data,'ftp');
  let scheduled = _.get(data,'scheduled');

  return {...state,fieldmappings,fields,ftp,scheduled}
}
