import _, {cloneDeep} from 'lodash';

const initialState = {
  configs: {},
  configid: null,
  configname: ''
};

export default function deviceconfig(state = initialState, action) {
  switch(action.type) {
    case 'RECEIVE_CONFIGCREATE':
      return receiveConfigCreate(state, action);
    case 'RECEIVE_CONFIGGET':
      return receiveConfigGet(state, action);
    case 'RECEIVE_CONFIGMODIFY':
      return receiveConfigModify(state, action);
    case 'RECEIVE_CONFIGGETBYCONTAINER':
      return receiveConfigGetByContainer(state, action);
    case 'RECEIVE_CONFIGDEVICEGETCONFIG':
      return receiveConfigDeviceGetConfig(state, action);
    case 'HANDLE_LOGOUT':
      return cloneDeep(initialState);
    default:
      return state;
  }
}

function receiveConfigCreate(state, action) {
  let configs = _.get(state,'configs');
  let result = _.get(action,'data.result');
  let containerid = _.get(action,'data.containerid');
  if(configs[containerid]) {
    configs[containerid] = [...configs[containerid],result];
  } else {
    configs[containerid] = [result];
  }
  return { ...state, configs }
}

function receiveConfigGet(state, action) {
  const configs = _.get(action,'data.result.0');
  return {...state,configs}
}

function receiveConfigDeviceGetConfig(state, action) {
  const {configuration:configs,id:configid,configname} = _.get(action,'data.configs');
  return {...state,configs,configid,configname}
}

function receiveConfigModify(state, action) {
  const configs = _.get(action,'data.result.0');
  return {...state,configs}
}

function receiveConfigGetByContainer(state, action) {
  let configs = _.get(state,'configs');
  let containerid = _.get(action,'data.containerid');
  let result = _.get(action,'data.configs');
  if(configs[containerid]) {
    configs[containerid] = [...configs[containerid],...result];
  } else {
    configs[containerid] = result;
  }
  return { ...state, configs }
}
