import _, {cloneDeep, get } from 'lodash';

const initialState = {
  currentid: null,
  configurations: {},
  list: {},
  eventtypes: [],
  camera: {},
  events: {},
  sensorevents: {},
};

export default function securityEvent(state = initialState, action) {
  switch(action.type) {
    case 'RECEIVE_GETALLEVENTTYPE':
      return receiveGetAllEventType(state, action);
    case 'RECEIVE_GETSELECTEDSECURITYLOG':
      return receiveSelectedGetLog(state, action);
    case 'RECEIVE_GETSECURITYIMAGESBYDATE':
      return receiveGetSecurityImagesByDate(state, action);
    case 'RECEIVE_GETEVENTSBYKIOSKDATE':
      return receiveGetEventByKiosDate(state, action);
    case 'RECEIVE_CONFIGMODIFY':
      return receiveConfigModify(state, action);
    case 'RECEIVE_SECURITYEVENTLIST':
      return { ...state, sensorevents: { ...state.sensorevents,
        [get( action, 'data.kioskid' )]: get( action, 'data.events' ) } };
    case 'RECEIVE_SENSOREVENTDOWNLOAD':
      return { ...state, sensoreventurl: get( action, 'data.url' ) };
    case 'HANDLE_LOGOUT':
      return cloneDeep(initialState);
    default:
      return state;
  }
}

function receiveGetEventByKiosDate(state, action) {
  const {list} = state;
  let events = _.get(action,'data.events');
  let configurations = _.get(action,'data.configurations');
  let containerid = _.get(action,'data.containerid');
  list[containerid] = events;
  return {...state,list,configurations};
}

function receiveGetAllEventType(state, action) {
  // list of eventype for create radio button
  let eventtypes = _.get(action,'data.eventtypes');
  return {...state,eventtypes};
}

function receiveConfigModify(state, action) {
  let configurations = _.get(action,'data.result');
  return {...state,configurations};
}

function receiveGetSecurityImagesByDate(state, action) {
  const {camera} = state;
  let eventcamera = _.get(action,'data.camera.0');
  let containerid = _.get(action,'data.containerid');
  // let oldcamera = _.get(camera,containerid);
  camera[containerid] = eventcamera;
  // if(!_.isEmpty(oldcamera) && _.size(eventcamera) === 1 ) {
  //   camera[containerid] = _.map(oldcamera,old=>{
  //     let date = _.get(old,'date');
  //     let newCamera = _.find(eventcamera,{date});
  //     return newCamera ? newCamera : old
  //   });
  // }

  return {...state,camera};
}

function receiveSelectedGetLog(state, action) {
  let eventstate = _.get(state,'events');
  let event = _.get(action,'data.event');
  let id = _.get(event,'id');

  // let existid = _.find(eventstate,{id});
  eventstate[id] = event;
  let events = _.assign({},eventstate);
  return {...state,events,currentid:id}
  // if(!existid) {
  //   // caching logs
  //   let events = _.compact([...eventstate,event]);
  //   return { ...state, events, currentid:id };
  // } else {
  //   return { ...state, currentid:id };
  // }
}
