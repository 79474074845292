import React from 'react';
import {filter,values,map,kebabCase,find,isEmpty} from 'lodash';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Collapse, Form, Navbar, NavItem, NavbarToggler, NavbarBrand, Nav, NavLink, Dropdown, DropdownItem, DropdownToggle, DropdownMenu} from 'reactstrap';
import { logout } from './../actions/currentUser';
import { switchContainers } from './../actions/containers';
import ContainerSelection from './../components/ContainerSelection';
import {multilanguage} from 'redux-multilanguage';
import {Tr, valueNullToString} from '../languageTranslate';
import './MainNav.css';
import {switchServer} from './../actions/currentUser';

// react_devtools is complaining
const STYLE = {margin: 0 }; // add to reactstrap dropdown to stop complain
class MainNav extends React.Component {

  constructor(props) {
    super(props);
    this.logout = this.logout.bind(this);
    this.toggleInventorytDropdown = this.toggleInventorytDropdown.bind(this);
    this.toggleDashboardDropdown = this.toggleDashboardDropdown.bind(this);
    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.toggleUserDropdown = this.toggleUserDropdown.bind(this);
    this.toggleReportsDropdown = this.toggleReportsDropdown.bind(this);
    this.toggleSoloTransactionDropdown = this.toggleSoloTransactionDropdown.bind(this);
    this.toggleSupportDropdown = this.toggleSupportDropdown.bind(this);
    this.toggleServiceDropdown = this.toggleServiceDropdown.bind(this);
    this.toggleIOptixDropdown = this.toggleIOptixDropdown.bind(this);
    this.toggleCustomerDropdown = this.toggleCustomerDropdown.bind(this);
    this.onContainerIDQueryUpdate = this.onContainerIDQueryUpdate.bind(this);
    this.state = {
      isOpen: false,
      reportsDropdownIsOpen: false,
      soloTransactionDropdownIsOpen: false,
      supportDropdownIsOpen: false,
      inventoryDropdownIsOpen: false,
      dashboardDropdownIsOpen: false,
      serviceDropdownIsOpen: false,
      ioptixDropdownIsOpen: false,
      customerDropdownIsOpen: false,
      userDropdownIsOpen: false
    }
  }

  logout() {
    this.props.dispatch(logout());
  }

  toggleInventorytDropdown() {
    this.setState({
      inventoryDropdownIsOpen: !this.state.inventoryDropdownIsOpen
    });
  }

  toggleDashboardDropdown() {
    this.setState({
      dashboardDropdownIsOpen: !this.state.dashboardDropdownIsOpen
    });
  }

  toggleServiceDropdown() {
    this.setState({
      serviceDropdownIsOpen: !this.state.serviceDropdownIsOpen
    });
  }

  toggleIOptixDropdown() {
    this.setState({
      ioptixDropdownIsOpen: !this.state.ioptixDropdownIsOpen
    });
  }

  toggleCustomerDropdown() {
    this.setState({
      customerDropdownIsOpen: !this.state.customerDropdownIsOpen
    });
  }

  toggleNavbar() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  toggleUserDropdown() {
    this.setState({
      userDropdownIsOpen: !this.state.userDropdownIsOpen
    });
  }

  toggleReportsDropdown() {
    this.setState({
      reportsDropdownIsOpen: !this.state.reportsDropdownIsOpen
    });
  }

  toggleSoloTransactionDropdown() {
    this.setState({
      soloTransactionDropdownIsOpen: !this.state.soloTransactionDropdownIsOpen
    });
  }

  toggleSupportDropdown() {
    this.setState({
      supportDropdownIsOpen: !this.state.supportDropdownIsOpen
    });
  }

  onContainerIDQueryUpdate(containerID) {
    this.props.dispatch(switchContainers(containerID));
  }

  permissions(Type) {
    return find(this.props.user.permissions,['Type',Type]);
  }

  switchServer =()=> {
    if(this.permissions('250')) {
      this.props.dispatch(switchServer());
    }
  };

  lang(string) {
    return valueNullToString(this.props.strings,string)
  }

  dropDownItem(key,path,string) {
    return (
      <DropdownItem
        key={key}
        tag={Link}
        to={'/'+path}
        stlye={STYLE}
      >
        {this.lang(string)}
      </DropdownItem>
    )
  }

  render() {
    const reports = map(this.props.reports, ({reportID,reportName}) => {
      let link = `reports/${kebabCase(reportName)}`;
      return this.dropDownItem(reportID,link,reportName);
    });
    const isContainerAdmin = this.permissions('27');
    const isOneMicro = this.permissions('250');
    const isTimesheetAdministrator = this.permissions('30');
    const isAuditAdmin = this.permissions('31');
    const isBookIn = this.permissions('6');
    const isBinAdmin = this.permissions('72');
    const isSoloAdmin = this.permissions('73');
    const isWebsiteBookIn = this.permissions('35');
    const locationTracker = this.permissions('32');
    const serviceAdmin = this.permissions('11');
    const customerAdmin = this.permissions('40');
    const iOptixAdmin = this.permissions('81');

    const dashboard =
      locationTracker ?
      <Dropdown
        stlye={STYLE}
        isOpen={this.state.dashboardDropdownIsOpen}
        toggle={this.toggleDashboardDropdown}>
        <DropdownToggle nav caret
         stlye={STYLE}
        >
          {this.lang('Dashboard')}
        </DropdownToggle>
        <DropdownMenu
          stlye={STYLE}
        >
          {this.dropDownItem("Main Dashboard",`${process.env.PUBLIC_URL}`,'MainDashboard')}
          {this.dropDownItem("Location Tracker",`tracking-test-drive`,'TrackingDashboard')}
        </DropdownMenu>
      </Dropdown>
      :
    <NavLink tag={Link} to={`${process.env.PUBLIC_URL}/`} stlye={STYLE}>Dashboard</NavLink>;

    const service =
      serviceAdmin ?
        <Dropdown
          stlye={STYLE}
          isOpen={this.state.serviceDropdownIsOpen}
          toggle={this.toggleServiceDropdown}>
          <DropdownToggle nav caret
           stlye={STYLE}
          >
            {this.lang('Service')}
          </DropdownToggle>
          <DropdownMenu
            stlye={STYLE}
          >
            {this.dropDownItem('dshs1','service/dshs','DigitalServiceHatSetup')}
            {this.dropDownItem('report','service/report','ServiceDriveReport')}
          </DropdownMenu>
        </Dropdown>
        :
        null;

    const ioptix =
      iOptixAdmin ?
        <Dropdown
          stlye={STYLE}
          isOpen={this.state.ioptixDropdownIsOpen}
          toggle={this.toggleIOptixDropdown}>
          <DropdownToggle nav caret
           stlye={STYLE}
          >
            {this.lang('iOptix')}
          </DropdownToggle>
          <DropdownMenu
            stlye={STYLE}
          >
            {this.dropDownItem('Accelerometer Activity','optix/accerlerometer-activity','Accelerometer Activity')}
            {this.dropDownItem('iSafeWatch','optix/isafewatch','iSafeWatch')}
          </DropdownMenu>
        </Dropdown>
        :
        null;

    const customer =
      customerAdmin ?
        <Dropdown
          stlye={STYLE}
          isOpen={this.state.customerDropdownIsOpen}
          toggle={this.toggleCustomerDropdown}>
          <DropdownToggle nav caret
           stlye={STYLE}
          >
            {this.lang('Customer')}
          </DropdownToggle>
          <DropdownMenu stlye={STYLE}>
            {this.dropDownItem('container','customer','Verified Customer')}
            {this.dropDownItem('event','customer/events','Events')}
            {this.dropDownItem('unverified','customer/unverified-documents','Unverified Documents')}
          </DropdownMenu>
        </Dropdown>
        :
        '';

    return (
      <Navbar
        className="MainNav"
        expand="md"
        stlye={STYLE}
      >
        <Nav stlye={STYLE}>
          <NavbarBrand
            stlye={STYLE}
            to={`${process.env.PUBLIC_URL}/`}
            tag={Link}>
            <img className="brand-logo" src={`${process.env.PUBLIC_URL}/logo.png`} alt="logo"/>
          </NavbarBrand>
          <NavItem stlye={STYLE}>
            <Form className="form-inline">
              <ContainerSelection
                initialContainerID={this.props.currentContainer}
                onChange={this.onContainerIDQueryUpdate}
              />
            </Form>
          </NavItem>
          <NavbarToggler
            right="true"
            onClick={this.toggleNavbar}
            style={{backgroundColor: 'whitesmoke',...STYLE}} />
          <Collapse isOpen={this.state.isOpen} navbar>
          <Nav className="ml-auto" navbar={this.state.isOpen}  stlye={STYLE}>
            {dashboard}
            {/*<NavItem>*/}
              {/*<NavLink tag={Link} to={`${process.env.PUBLIC_URL}/`}>{this.lang("Dashboard")}</NavLink>*/}
            {/*</NavItem>*/}
            <NavItem  stlye={STYLE}>
              <NavLink  stlye={STYLE} tag={Link} to={`${process.env.PUBLIC_URL}/users`}>{this.lang("Users")}</NavLink>
            </NavItem>
            { isContainerAdmin ? <NavItem><NavLink tag={Link} to={`${process.env.PUBLIC_URL}/containers`}>{this.lang("Containers")}</NavLink></NavItem> : '' }
            { isContainerAdmin && this.props.currentContainer === '1' ? <NavItem><NavLink tag={Link} to={`${process.env.PUBLIC_URL}/assembly-inventory`}>{this.lang("Assembly Inventory")}</NavLink></NavItem> : '' }
            { isContainerAdmin ? <NavItem><NavLink tag={Link} to={`${process.env.PUBLIC_URL}/dealers`}>{this.lang("Dealers")}</NavLink></NavItem> : '' }
            { isTimesheetAdministrator ? <NavItem><NavLink tag={Link} to={`${process.env.PUBLIC_URL}/reports/timesheets2`}>{Tr(this.lang("Timesheets 2"))}</NavLink></NavItem> : '' }
            <Dropdown
              stlye={STYLE}
              isOpen={this.state.inventoryDropdownIsOpen} toggle={this.toggleInventorytDropdown}>
              <DropdownToggle nav caret
               stlye={STYLE}
              >
                {this.lang("Inventory")}
              </DropdownToggle>
              <DropdownMenu
                stlye={STYLE}
              >
                {this.dropDownItem('Assets','assets','Search')}
                {isAuditAdmin ? this.dropDownItem('Audits','audits','Audits') :''}
                {isBinAdmin ? this.dropDownItem('Bins','bins','Bins') :''}
                {isBookIn && isWebsiteBookIn ? this.dropDownItem('book-in','book-in','BookIn') :''}
                {isBookIn && isWebsiteBookIn ? this.dropDownItem('book-in-mass','book-in/mass','BookIn Mass') :''}
              </DropdownMenu>
            </Dropdown>
            { !isEmpty(reports) ?
              <Dropdown
                stlye={STYLE}
                isOpen={this.state.reportsDropdownIsOpen} toggle={this.toggleReportsDropdown}>
              <DropdownToggle nav caret
               stlye={STYLE}
              >
                {this.lang("Reports")}
              </DropdownToggle>
              <DropdownMenu
                stlye={STYLE}
              >
                {reports}
              </DropdownMenu>
            </Dropdown>
              : ''
            }
            { !isSoloAdmin ? '' :
              <Dropdown
                stlye={STYLE}
                isOpen={this.state.soloDropdownOpen} toggle={
                () => {this.setState({ soloDropdownOpen: !this.state.soloDropdownOpen });}}>
                <DropdownToggle nav caret
                 stlye={STYLE}
                >{this.lang("SOLO")}</DropdownToggle>
                <DropdownMenu
                  stlye={STYLE}
                >
                  {this.dropDownItem('solo-create', 'solo/create', 'Create SOLO Transaction(s)')}
                  {this.dropDownItem('solo-view', 'solo/view', 'View Transactions')}
                </DropdownMenu>
              </Dropdown>
            }
            <Dropdown
              stlye={STYLE}
              isOpen={this.state.supportDropdownIsOpen} toggle={this.toggleSupportDropdown}>
              <DropdownToggle nav caret
               stlye={STYLE}
              >
                {this.lang("Support")}
              </DropdownToggle>
              <DropdownMenu
                stlye={STYLE}
              >
                {this.dropDownItem('videos','support/videos','VideoUserGuides')}
                {this.dropDownItem('contact','support/contact','ContactSupport')}
                {isContainerAdmin ? this.dropDownItem('creditcard','support/creditcard','CreditCard') : null}
              </DropdownMenu>
            </Dropdown>
            {service}
            {ioptix}
            {customer}
            {/*<NavItem>*/}
              {/*<NavLink tag={Link} to={`${process.env.PUBLIC_URL}/about`}>{this.lang("About")}</NavLink>*/}
            {/*</NavItem>*/}
            {/*<NavItem><NavLink tag={Link} to={`${process.env.PUBLIC_URL}/valetouch`}>{this.lang("ValeTouch")}</NavLink></NavItem>*/}
          </Nav>
          <Nav className="ml-auto"  stlye={STYLE}>
            <Dropdown
              stlye={STYLE}
              isOpen={this.state.userDropdownIsOpen} toggle={this.toggleUserDropdown}>
              <DropdownToggle nav caret
               stlye={STYLE}
              >
                {this.props.user.username}
              </DropdownToggle>
              <DropdownMenu   stlye={STYLE} className="current-user-dropdown">
                {
                  isOneMicro ?
                    <DropdownItem  stlye={STYLE} onClick={this.switchServer}>
                      {this.lang("Switch Server")}
                    </DropdownItem>
                    : null
                }
                <DropdownItem  stlye={STYLE} onClick={this.logout}>
                  {this.lang("Logout")}
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <Nav  tag={Link} to={`${process.env.PUBLIC_URL}/setting`}  stlye={STYLE}>
              <i className="fa fa-cog setting"/>
            </Nav>
            <img className="connection-status"
                 src={
                   this.props.connectionStatus === 'opened' ?
                     `${process.env.PUBLIC_URL}/websocket_up.svg` :
                     `${process.env.PUBLIC_URL}/websocket_down.svg`
                 }
                 alt="websocket status"
                 title={window.socket.socket.url}
            />
          </Nav>
          </Collapse>
        </Nav>
      </Navbar>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    reports: filter(values(state.reports.reportsList), (report) => { return report.reportName !== 'Users Clocked In'} ), // There are better ways to remove this
    user: state.currentUser,
    connectionStatus: state.connectionStatus,
    currentContainer: state.containers.currentContainer
  }
};

export default connect(mapStateToProps)(multilanguage(MainNav));
