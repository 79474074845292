import {remove} from 'lodash';

const initialState = {
  list: [],
};

export default function creditcard(state = initialState, action) {
  switch(action.type) {
    case 'RECEIVE_CREDITCARDGETLIST':
      return receiveCreditCardGetList(state, action);
    case 'RECEIVE_CREDITCARDADD':
      return receiveCreditCardAdd(state, action);
    case 'RECEIVE_CREDITCARDDELETE':
      return receiveCreditCardDelete(state, action);
    default:
      return state;
  }
}

function receiveCreditCardGetList(state, action) {
  let {list} = action.data;
  return {...state,list}
}

function receiveCreditCardAdd(state, action) {
  let {list} = state;
  return {...state,list:[...list,action.data.addedcard]}
}

function receiveCreditCardDelete(state, action) {
  let {list} = state;
  let {id} = action.data;
  remove(list, card => {
    return card.id === id;
  });
  return {...state,list:[...list]}
}
