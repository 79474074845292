const initialState = {};

export default function windowsize(state = initialState, action) {
  switch (action.type) {
    case 'WINDOW_SIZE_CHANGED':
      return { ...state, ...action };
    default:
      return state;
  }
}
