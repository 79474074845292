import store from './configureStore';
import { startsWith, get, includes, toString, split, trimStart, reduce } from 'lodash';
import { registerSession, invalidSession, storeSession} from './actions/currentUser';
import {fetchAsset} from "./actions/assets";
import {fetchKeysCheckedOutDashboardReport} from "./actions/dashboard";

class Socket {
  // Switched from the 'reconnecting-websocket' to a 'timeout-ping' approach.
  constructor( url ) {
    this.url = url;
    this.connection_resolvers = [];
    if( !this.url ) {
      if( window.location.hostname === 'localhost'
        || startsWith( window.location.hostname, '192.168.' )
        || startsWith( window.location.hostname, '10.' )
        || startsWith( window.location.hostname, 'webtest' )
      ) {
        // this.url = 'wss://cloud.ilot.net/ws/';
        // this.url = 'wss://dev.ilot.net/ws/';
        this.url = 'wss://www.1micro.net/ws/';
      } else {
        this.url = 'wss://' + window.location.hostname + '/ws/';
      }
    }
    this.default_server = this.url;

    let urlparams = new URLSearchParams( trimStart( window.location.search, '?' ) );
    if( urlparams.get( 'resetserver' ) ) {
      // password reset - uses server from url if exists
      this.alternate_server = 'wss://' + urlparams.get( 'resetserver' ) + '/ws/';
    } else {
      this.alternate_server = localStorage.getItem( 'alternate_server' );
    }
    this.max_alternate_attempts = 3;
    this.alternate_server_attempts = this.alternate_server ? this.max_alternate_attempts : 0;

    this.onClose = this.onClose.bind(this);
    this.onError = this.onError.bind(this);
    this.onMessage = this.onMessage.bind(this);
    this.onOpen = this.onOpen.bind(this);
    this.timer = setInterval( this.checkConnection.bind(this), 5000 );
    // this.comms_timeout = 50000;
    // this.comms_timeout = 30000;
    this.comms_timeout = 60000;
    this.checkConnection();
  }


  checkConnection( err ) {
    try {
      let timediff = Date.now() - this.lastcomms;

      if( !this.socket || this.socket.readyState === WebSocket.CLOSED
        || timediff > this.comms_timeout * 2 ) {
        if( err )
        this.alternate_server_attempts = this.alternate_server_attempts - 1;
        // ----------------- Timed out
        if( this.socket && this.socket.readyState !== WebSocket.CLOSED )
        this.socket.close( 1006, "No ping received within timeout." );
        this.socket = new WebSocket(
          ( this.alternate_server_attempts > 0 ) ?
          this.alternate_server :
          this.default_server
          // 'wss://www.1micro.net/ws/'
        );
        this.socket.onclose = this.onClose;
        this.socket.onerror = this.onError;
        this.socket.onmessage = this.onMessage;
        this.socket.onopen = this.onOpen;
        if(this.code && this.reason) {
          this.send({ command: 'reconnectionreason',data: {
            reason:this.reason,code:this.code
          }
          });
          this.reason = null;
          this.code = null;
        }
      } else if( timediff > this.comms_timeout ) {
        store.dispatch({ type: 'PING' });
        this.send({ command: 'ping' });
      }
    } catch(e ) {
    }
  }

  // 2022-03-22 jerry added for react dev from crashing black screen error
  checkConnection2 = () => {
    return new Promise((resolve, reject) => {
      if (this.socket.readyState === WebSocket.OPEN) {
        resolve();
      }
      else {
        this.connection_resolvers.push({resolve, reject});
      }
    });
  };

  reconnect( is_logout ) {
    if( is_logout )
      this.alternate_server_attempts = 0;
    this.send({ command: 'reconnectionreason',data: {} });
    this.socket.close();
    this.connection_resolvers = [];
    this.checkConnection();
  }

  onClose(error) {
    if(error.code !== 1006 ) {
      this.code = error.code;
      this.reason = error.reason;
    }
    this.connection_resolvers = [];
    store.dispatch({type: 'CLOSE_SOCKET'});
    this.checkConnection();
  }

  onError( e ) {
    console.error( e );
    this.connection_resolvers = [];
    this.checkConnection( e );
  }

  onOpen() {
    store.dispatch({ type: 'OPEN_SOCKET' });
    this.lastcomms = Date.now();
    this.connection_resolvers.forEach(r => r.resolve());
    // password reset - uses token from url if exists
    let urlparams = new URLSearchParams( trimStart( window.location.search, '?' ) );

    let params = {
      PHPSESSID: urlparams.get('resettoken') || localStorage.getItem( 'token' ) || '',
      token: urlparams.get('resettoken') || localStorage.getItem( 'token' ) || '',
      language: localStorage.getItem( 'language' ),
      username: localStorage.getItem( 'username' )
    };
    if( !params.PHPSESSID )
      params.password = localStorage.getItem( 'password' );

    if( params.PHPSESSID || ( params.username && params.password ) )
      store.dispatch( registerSession( params ) );
  }

  // send( payload, callback) {
  //   this.waitForConnection(function () {
  //     let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  //     this.socket.send( JSON.stringify({ ...payload, timezone }) );
  //     if (typeof callback !== 'undefined') {
  //       callback();
  //     }
  //   }, 1000);
  // }
  async send( payload ) {
    await this.checkConnection2();
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.socket.send( JSON.stringify({ ...payload, timezone }) );
  }

  toastrMessage = (message,type) => {
    store.dispatch({
      payload: {
        message,
        options: {
          removeOnHover: true,
          showCloseButton: true,
        },
        position: "top-right",
        title: type.toUpperCase(),
        type,
      },
      type: "@ReduxToastr/toastr/ADD",
    });
  };


  onMessage( response ) {
    this.lastcomms = Date.now();
    try{
      const data = JSON.parse(response.data);
      const { command } = data;
      switch(command) {
        case 'ping':  // ping is obnoxiously noisy
          return null;
        case 'register':
          if( data.PHPSESSID ) {
            if( this.alternate_server_attempts > 0 )
              this.alternate_server_attempts = this.max_alternate_attempts;
            store.dispatch(storeSession(data.PHPSESSID));
            return store.dispatch(fetchKeysCheckedOutDashboardReport({offset: 0, count: 1000}));
          } else {
            // password reset - prevents loop of reconnects by redirecting to base url
            let urlparams = new URLSearchParams( trimStart( window.location.search, '?' ) );
            if( urlparams.get( 'resettoken' ) || urlparams.get( 'resetserver' ) ) {
              window.location.replace( window.location.origin );
            }

            if (data.attemptsremaining) {
              this.reconnect( 'islogout' );
              return store.dispatch(invalidSession());
            } else {
              this.toastrMessage( 'Incorrect Username or Password', 'error');
            }
          }
          return null;
        case 'successmessage':
          let message = get(data,'message');
          if (message) {
            return this.toastrMessage(message,"success");
          }
          return null;
        case 'assetdetailset':
          const { lastViewedID } = store.getState().assets;
          return lastViewedID != null ?
            store.dispatch(fetchAsset(lastViewedID))
          : null;
        case 'register_redirect':
          {
            let { serverurl, username, token } = data;
            if( serverurl ||  username ||  token ) {
              localStorage.setItem( 'token', token );
              localStorage.setItem( 'alternate_server', serverurl );
              this.alternate_server = serverurl;
              this.alternate_server_attempts = this.max_alternate_attempts;
              this.reconnect();
            }
          }
          break;
        case 'register_replaceurl':
          window.location.replace( data.url );
          break;
        case 'passwordreset':
          // User doesn't get to know if the email was sent.
          return this.toastrMessage( 'If an email is on file, a password reset email ' +
            'has been sent. Please check your junk folder if you do not see it.', 'success' );
        default:
          // Status Message
          if (data.code < 0 || command === 'errormessage' || command === 'usermessage') {
            let message = get(data,'message');
            return (message === 'Updated' || message === 'Cleared' || command !== 'errormessage') && data.code !== -1?
              this.toastrMessage(message,"success")
            :
              this.toastrMessage(message,"error")
          }
          return (includes(data.type,"@ReduxToastr/")) ?
            // Status Message
            store.dispatch({
              type: data.type,
            })
          :
            // Receive Data
            store.dispatch({
              type: `RECEIVE_${command.toUpperCase()}`,
              data
            });
      }
    } catch(e) {
      console.error('socket onMessage error:',e)
    }
  }
}

let socket = new Socket();
window.socket = socket;

export default socket;
