import socket from "../socket";

export const TERMINAL_COMMAND = 'TERMINAL_COMMAND';
export const terminalCommand = params => {
  return dispatch => {
    let payload = {
      command: 'sessioncommand',
      ...params
    };
    socket.send(payload);
    dispatch({ type: TERMINAL_COMMAND,data: params });
  }
};

export const TERMINAL_NEW = 'TERMINAL_NEW';
export const terminalNew = kioskid => {
  return dispatch => {
    let payload = {
      command: 'sessionnew',
      kioskid
    };
    socket.send(payload);
    dispatch({ type: TERMINAL_NEW, data:{kioskid} });
  }
};

export const TERMINAL_CLOSED = 'TERMINAL_CLOSED';
export const terminalClose = params => {
  return dispatch => {
    let payload = {
      command: 'sessionclose',
      ...params
    };
    socket.send(payload);
    dispatch({ type: TERMINAL_CLOSED,data:params });
  }
};

export const TERMINAL2_ADD = 'TERMINAL2_ADD';
export const terminal2Add = params => {
  return dispatch => {
    let payload = {
      command: 'terminal2',
      action: 'add',
      ...params
    };
    socket.send(payload);
  }
};

export const TERMINAL2_LIST = 'TERMINAL2_LIST';
export const terminal2List = params => {
  return dispatch => {
    let payload = {
      command: 'terminal2',
      action: 'list',
      ...params
    };
    socket.send(payload);
  }
};

export const TERMINAL2_HIDE = 'TERMINAL2_HIDE';
export const terminal2Hide = data => {
  return dispatch => {
    dispatch({ type: TERMINAL2_HIDE, data });
  }
};

export const TERMINAL2_SHOW = 'TERMINAL2_SHOW';
export const terminal2Show = data => {
  return dispatch => {
    dispatch({ type: TERMINAL2_SHOW, data });
  }
};

export const TERMINAL2_LEAVE = 'TERMINAL2_LEAVE';
export const terminal2Leave = params => {
  return dispatch => {
    let payload = {
      command: 'terminal2',
      action: 'leave',
      ...params,
      id: parseInt( params.id ),
    };
    socket.send(payload);
    dispatch({ type: TERMINAL2_LEAVE, data: params });
  }
};

export const TERMINAL2_JOIN = 'TERMINAL2_JOIN';
export const terminal2Activate = params => {
  return dispatch => {
    let payload = {
      command: 'terminal2',
      action: 'join',
      ...params,
      id: parseInt( params.id ),
    };
    socket.send(payload);
    dispatch({ type: TERMINAL2_JOIN, data: params });
  }
};

export const TERMINAL2_CLOSE = 'TERMINAL2_CLOSE';
export const terminal2Close = params => {
  return dispatch => {
    let payload = {
      command: 'terminal2',
      action: 'remove',
      ...params,
      id: parseInt( params.id ),
    };
    socket.send(payload);
  };
};

export const TERMINAL2_ATTACH = 'TERMINAL2_ATTACH';
export const terminal2Attach = data => {
  return dispatch => {
    dispatch({ type: TERMINAL2_ATTACH, data });
  };
};

export const TERMINAL2_SENDDATA = 'TERMINAL2_SENDDATA';
export const terminal2SendData = params => {
  return dispatch => {
    socket.send({
      command: 'terminal2',
      action: 'data',
      ...params,
      id: parseInt( params.id ),
      t: Date.now(),    // ratelimiter will block otherwise.
    });
  };
};
