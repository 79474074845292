import _ from 'lodash';
import moment from 'moment';
import { FETCH_REPORT, REPORT_NAMES } from '../actions/reports';

const initialState = {
  dateRange:{},
  reportsList: [],
  datafile: {},
  soldAsset: {},
  lostTags: {},
  kioskimages: {},
  instockinventory: [],
  instockinventoryDatafile: [],
  assetAging: [],
  assetAgingDatafile: [],
  sensorEvents: [],
  sensorEventsDatafile: [],
  ftpinterface: []
};

export default function reports(state = initialState, action) {
  switch (action.type) {
    case 'SET_REPORT_DATE_RANGE':
      //clear data for every new search
      let data = state[action.data.report] = {};
      return {...state,...data,dateRange:action.data};
    case 'RECEIVE_REPORTLIST':
      return {
        ...state,
        reportsList: action.data.reports
      };
    case 'RECEIVE_REPORT':
      return receiveReport(state, action);
    case FETCH_REPORT:
      return fetchReport(state, action);
    case 'HANDLE_LOGOUT':
      return _.cloneDeep(initialState);
    case 'RECEIVE_ASSETSNOTASSIGNED':
      return receiveAssetNotAssigned(state, action);
    case 'RECEIVE_TAGMARKASLOST':
      return receiveTagMarkAsLost(state,action);
    case 'RECEIVE_SOLDKEYLIST':
      return receiveSoldKeyList(state,action);
    case 'RECEIVE_LOSTKEYLIST':
      return receiveLostKeyList(state,action);
    case 'RECEIVE_KIOSKIMAGESGET':
      return receiveKioskImagesGet(state,action);
    case 'RECEIVE_REPORTKEYSNOTHOME':
      return receiveReportKeysNotHome(state, action);
    case 'RECEIVE_INSTOCKINVENTORY':
      return receiveInStockInventory(state, action);
    case 'RECEIVE_NOTSOLDNOTAGASSET':
      return receiveNotSoldNoTagAsset(state, action);
    case 'RECEIVE_ASSETARCHIVEDSET':
      return receiveAssetArchivedSet(state, action);
    case 'RECEIVE_FTPGETINTERFACE':
      return receiveFtpGetInterface(state, action);
    default:
      return state;
  }
}

function receiveFtpGetInterface(state,action) {
  return {
    ...state,
    ftpinterface:_.map(action.data.files, (file,uuid) =>({...file,uuid}))
  }
}

function receiveAssetNotAssigned(state,action) {
  let {assets} = action.data;
  return {
    ...state,
    12:assets
  }
}

function receiveLostKeyList(state,action) {
  let lostTags = state.lostTags;
  let assets = _.get(action,'data.keys');
  let containerid = _.get(action,'data.containerid');
  lostTags[containerid] = assets;
  let datafile = state.datafile;
  datafile['lostkey'] = scrubForSpreadsheet(assets);
  return {...state,lostTags,datafile}
}

function receiveSoldKeyList(state,action) {
  let soldAsset = state.soldAsset;
  let assets = _.get(action,'data.keys');
  let containerid = _.get(action,'data.containerid');
  soldAsset[containerid] = assets;
  let datafile = state.datafile;
  datafile['soldkey'] = scrubForSpreadsheet(assets);
  return {...state,soldAsset,datafile}
}

function receiveTagMarkAsLost(state,action) {
  let reportType = _.get(action,'data.reportType');
  let tagid = _.get(action,'data.tagid');
  let report = _.get(state,`${reportType}`);
  let data = _.get(report,`data`);
  let removeData = _.filter(data,report=>{
    let tagID = report['Tag ID'];
    // let tagIDToInt = parseInt(tagID,16);
    // console.log('tagIDToInt',tagIDToInt);
    // return tagIDToInt !==tagid
    return tagID !== tagid
  });
  return{...state,[reportType]:{...report,data:removeData}}
}

function fetchReport(state, action) {

  if (_.get(state[action.id], 'status') === 'fetching') {
    return state;
  }
  // Determine if the new parameters require us to erase existing data or not
  let currentReport = state[action.id];
  let currentParameters = _.get(currentReport, 'parameters', {});
  // let currentParameters = _.get(currentReport, 'parameters.dateRange', {});
  let report;
  // Assume offset zero to mean the report should be fetched anew
  if (
    _.get(action, 'parameters.offset', 0) === 0 ||
    // _.get(action, 'parameters.containerid') !== _.get(currentReport, 'parameters.containerid') ||
    (currentParameters.startDate !== _.get(action, 'parameters.startDate') || currentParameters.endDate !== _.get(action, 'parameters.endDate')) ) {
    report = {
      parameters: action.parameters
    };
    report.status = 'fetching';
  } else { // Preserve Existing data
    report = _.assign({}, state[action.id], {parameters: action.parameters});
    report.status = 'loadingMore';
  }

  return {
    ...state,
    [action.id]: {...report}
  };
}

function receiveReport(state, action) {
  if(!REPORT_NAMES[action.data.id]) { return state; } // If we don't recognize this report id, just return state
  let currentReport = state[action.data.id];

//   let data = _.get(state[action.data.id], 'offset') === 0 ? action.data.data : _.concat(_.get(currentReport, 'data', []), action.data.data);
  let data = _.get(action,'data.data'); //_.get(state[action.data.id], 'offset') === 0 ? action.data.data : _.concat(_.get(currentReport, 'data', []), action.data.data);
  // console.log('action *******',action.data);

  // let data = action.data.data; //_.get(state[action.data.id], 'offset') === 0 ? action.data.data : _.concat(_.get(currentReport, 'data', []), action.data.data);
  let metadata = action.data.metadata;  // _.concat(_.get(currentReport, 'metadata', []), action.data.metadata);

  if (action.data.id === '6') {
    data = _.filter(action.data.assets, (asset) => {
      return asset.latitude.indexOf('3.333333') === -1;
    });
  } else if (action.data.id === '3') {
    data = data.map(report=> {
      // change null values to empty strings
      // prevent null values to be search on react table when non exist keyword does not match
      let {Kiosk}=report;
      Kiosk = Kiosk || '';
      return {...report,Kiosk};
    })

    // data = data.map(report=> {
    //   console.log('report *****',report);
    //   // change null values to empty strings
    //   // prevent null values to be search on react table when non exist keyword does not match
    //   let {Kiosk}=report;
    //   Kiosk = Kiosk || '';
    //   return {...report,Kiosk};
    // })
  } else if (action.data.id === '4') {
    data = data.map(report=> {
      // change null values to empty strings
      // prevent null values to be search on react table when non exist keyword does not match
      let {Asset,identifier}=report;
      let stockNumber = report['Stock Number'] || '';
      Asset = Asset || '';
      identifier = identifier || '';
      return {...report,'Stock Number':stockNumber,Asset,identifier};
    })
  }

  let imagelists = _.get(action,'data.imagelists');
  if(imagelists) {
    data = employeeKeyActivitiesData(data,imagelists);
    let stateData = _.get(state,`${action.data.id}.data`,[]);
    let stateMetaData = _.get(state,`${action.data.id}.metadata`,[]);
    data = [...stateData,...data];
    metadata = [...stateMetaData,...metadata];
  }

  let checkoutDateIndex = _.findKey(data,'checkoutDate');
  if(data[checkoutDateIndex]) {
    data = keyCheckedOutData(data);
  }

  let parameters = _.get(currentReport, 'parameters', {});
  let datafile = state.datafile;
  datafile[action.data.id] = scrubForSpreadsheet(data);
  return {
    ...state,
    [action.data.id]: {
      data:[...data],
      metadata,
      parameters,
      imagelists,
      total: action.data.total,
      status: 'done'
    },
    datafile,
  };
}

function keyCheckedOutData(data) {
  return _.map(data,asset=>{
    let duration;
    if(asset.checkoutDate) {
      let checkoutDate = moment.utc(asset.checkoutDate);
      let now = moment.utc();
      duration = moment.duration(checkoutDate.diff(now)).humanize();
      return {...asset,duration};
    }
    return asset;
  });
}

function employeeKeyActivitiesData(data,imagelists) {
  if (_.isEmpty(data)) return [];
  let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return _.map(data,(asset)=>{
    let kioskID = _.get(asset,'kioskID');
    let imagelist = _.get(imagelists,kioskID);
    let timelists = _.map(imagelist,'time');
    let endTime = moment(asset.Completed);
    let startTime = moment(asset.Started);
    // let Created = asset.Created ? moment.utc( asset.Created ).local().tz(timezone) : null;
    let Created = asset.Created ? moment.utc( asset.Created ).local().tz(timezone).format( 'YYYY-MM-DD hh:mm:ssA z' ) : '';
    let from = asset.Started;
    let to = asset.Completed;
    // let Completed = asset.Completed ? moment.utc( asset.Completed ).local().tz(timezone) : null;
    let Completed = asset.Created ? moment.utc( asset.Completed ).local().tz(timezone).format( 'YYYY-MM-DD hh:mm:ssA z' ) : '';
    // let Started = asset.Started ? moment.utc( asset.Started ).local().tz(timezone) : null;
    let Started = asset.Created ? moment.utc( asset.Started ).local().tz(timezone).format( 'YYYY-MM-DD hh:mm:ssA z' ) : '';
    if( !asset.Started ) {
      startTime = moment( asset.Completed );
      startTime.subtract( 10, 'seconds' );
    }
    let duration = '';
    if(asset.Started) {
      let created = moment(asset.Started);
      let completed = moment(asset.Completed);
      duration = moment.duration(completed.diff(created));
      let minutes = Math.floor(duration.asMinutes());
      let seconds = duration.seconds().toString().padStart(2, '0');
      duration = `${minutes}: ${seconds}`;
    }
    let startIndex = _.sortedIndex(timelists,startTime.toISOString());
    let endIndex = _.sortedIndex(timelists,endTime.toISOString()) + 1;
    imagelist = _.slice(imagelist,startIndex,endIndex);
    // Reason -- react table filter search look for non existing keywords will define null value as found
    let Reason = asset.Reason || '';
    return {...asset,Reason,imagelist,Started,Completed,Created,duration,from,to}
  });
}

// function receiveUserTimeChunkAdd(state, action) {
//   let data = _.get(action,'data');
//   let reportID = _.get(action,'data.id');
//   _.unset(data,'command');
//   _.unset(data,'id');
//   _.unset(data,'userid');
//   return {
//     ...state,
//     [reportID]: {
//       data,
//       status: 'added'
//     }
//   }
// }

// function receiveUserTimeChunkDelete(state, action) {
//   let data = _.get(action,'data');
//   let reportID = _.get(action,'data.id');
//   _.unset(data,'command');
//   _.unset(data,'id');
//   _.unset(data,'userid');
//   return {
//     ...state,
//     [reportID]: {
//       data,
//       status: 'updated'
//     }
//   }
// }

// function receiveUserTimeChunkUpdate(state, action) {
//   let data = _.get(action,'data');
//   let reportID = _.get(action,'data.id');
//   _.unset(data,'command');
//   _.unset(data,'id');
//   _.unset(data,'userid');
//   return {
//     ...state,
//     [reportID]: {
//       data,
//       status: 'updated'
//     }
//   }
// }

function scrubForSpreadsheet(data) {
  // Deep clone data.. fixed data reference and mutate original data
  let reports = _.cloneDeep(data);
  return _.map(reports,(report)=>{
    // if(!report.Slot && !report.Drawer) {
    //   _.unset(report,'Tag ID');
    // }
    if (window.location.pathname.includes('keys-checked-out')) {
      let checkoutDate = moment( report.checkoutDate ).format( 'lll' );
      // let checkoutDate = moment( report.checkoutDate ).format( 'YYYY-MM-DD' );
      report = {...report, checkoutDate};
      _.unset(report,'companyID')
    } else if (window.location.pathname.includes('sold-asset')) {
      _.unset(report,'tagid');
      _.unset(report,'assetid');
      _.unset(report,'containerid');
      _.unset(report,'companyid')
    } else if (window.location.pathname.includes('audits')) {
      _.unset(report,'location');
      _.unset(report,'tags');
      _.unset(report,'assetid');
      _.unset(report,'scanuserid');
    } else if (window.location.pathname.includes('drawer-layout')) {
      let lastUpdate = moment( report[ 'Last Update' ] ).format( 'LL' );
      // let now = moment();
      // now.isSame( lastUpdate, 'day' ) ? lastUpdate.format( 'LT' ) : lastUpdate.format( 'LL' );
      // let lastUpdate = moment(report['Last Update']).format("YYYY-MM-DD");
      report = {...report,'Last Update': lastUpdate };
    }
    _.unset(report,'Designation');
    if(!window.location.pathname.includes('key-locations')) {
      _.unset(report,'VIN');
    }
    _.unset(report,'assetID');
    _.unset(report,'companyID');
    _.unset(report,'Company ID');
    _.unset(report,'latitude');
    _.unset(report,'longitude');
    _.unset(report,'stockPhotoURL');
    _.unset(report,'userID');
    _.unset(report,'imagelist');
    _.unset(report,'transactionID');
    _.unset(report,'kioskID');

    return report;
  });
}

function receiveKioskImagesGet(state,action) {
  let kioskimages = action.data.image_urls;
  return {...state,kioskimages};
}

function receiveReportKeysNotHome(state, action) {
  let {data,containers} = _.get( action, 'data' );
  let keysNotHomeExports = _.map(data, asset=> {
    let home_containerid = _.get(asset,'home_containerid','');
    let i = _.findIndex(containers,['containerid',home_containerid]);
    let homecontainername = _.get(containers,`[${i}].containername`,'');
    let {Asset,Kiosk,['Stock Number']:stocknumber,VIN,Drawer,Row,Slot} = asset;
    return {Asset,Kiosk,['Stock Number']:stocknumber,VIN,Drawer,Row,Slot,homecontainername};
  });

  let keysNotHome = _.map( containers, v => {
    let containerid = v.containerid;
    let containername = v.containername;
    let assets = _.reduce(data, (obj,asset)=> {
      let home_containerid = _.get(asset,'home_containerid','');
      let i = _.findIndex(containers,['containerid',home_containerid]);
      let homecontainername = _.get(containers,`[${i}].containername`,'');
      if(asset.containerid === containerid) {
        obj =[...obj,{...asset,homecontainername}]
      }
      return obj;
    },[]);
    return {containername,assets,containerid};
  });

  return { ...state,
    keysNotHomeExports,
    keysNotHome
  };
}


function receiveInStockInventory(state,action) {
  let instockinventory = action.data.report;
  let instockinventoryDatafile = _.map(instockinventory, i =>{
    let {assetname,stocknumber,vin} = i;
    return {assetname,stocknumber,vin}
  });
  return {...state,instockinventory,instockinventoryDatafile};
}

function receiveNotSoldNoTagAsset(state,action) {
  let data = normalizeAssetAging(action.data.assets);
  return {...state,...data};
}

function receiveAssetArchivedSet(state,action) {
  let {archive,assetid} = action.data;
  let data = normalizeAssetAging(state.assetAging,assetid,archive);
  return {...state,...data};
}

const normalizeAssetAging = (assets,id,isSold)=> {
  let assetAging = [];
  let assetAgingDatafile = [];
  let newArchive = Number(isSold);
  let assetid = Number(id);
  for(let data of assets) {
    let {
      assetname,
      stocknumber,
      identifier:vin,
      bookin,
      keyassign,
      lastseen,
      archive,
    } = data;

    let firstreceive = moment(bookin).format('MM/DD/YYYY LT');
    let bookinDate = moment(bookin);
    let today = moment();
    let days = today.diff(bookinDate,'day');
    lastseen = lastseen ? moment(lastseen).format('MM/DD/YYYY LT') : '';
    if(assetid) {
      if(data.assetid === assetid) {
        archive = newArchive
      }
    }
    let normalizeExport = {assetname,stocknumber,vin,days,firstreceive,keyassign,lastseen};
    let normalize = {...data,...normalizeExport,archive};
    assetAging.push(normalize);
    assetAgingDatafile.push(normalizeExport)
  }
  return {assetAging,assetAgingDatafile}
};

