import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap-reboot.min.css';
import 'bootstrap/dist/css/bootstrap-grid.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import MainNav from './components/MainNav';
import Footer from './components/Footer';
import { connect } from 'react-redux';
import LoginIndex from './Login/LoginIndex';
import { withRouter } from "react-router-dom";
import ScrollToTop from './components/ScrollToTop';
import {loadLanguages,multilanguage,changeLanguage} from 'redux-multilanguage';
import ReduxToastr from 'react-redux-toastr';
import { each, get, isEmpty, assign } from 'lodash';
import { windowSizeChanged } from './actions/window';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import {languageTranslations} from './languageTranslate';
import {getVersion} from './actions/version';
import { library } from '@fortawesome/fontawesome-svg-core';
import Terminal2ModalGroup from './NewKiosk/Terminal2ModalGroup';
import { Container } from 'reactstrap';
import ErrorBoundary from './components/ErrorBoundary';

import {
  faTimes, faCheck, faSyncAlt, faPlus, faTrashAlt, faTrash, faFileDownload, faPrint, faFilePdf, faFileExcel, faFileCsv, faFile, faEdit, faArrowLeft, faArrowRight, faUsers, faImage, faVideo, faEnvelope, faWifi, faSpinner, faTabletAlt, faCamera, faShieldAlt, faArchive, faServer,faMinusSquare, faSync, faExternalLinkAlt, faUser, faSlash, faLocationArrow, faCircle, faCogs, faExclamationTriangle, faAngleDown
} from '@fortawesome/free-solid-svg-icons';

each( [
  faTimes, faCheck, faSyncAlt, faPlus, faTrashAlt, faTrash, faFileDownload, faPrint, faFilePdf, faFileExcel, faFileCsv, faFile, faEdit, faArrowLeft, faArrowRight, faUsers, faImage, faVideo, faEnvelope, faWifi, faSpinner, faTabletAlt, faCamera, faShieldAlt, faArchive, faServer,faMinusSquare, faSync, faExternalLinkAlt, faUser, faSlash, faLocationArrow, faCircle, faCogs, faExclamationTriangle, faAngleDown
], ( o ) => { library.add( o ); } );

class App extends Component {
  UNSAFE_componentWillMount() {
    this.props.getVersion();
  }

  componentDidMount() {
    this.updateWindowDimensions = this.updateWindowDimensions.bind( this );
    // this.updateWindowDimensions();
    this.props.windowSizeChanged( { width:window.innerWidth, height:window.innerHeight } );
    window.addEventListener('resize', this.updateWindowDimensions);
    if (window.location.hash.substring(1)) {
      this.props.history.push(`${process.env.PUBLIC_URL}/${window.location.hash.substring(1)}`);
    }
    // axios.get(`https://1micro.net/version.txt`).then((data)=> {
    //     debugger;
    //     console.log('version data **',data)
    // });
    //
  }

  UNSAFE_componentWillReceiveProps({language,languagelist}) {
    if(!this.props.language && language) {
      this.props.changeLanguage(language)
    }
    if(isEmpty(this.props.languagelist) && !isEmpty(languagelist)) {
      this.loadLanguages(languagelist);
    }
  }

  updateWindowDimensions( e ) {
    let width = e.target.innerWidth;
    let height = e.target.innerHeight;
    this.props.windowSizeChanged( { width, height } );
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  loadLanguages(data){
    // Below is to load languages, must call this once on app start,
    // and when user switch to new language that haven't loaded yet.
    const languages = assign({},this.props.multilanguage);
    each(data,lang =>{
      const languageCode = get(lang,'code');
        // get the language translation directly from the server for caching purpose
        languageTranslations(languageCode).then(data =>{
          languages[languageCode] = data;
          this.props.loadLanguages({languages})
      });
    });
  }

  render() {
   if (!this.props.user.sessionId) {
     let color = localStorage.getItem( 'loginBackgroundcolor' );
     return (
       <>
             <ReduxToastr
               timeOut={3000}
               newestOnTop={false}
               removeOnHover={true}
               preventDuplicates
               progressBar/>
             <Container
               style={{
                 backgroundColor: color || 'black',
                 minHeight: 'calc(100vh - 100px)',
                 marginBottom: '3em'
               }}
               fluid
             >
               <ErrorBoundary component={'LoginIndex'}>
                  <LoginIndex/>
               </ErrorBoundary>
             </Container>
             <Footer version={this.props.version}/>
         </>
       )
    } else {
      return (
        <div>
            <ScrollToTop>
              <ErrorBoundary component={'MainNav'}>
                <MainNav/>
              </ErrorBoundary>
              <ErrorBoundary component={'Terminal2ModalGroup'}>
                <Terminal2ModalGroup/>
              </ErrorBoundary>
              <ReduxToastr
                timeOut={3000}
                newestOnTop={false}
                removeOnHover={true}
                preventDuplicates
                progressBar/>
              <Container style={{minHeight: 'calc(100vh - 100px)', marginBottom: '3em'}} fluid className="no-gutters main-container">
                {this.props.children}
              </Container>
              <Footer version={this.props.version}/>
            </ScrollToTop>
        </div>
      );
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    windowSizeChanged: ( params ) => {
      return dispatch( windowSizeChanged( params ) );
    },
    getVersion: () =>dispatch(getVersion()),
    changeLanguage: langauge => {
      return dispatch(changeLanguage(langauge))
    },
    loadLanguages: langauges => {
      return dispatch(loadLanguages(langauges))
    }
  };
};

const mapStateToProps = (state) => {
  const user = get(state,'currentUser');
  const language = get(user,'language');
  const languagelist = get(state,'language.list');
  const multilanguage = get(state,'multilanguage.languages');
  const version = get(state,'version.version');
  return {
    user,
    language,
    languagelist,
    multilanguage,
    version
  }
};

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(multilanguage(App)));
