import _ from 'lodash';

const initialState = {
  currentvalues: {},
  defaultvalues: {},
};

export default function companyinfo(state = initialState, action) {
  switch (action.type) {
    case 'RECEIVE_COMPANYINFOGETALL':
      let currentvalues = { ...state.currentvalues,
        ..._.get( action, 'data.currentvalues' ) };
      let defaultvalues = _.get( action, 'data.defaultvalues' );
      return { currentvalues, defaultvalues };
    default:
      return state;
  }
}
