import {FETCH_VIDEOS} from './../actions/support';
import {cloneDeep,isEmpty} from "lodash";

const initialState = {
  videos: [],
  status: 'unfetched'
};

export default function support(state = initialState, action) {
  switch (action.type) {
    case FETCH_VIDEOS:
      let status = isEmpty(state.videos) ? 'pending' : state.status;
      return {
        ...state,
        status
        // status: 'pending'
      };
    case 'RECEIVE_NETSUITEID':
      return {
        ...state,
        netsuiteID: action.data.netsuiteid
      };
    case 'RECEIVE_SUPPORTVIDEOSGET':
      state.status = 'fetched';
      return {
        ...state,
        videos: action.data.videos,
        status: 'fetched'
      };
    case 'HANDLE_LOGOUT':
      return cloneDeep(initialState);
    default:
      return state;
  }
}
