import {cloneDeep} from 'lodash'

const initialState = {
    logs: '',
};

export default function drawercommunication(state = initialState, action) {
    switch (action.type) {
        case 'RECEIVE_DRAWERWATCH':
            return recieveDrawerWatch(state,action);
        case 'RECEIVE_DRAWERBYPASS':
            return recieveDrawerByPass(state,action);
        case 'RECEIVE_DRAWERCOMMANDRAW':
            return recieveDrawerCommanDraw(state,action);
        case 'RECEIVE_DRAWERCOMMS':
            return recieveDrawerComms(state,action);
        case 'HANDLE_LOGOUT':
            return cloneDeep(initialState);
        default:
            return state;
    }
}

function recieveDrawerWatch(state,action) {
    return {...state}
}

function recieveDrawerByPass(state,action) {
    // debugger;
    return {...state}
}

function recieveDrawerCommanDraw(state,action) {
    console.log('action.data',action.data);
    return {...state}
}

function recieveDrawerComms(state,action) {
    // console.log('action.data',action.data.what);
    const {what} = action.data;
    let  {logs} = state;
    logs += what;
    return {...state,logs:logs}
}
