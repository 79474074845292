import _ from 'lodash';
import moment from 'moment';

const initialState = {
  dateRange:{},
  exportData:[],
  metadata:[],
  employeeKeyActivity:[],
  // status: 'fetching',
  position: 0,
  total: 0
};

export default function reportsv2(state = initialState, action) {
  switch (action.type) {
    case 'SET_REPORTV2_DATE_RANGE':
      return {...state,dateRange:action.data};
    case 'RECEIVE_EMPLOYEEKEYACTIVITYV2':
      return recieveEmployeeKeyActivityV2(state,action);
    case 'HANDLE_LOGOUT':
      return _.cloneDeep(initialState);
    default:
      return state;
  }
}

function recieveEmployeeKeyActivityV2(state,action) {
  let employeeData = _.get(action,'data.employeeData.data',[]);
  let imagelists = _.get(action,'data.employeeData.imagelists',{});
  let position = state.position;
  let normalize = employeeKeyActivitiesNormalize(employeeData,imagelists,position);
  let metadata = _.get(action,'data.employeeData.metadata',[]);
  let total = _.get(action,'data.employeeData.total',0);

  return {
    ...state,
    metadata:[...state.metadata,...metadata],
    total,
    position:normalize.position,
    exportData: [...state.exportData,...normalize.exportData],
    employeeKeyActivity: [...state.employeeKeyActivity,...normalize.data]
  }
}

function employeeKeyActivitiesNormalize(data,imagelists,position) {
  if (data.length === 0) return {data,exportData:[],position};
  let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let employee = [];
  let exportData = [];
  for(let asset of data) {
    let kioskID = _.get(asset,'kioskID');
    let imagelist = _.get(imagelists,kioskID);
    let timelists = _.map(imagelist,'time');
    let endTime = moment(asset.Completed);
    let startTime = moment(asset.Started);
    let Created = asset.Created ? moment.utc( asset.Created ).local().tz(timezone).format( 'YYYY-MM-DD hh:mm:ssA z' ) : '';
    let from = asset.Started;
    let to = asset.Completed;
    let Completed = asset.Created ? moment.utc( asset.Completed ).local().tz(timezone).format( 'YYYY-MM-DD hh:mm:ssA z' ) : '';
    let Started = asset.Created ? moment.utc( asset.Started ).local().tz(timezone).format( 'YYYY-MM-DD hh:mm:ssA z' ) : '';
    if( !asset.Started ) {
      startTime = moment( asset.Completed );
      startTime.subtract( 10, 'seconds' );
    }
    let duration = '';
    if(asset.Started) {
      let created = moment(asset.Started);
      let completed = moment(asset.Completed);
      duration = moment.duration(completed.diff(created));
      let minutes = Math.floor(duration.asMinutes());
      let seconds = duration.seconds().toString().padStart(2, '0');
      duration = `${minutes}: ${seconds}`;
    }
    let startIndex = _.sortedIndex(timelists,startTime.toISOString());
    let endIndex = _.sortedIndex(timelists,endTime.toISOString()) + 1;
    imagelist = _.slice(imagelist,startIndex,endIndex);
    // Reason -- react table filter search look for non existing keywords will define null value as found
    let Reason = asset.Reason || '';
    position =  asset.transactionID;
    exportData.push({
      ['Asset Name']:asset['Asset Name'],
      ['Stock Number']:asset['Stock Number'],
      Reason,
      Started,
      Completed,
      Created,
      Type:asset['Type'],
      duration
    });
    employee.push({...asset,Reason,imagelist,Started,Completed,Created,duration,from,to});
  }
  position += 1;
  return {data:employee,exportData,position};
}
