import { get, find } from 'lodash';
  
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { multilanguage } from 'redux-multilanguage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Terminal } from 'xterm';
import '~/../xterm/css/xterm.css';

import { terminal2Hide, terminal2Leave, terminal2Close, terminal2Attach,
  terminal2SendData } from '../actions/terminal';

import './Terminal2ModalGroup.css';

class Terminal2ModalGroup extends Component {
  constructor( props ) {
    super( props );
    this.state = {
      drag: {
        originLeft: 0,
        originTop: 0,
        originX: 0,
        originY: 0,
        target: null
      },
      resize: {
        originWidth: 0,
        originHeight: 0,
        originX: 0,
        originY: 0,
        target: null
      },
    };
    this.startDrag = this.startDrag.bind(this);
    this.onDrag = this.onDrag.bind(this);
    this.startResize = this.startResize.bind(this);
    this.onResize = this.onResize.bind(this);
    this.stopDragOrResize = this.stopDragOrResize.bind(this);
    this.putOnTopOfZIndex = this.putOnTopOfZIndex.bind(this);
  }

  startDrag( e, container_id ) {
    e.preventDefault();
    let target = document.getElementById( container_id );
    this.setState({ drag: {
      originLeft: target.offsetLeft, 
      originTop: target.offsetTop,
      originX: e.clientX,
      originY: e.clientY,
      target } });
    document.onmouseup = this.stopDragOrResize;
    document.onmousemove = this.onDrag;
  }

  onDrag( e ) {
    e.preventDefault();
    let t = this.state.drag.target;
    let deltaX = e.clientX - this.state.drag.originX;
    let deltaY = e.clientY - this.state.drag.originY;

    if( t ) {
      t.style.top = ( this.state.drag.originTop + deltaY ) + "px";
      t.style.left = ( this.state.drag.originLeft + deltaX ) + "px";
    }
  }

  stopDragOrResize( e ) {
    document.onmouseup = null;
    document.onmousemove = null;
  }

  startResize( e, container_id ) {
    e.preventDefault();
    let target = document.getElementById( container_id );
    this.setState({ resize: { 
      originWidth: target.clientWidth, 
      originHeight: target.clientHeight,
      originX: e.clientX,
      originY: e.clientY,
      target } });
    document.onmouseup = this.stopDragOrResize;
    document.onmousemove = this.onResize;
  }

  onResize( e ) {
    e.preventDefault();
    let t = this.state.resize.target;
    let deltaX = e.clientX - this.state.resize.originX;
    let deltaY = e.clientY - this.state.resize.originY;

    if( t ) {
      t.style.height = ( this.state.resize.originHeight + deltaY ) + "px";
      t.style.width = ( this.state.resize.originWidth + deltaX ) + "px";
    }
  }

  putOnTopOfZIndex( component_id ) {
    for( let div of document.getElementsByClassName('terminal2-modal-container') ) {
      div.style.zIndex = ( div.id == component_id ) ? 100 : 99;
    }
  }

  componentDidUpdate() {
    for( let kioskid in this.props.sessions ) {
      for( let id in this.props.sessions[kioskid] ) {
        if( this.props.sessions[kioskid][id].terminal )
          continue;

        let div = document.getElementById(
          `terminal2-modal-kiosk_${kioskid}-terminal_${id}-render` );
        if( !div ) {
          console.log( 'ERROR: Unable to find div that should exist at this point: ' +
            `terminal2-modal-kiosk_${kioskid}-terminal_${id}-render` );
          continue;
        }

        let term = new Terminal();
        if( !term ) {
          console.log( 'ERROR: Unable to create terminal - new returned falsey.' );
          continue;
        }

        term.onData( raw => {
          this.props.terminal2SendData( kioskid, id, btoa( raw ) );
        } );

        this.props.terminal2Attach( kioskid, id, term, div );
      }
    }
  }

  render() {
    let terminal_windows = [];

    for( let kioskid in this.props.sessions ) {
      for( let id in this.props.sessions[kioskid] ) {
        let container_id = `terminal2-modal-kiosk_${kioskid}-terminal_${id}`;

        let class_name = this.props.sessions[kioskid][id].visible ?
          "terminal2-modal-container" : "terminal2-modal-container terminal2-modal-hidden";
        let kiosk_name = get( find( this.props.kiosks, { kioskID: kioskid } ), 
          'kioskName' );

        terminal_windows.push(
          <div className={class_name} id={container_id}
              key={container_id} onClick={() => {this.putOnTopOfZIndex( container_id );}}>
            <div className={"terminal2-modal-header"}>
              <span className={"terminal2-modal-dragbar"}
                  onMouseDown={(e) => this.startDrag(e, container_id)}>
                {kiosk_name} - Terminal {id}
              </span>
              <Button className={"terminal2-modal-headerbutton"}
                  onClick={() => {this.props.terminal2Hide( kioskid, id );}}>
                -
              </Button>
              {/*<Button className={"terminal2-modal-headerbutton"}
                  onClick={() => {this.props.terminal2Leave( kioskid, id );}}>
                Leave
              </Button>*/}
              <Button className={"terminal2-modal-headerbutton"}
                  onClick={() => {this.props.terminal2Close( kioskid, id );}}>
                x
              </Button>
            </div>
            <div className={"terminal2-modal-body"} id={container_id + '-render'}>
            </div>
            <div className={"terminal2-modal-footer"}>
              <span className={"terminal2-modal-footercontent"}></span>
              <span className={"terminal2-modal-resizehandle"}
                  onMouseDown={(e) => {} /*this.startResize(e, container_id)*/}>
                </span>
            </div>
          </div>
        );
      }
    }

    return (
      <div>
        {terminal_windows}
      </div>
    );
  }
};

const mapDispatchToProps = ( dispatch ) => {
  return {
    terminal2Hide: ( kioskid, id ) => {
      return dispatch( terminal2Hide({ kioskid, id }) );
    },
    terminal2Leave: ( kioskid, id ) => {
      return dispatch( terminal2Leave({ kioskid, id }) );
    },
    terminal2Close: ( kioskid, id ) => {
      return dispatch( terminal2Close({ kioskid, id }) );
    },
    terminal2Attach: ( kioskid, id, term, div ) => {
      return dispatch( terminal2Attach({ kioskid, id, term, div }) );
    },
    terminal2SendData: ( kioskid, id, data ) => {
      return dispatch( terminal2SendData({ kioskid, id, data }) );
    },
  };
};

const mapStateToProps = ( state, ownProps ) => {
  return {
    sessions: get( state, 'terminal.terminal2sessions' ),
    kiosks: get( state, 'kiosks' ),
  };
};

export default connect( mapStateToProps, mapDispatchToProps )
  ( multilanguage( Terminal2ModalGroup ) );
