import reducer from './reducers';

import { createStore, applyMiddleware } from 'redux';
import createLogger from 'redux-logger';
import thunk from 'redux-thunk';
import {
  composeWithDevTools,
} from 'redux-devtools-extension/logOnlyInProduction';

const middleware = [thunk];
if (process.env.NODE_ENV !== 'production') {
  middleware.push(createLogger({ collapsed: true }));
}

const store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(...middleware)),
);

window.store = store;

export default store;
