import _, {cloneDeep} from 'lodash';
import moment from 'moment';

const initialState = {
  list: [],
  users: {},
  reports:[],
  start:'',
  end:'',
};

export default function service(state = initialState, action) {
  switch(action.type) {
    case 'RECEIVE_ASSETHATSTYLESLIST':
      return receiveAssethatStyleList(state,action);
    case 'RECEIVE_ASSETHATSTYLESUPDATE':
      return receiveAssethatStlyeUpdate(state,action);
    case 'RECEIVE_ASSETHATSTYLESADD':
      return receiveAssetHatStylesAdd(state,action);
    case 'RECEIVE_ASSETHATSTYLESDELETE':
      return receiveAssetHatStlyeDelete(state,action);
    case 'RECEIVE_SERVICEHISTORY':
      return receiveServiceHistory(state,action);
    case 'RECEIVE_SERVICETYPESGET':
      return receiveServiceGetTypes(state,action);
    case 'HANDLE_LOGOUT':
      return cloneDeep(initialState);
    default:
      return state;
  }
}

function receiveAssethatStyleList(state,action) {
  const list = _.get(action,'data.hatstyles');
  const users = _.get(action,'data.hatusers');
  return {...state,list,users};
}

function receiveAssethatStlyeUpdate(state,action) {
  const data = _.head(action.data.updatedstyles);
  const list = _.get(state,'list');
  const userid = _.get(data,'userid');
  const index = _.findIndex(list,{userid});
  list[index] = data;
  return {...state,list};
}

function receiveAssetHatStylesAdd(state,action) {
  const data = _.get(action,'data.addedstyles.0');
  let list = _.get(state,'list');
  list = [...list,data];
  return {...state,list};
}

function receiveAssetHatStlyeDelete(state,action) {
  const data = _.get(action,'data.deletedstyles.0');
  const id = _.get(data,'id');
  let list = _.get(state,'list');
  list = _.filter(list,user=>user.id !== id);
  return {...state,list};
}

function receiveServiceHistory(state,action) {
  const reports = _.map(_.get(action,'data.servicehistory'),report=>
    ({...report,
      scheduled:moment(report.scheduled).format('MM/DD/YYYY h:mm A z'),
      arrivaltime:moment(report.arrivaltime).format('MM/DD/YYYY h:mm A z'),
    }));
  const start = _.get(action,'data.start');
  const end = _.get(action,'data.end');
  return {...state,reports,start,end};
}

function receiveServiceGetTypes(state, action) {
  const types = _.get(action,'data');
  _.unset(types,'command');
  return {...state,types};
}
