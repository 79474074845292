import _ from 'lodash';
import {HANDLE_REGISTER_SESSION_ATTEMPT, HANDLE_REGISTER_SESSION_SUCCESS, HANDLE_LOGOUT} from './../actions/currentUser';
import {CANCEL_TWO_FACTOR_AUTHENTICATION, CLEAR_ALTERNATE_SERVER} from "../actions/currentUser";

const initialState = {
  register:false,
  code2fa:false,
  activeLoginType: localStorage.getItem('activeLoginType') || '',
  loginTypes: [
    { name: 'None', key: '' },
    { name: 'Azure AD', key: 'azure-ad' },
  ],
};

export default function user(state = initialState, action) {
  switch (action.type) {
    case 'RECEIVE_GROUPLIST':
      return grouplist(state,action);
    case 'RECEIVE_REGISTER':
      return {...state,
        displayUsername: action.data.username,
        language:action.data.language,
        register:true,
        code2fa:false,
      };
    case 'SWITCH_SERVER':
      return {...state,sessionId:null};
    case 'CANCEL_TWO_FACTOR_AUTHENTICATION':
      return {...state,
        invalid: false,
        code2fa:false,
        register:false,
      };
    case 'RECEIVE_PERMISSIONS':
      return {
        ...state,
        permissions: action.data.permissions
      };
    case HANDLE_REGISTER_SESSION_SUCCESS:
      return {
        ...state,
        sessionId: action.phpsessid,
        invalid: false
      };
    case 'HANDLE_REGISTER_SESSION_FAILURE':
      return {
        ...state,
        invalid: true
      };
    case 'RECEIVE_USERINFOGETCONTACT':
      return receiveUserInfoGetContact(state, action.data);
    case HANDLE_REGISTER_SESSION_ATTEMPT:
      return {
        ...state,
        username: action.username,
        password: action.password,
        invalid: action.username && action.password || action.phpsessid,
        serveroptions: null
      };
    case 'CLEAR_ALTERNATE_SERVER':
      return {
        ...state,
        serveroptions: localStorage.getItem( 'token' ) ? state.serveroptions : null
      };
    case HANDLE_LOGOUT:
      return initialState;
    case 'RECEIVE_REGISTER_OPTIONS':
      localStorage.setItem('token',_.get(action,'data.token'));
      return {
        ...state,
        code2fa:false,
        serveroptions: _.get( action, 'data.serveroptions' ),
      };
    case 'RECEIVE_TWOFACTORCHALLENGE':
      return {...state,code2fa:true,invalid: false};
    case 'HANDLE_LOGOUT':
      return _.cloneDeep(initialState);
    case 'HANDLE_SWITCHLOGINTYPE':
      let activeLoginType = _.get( action, 'loginType' );
      localStorage.setItem( 'activeLoginType', activeLoginType );
      return {...state, activeLoginType };
    case 'RECEIVE_NEWOAUTH':
      window.open( _.get( action, 'data.oauthurl' ) );
      console.log(action);
      return state;
    default:
      return state
  }
}

function grouplist(state, action) {
  let types = _.get(action,'data.types');
  return {...state,types}
}

function receiveUserInfoGetContact(state, data) {
  let contacts = _.get(data,'contacts');
  return {...state,contacts}
}
