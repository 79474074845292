import socket from '../socket';

export const TOGGLE_ASSET_ARCHIVED = 'TOGGLE_ASSET_ARCHIVED';
export const RECEIVE_ASSETARCHIVEDSET = 'RECEIVE_ASSETARCHIVEDSET';
export const toggleAssetArchived = asset => dispatch => {
  const assetID = asset.assetID;
  const newIsArchivedValue = !asset.isArchived;
  socket.send({
    command: 'assetarchivedset',
    assetid: assetID,
    // Yes, it's `archived`, not `archive` despite everything else in the API.
    archived: newIsArchivedValue ? 1 : 0,
  });
  dispatch({ type: TOGGLE_ASSET_ARCHIVED, assetID, newIsArchivedValue });
};

export const FETCH_ASSET = 'FETCH_ASSET';
export const RECEIVE_ASSET = 'RECEIVE_ASSETINFOFULL';
export const fetchAsset = assetID => dispatch => {
  socket.send({
    command: 'assetinfofull',
    assetid: assetID,
  });
  dispatch({ type: FETCH_ASSET, assetID });
};

export const FETCH_ALL_ASSETS = 'FETCH_ALL_ASSETS';
export const RECEIVE_ALL_ASSETS = 'RECEIVE_ASSETSEARCH';
export const fetchAllAssets = (
  containerID,
  { filter, limit, offset,archive },
) => dispatch => {
  socket.send({
    command: 'assetsearch',
    container: containerID,
    searchstring: filter,
    limit,
    offset,
    archive,
  });
  dispatch({ type: FETCH_ALL_ASSETS, containerID, filter, limit, offset, archive });
};

export const FETCH_HISTORY = 'FETCH_HISTORY';
export const RECEIVE_HISTORY = 'RECEIVE_ASSETHISTORY';
export const fetchHistory = assetID => dispatch => {
  socket.send({
    command: 'assethistory',
    assetid: assetID,
  });
  dispatch({ type: FETCH_HISTORY, assetID });
};

export const CLEAR_HISTORY = 'CLEAR_HISTORY';
export const clearHistory = () => ({ type: CLEAR_HISTORY });

export const UPDATE_ASSET_DETAIL = 'UPDATE_ASSET_DETAIL';
export const updateAssetDetail = ({
  assetID,
  fieldName,
  value,
  containerid
}) => dispatch => {
  socket.send({
    command: 'assetdetailset',
    assetid: assetID,
    fieldname: fieldName,
    detail: value,
    containerid
  });
  dispatch({ type: UPDATE_ASSET_DETAIL, assetID, fieldName, value });
};

export const CHANGE_ASSET_LIST_QUERY = 'CHANGE_ASSET_LIST_QUERY';
export const changeAssetListQuery = ({ filter, limit, offset,archive }) => ({
  type: CHANGE_ASSET_LIST_QUERY,
  filter,
  limit,
  offset,
  archive
});

export const eventHistoryGet = param => dispatch => {
  socket.send({
    command: 'asseteventhistoryget',
    ...param,
  });
};


export const RESTART_ASSET_FETCH = 'RESTART_ASSET_FETCH';
export const refetchAssets = () => dispatch => {
  dispatch({ type: RESTART_ASSET_FETCH });
};


