import _, {cloneDeep} from 'lodash';
import timeZones from '../timezones';

const initialState = {
  masterOfflineEmergencyDocument: {},
  isMasterAdmin: false,
  timeZones
};

export default function setting(state = initialState, action) {
  switch (action.type) {
    case 'RECEIVE_MASTERADMIN':
      return recieveMasterAdmin(state,action);
    case 'RECEIVE_MASTERUSER':
      return recieveMasterAdminGet(state,action);
    case 'RECEIVE_MASTERUSERSUBMIT':
      return recieveMasterUserSubmit(state,action);
    case 'HANDLE_LOGOUT':
      return cloneDeep(initialState);
    default:
      return state;
  }
}

function recieveMasterAdmin(state,action) {
  let isMasterAdmin = _.get(action,'data.command') === "masteradmin";
  return{...state,isMasterAdmin}
}

function recieveMasterAdminGet(state,action) {
  let masterOfflineEmergencyDocument = _.get(action,'data');
  return{...state,masterOfflineEmergencyDocument}
}

function recieveMasterUserSubmit(state,action) {
  let masterOfflineEmergencyDocument = _.get(action,'data');
  return{...state,masterOfflineEmergencyDocument}
}
