import {CONFIGURATION_CLEAR} from '../actions/configuration';
import {cloneDeep} from "lodash";

const initialState = {
  filename: '',
  contents: null
};

export default function configuration(state = initialState, action) {
  switch(action.type) {
    case 'RECEIVE_WATCHDOGCONFIG':
      return recieveWatchDogConfig(state,action);
    case CONFIGURATION_CLEAR:
      return recieveConfigClear(state,action);
    case 'HANDLE_LOGOUT':
      return cloneDeep(initialState);
    default:
      return state;
  }
}

function recieveWatchDogConfig(state,action) {
  const {contents,filename} = action.data;
  return {...state,contents,filename};
}

function recieveConfigClear(state,action) {
  return {...state,contents:null};
}
