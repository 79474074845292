import _, {cloneDeep} from 'lodash';

const initialState = {
  hierarchyFetched: false,
  commandlist:[],
  vnc: {}
};

export default function newkiosReducer (state = initialState, action) {
  switch (action.type) {
    case 'RECEIVE_COMMANDGETSTANDARDLIST':
      return receiveCommandGetStandardList(state, action);
    case 'RECEIVE_HIERARCHYGET':
      return { ...state, hierarchyFetched: true };
    case 'RECEIVE_CURRENTPAGE':
      return receiveVncCurrentPage(state,action);
    case 'HANDLE_LOGOUT':
      return cloneDeep(initialState);
    default:
      return state;
  }
}

function receiveCommandGetStandardList(state, action) {
  const commandlist = _.get(action,'data.standardcommands');
  return {
    ...state,
    commandlist
  }
}

function receiveVncCurrentPage( state, action ) {
  let vnc = _.get(action,'data.vnc');
  return {...state, vnc}
}
