import _, {cloneDeep} from 'lodash';
import moment from 'moment';

const TIMESHEETDATEFORMAT = "YYYY-MM-DD";

const initialState = {
  timechunksbyuserid: {},
  context: {
    startdate: localStorage.getItem('timesheets2.startdate') ||
      moment().subtract( 3, 'd' ).format( TIMESHEETDATEFORMAT ),
    enddate: localStorage.getItem('timesheets2.enddate') ||
      moment().format( TIMESHEETDATEFORMAT ),
    hourmin: localStorage.getItem('timesheets2.hourmin') || 0,
    hourlimit: localStorage.getItem('timesheets2.hourlimit' ) || 16,
    totalbonus: 0,
    selectedgroup: { label: '---', value: null },
  },
  usertotals: {},
};

export default function timesheets2( state = initialState, action ) {
  switch( action.type ) {
    case 'SET_TIMESHEETV2_CONTEXT':
      return receiveSetTimesheetV2Context( state, action );
    case 'FETCH_TIMESHEETV2':
      return { ...state, timechunksbyuserid: {}, usertotals: {} };
    case 'RECEIVE_TIMESHEET2_FETCH':
      return receiveTimesheetV2Fetch( state, action );
    case 'SET_TIMESHEETV2_USERTOTAL':
      return { ...state,
        usertotals: {
          ...state.usertotals,
          [ action.userid ]: _.pick( action, [ 'total', 'checked' ] )
        } };
    case 'CHECKALL_TIMESHEETV2_BONUS':
      return { ...state, usertotals: _.reduce( state.usertotals, ( a, v, k ) => {
        a[ k ] = { ...v, checked: action.checked };
        return a;
      }, {} ) };
    case 'HANDLE_LOGOUT':
      return cloneDeep(initialState);
    default:
      return state;
  };
}

function receiveSetTimesheetV2Context( state, action ) {
  let context = _.get( action, 'context' );

  if( 'hourmin' in context )
    localStorage.setItem( 'timesheets2.hourmin', context.hourmin );
  if( 'hourlimit' in context )
    localStorage.setItem( 'timesheets2.hourlimit', context.hourlimit );
  if( 'startdate' in context )
    localStorage.setItem( 'timesheets2.startdate', context.startdate );
  if( 'enddate' in context )
    localStorage.setItem( 'timesheets2.enddate', context.enddate );

  return { ...state, context: { ...state.context, ...context } };
};

function receiveTimesheetV2Fetch( state, action ) {
  let chunks = _.groupBy( _.get( action, 'data.chunks' ), 'userid' );
  let IID = _.get( action, 'data.IID' );
  let chunktypes = _.reduce( _.get( action, 'data.chunktypes' ), ( a, v ) => {
    a[ _.get( v, 'typeid' ) ] = _.get( v, 'name' );
    return a;
  }, {} );
  let adptypes = _.reduce( _.get( action, 'data.chunktypes' ), ( a, v ) => {
    a[ _.get( v, 'typeid' ) ] = _.get( v, 'adpname' );
    return a;
  }, {} );

  let timechunksbyuserid = _.reduce( chunks, ( a, newchunks, userid ) => {
      let oldchunks = _.get( state, userid );
      if( _.isEqual( oldchunks, newchunks ) )
        a[ userid ] = oldchunks;
      else
        a[ userid ] = newchunks;
      return a;
  }, {} );

  let usertotals = _.reduce( timechunksbyuserid, ( a, v, userid ) => {
    if( state.usertotals[ userid ] )
      a[ userid ] = state.usertotals[ userid ];
    else
      a[ userid ] = { total: 0, checked: false };
    return a;
  }, {} );

  return { ...state, usertotals, timechunksbyuserid, chunktypes, adptypes, IID };
}
