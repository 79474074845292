import React from 'react';
import {logError} from '../actions/logError';
import { multilanguage} from "redux-multilanguage";
import {connect} from "react-redux";
import {valueNullToString} from "../languageTranslate";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    this.props.logError(error, errorInfo, this.props.componentName);
  }

  lang = s => valueNullToString(this.props.strings,s);

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          {this.props.title ? <h5>{this.lang(this.props.title)}</h5> : null}
          {!this.props.noMessage ? this.props.message ? <p>{this.lang(this.props.message)}</p>
            : this.lang('Something went wrong.') : null}
        </div>);
    }

    return this.props.children;
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    logError: ( error, errorinfo, name ) => dispatch( logError( error, errorinfo, name ) )
  };
};

const mapStateToProps = (state) => {
  return {
  }
};

ErrorBoundary.defaultProps = {
  title:'',
  message:'',
  componentName:'',
  noMessage:false
};


export default connect(mapStateToProps,mapDispatchToProps)(multilanguage(ErrorBoundary));
