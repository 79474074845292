import React from 'react';
import { Link } from 'react-router-dom';
import { compact, keys, head, map } from 'lodash';
import CustomerImage from './CustomerImage';

export const assetLink = ( assetid, string ) =>  (
    <Link
      to={`${process.env.PUBLIC_URL}/assets/${assetid}`}
      className="btn link"
    >{string}</Link>
  );

export const userLink = ( userid, string ) => (
    <Link
      to={`${process.env.PUBLIC_URL}/users/${userid}`}
      className="btn link"
    >{string}</Link>
  );

export const detailLink = ( customerid, string ) => (
    <Link
      to={`${process.env.PUBLIC_URL}/customer/detail/${customerid}`}
      className="btn link"
    >{string}</Link>
  );

export const unverifyLink = ( documentid, string ) => (
    <Link
      to={`${process.env.PUBLIC_URL}/customer/unverified-detail/${documentid}`}
      className="btn link"
    >{string}</Link>
  );

const buildTable = data => {
  let table = map(data, obj => {
    let {
      assetid,
      name,
      date,
      assetname,
      username,
      userid,
      customerid,
      firstname,
      lastname,
      phone,
      verified,
      documentid,
      image,
    } = obj;
    let build = {};

    if (verified === 0) {
      // Building links for Unverify Table
      build.name = unverifyLink(documentid,name);
      if (userid && username) build.username = userLink(userid,username);
      build.date = unverifyLink(documentid,date);
      build.image = <CustomerImage url={image} height={'6em'}/>
    } else {
      // Building links for Customer Table
      if (assetid && assetname) build.assetname = assetLink(assetid,assetname);
      if (username) build.username = userLink(userid,username);
      if (customerid && firstname) build.firstname = detailLink(customerid,firstname);
      if (customerid && lastname) build.lastname = detailLink(customerid,lastname);
      if (phone) build.phone = phone.substr(-4);
    }

    return { ...obj, ...build };
  });

  const datakeys = keys(head(data));
  const header = compact(map(datakeys,accessor =>
    (!accessor.includes('id') && !accessor.includes('verified')) ? {Header:accessor,accessor} : ''));

  return { header, table }
};

export default buildTable;
