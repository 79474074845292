import React from 'react';
import { Col } from 'reactstrap';
import Zoom from 'react-thumbnail-zoom';
import "react-image-gallery/styles/css/image-gallery.css";
import {multilanguage} from "redux-multilanguage";

const CustomerImage = ({url,height,width,strings})=> {
  const imageContent = url ?
    <Zoom className="customer-image-zoom">
      <img src={url} alt="" style={{height,width}}/>
    </Zoom>
    :
    <p>{strings['No Image'] ? strings['No Image']: 'No Image'}</p>;
  return (
    <Col className="text-center">
      {imageContent}
    </Col>
  )
};

CustomerImage.defaultProps = {
  height: 'auto',
  width: 'auto',
};

export default multilanguage(CustomerImage);
