const initialState = [];

export default function errors(state = initialState, action) {
  switch (action.type) {
    case 'ERROR':
      state = state.concat(action.data);
      return state;
    default:
      return state
  }
}
