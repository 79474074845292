import socket from './../socket';
import _ from 'lodash';

export const REPORT_TYPES = {
  // Dashboard Reports
  DASHBOARD_EMPLOYEE_KEY_ACTIVITY: '4',
  DASHBOARD_KEYS_CHECKED_OUT: '5'
}

export const REPORT_NAMES = {
  // Dashboard Reports
  [REPORT_TYPES.DASHBOARD_EMPLOYEE_KEY_ACTIVITY]: "Employee Key Activity",
  [REPORT_TYPES.DASHBOARD_KEYS_CHECKED_OUT]: "Keys Checked Out",
}

export const FETCH_DASHBOARD_REPORT = 'FETCH_DASHBOARD_REPORT';
export const fetchDashboardReport = (id, parameters) => {
  return {
    type: FETCH_DASHBOARD_REPORT,
    data: {
      id,
      parameters
    }
  }
}

export const fetchEmployeeKeyActivityDashboardReport = (parameters = {}) => {
  return (dispatch, getState) => {
    new Promise((resolve, reject) => {
      let id = REPORT_TYPES.DASHBOARD_EMPLOYEE_KEY_ACTIVITY;
      let currentContainer = _.get(getState(), 'containers.currentContainer')
      if (currentContainer) {
        parameters.containerid = currentContainer;
      }

      let payload = {
        command: "report",
        id,
        name: REPORT_NAMES[id],
        parameters: parameters,
      }
      socket.send(payload);
      dispatch(fetchDashboardReport(REPORT_TYPES.DASHBOARD_EMPLOYEE_KEY_ACTIVITY, parameters));
    });
  }
}

export const FETCH_KEYS_CHECKED_OUT_REPORT = 'FETCH_KEYS_CHECKED_OUT_REPORT';

export const fetchKeysCheckedOutDashboardReport = (parameters = {}) => {
  return (dispatch, getState) => {
    let id = REPORT_TYPES.DASHBOARD_KEYS_CHECKED_OUT;
    let currentContainer = _.get(getState(), 'containers.currentContainer')
    if (currentContainer) {
      parameters.containerid = currentContainer;
    }
    let payload = {
      command: "report",
      id,
      name: REPORT_NAMES[id],
      parameters: parameters
    }
    socket.send(payload);
    dispatch(fetchDashboardReport(REPORT_TYPES.DASHBOARD_KEYS_CHECKED_OUT, parameters));
  }
}

export const GET_DASHBOARD_STATS = 'GET_DASHBOARD_STATS';
export const getDashboardStats = ({containerID,timezone,todayDate}) => {
  return (dispatch,getState) => {
    let payload = {
      command: 'dashboardgetstats',
      containerID,
      timezone,
      todayDate
    };
    socket.send(payload);
    dispatch({type:GET_DASHBOARD_STATS,containerID})
  }
};
