import React from 'react';
import { Link } from 'react-router-dom';
import ArchiveButton from './ArchiveButton';
import _ from 'lodash';

// Assets have multiples color keys, color function will search for the proper key
const colors = [
  "exteriorColor_genericColor_name",
  "exteriorColor_genericColor_primary",
  "exteriorColor_colorCode",
  "exteriorColor_colorCode",
  "exteriorColor_colorName",
  "exteriorColor_rgbValue",
  "genericColor_name",
  "genericColor_primary",
  "color"
];

export const TableFormat = (assetsBuild) => {
  const inverse = {color: '#fff',backgroundColor: '#ff0000'};
  return _.map(assetsBuild,(asset)=>{
    let id = _.get(asset,'identifier');
    let assetid = _.get(asset,'assetID');

    // asset id 1 is an message data
    if( assetid === 1 ) return { identifier: id };

    let assetInventory = _.get(asset,'inventory');
    let archived = _.get(asset,'archived');
    let stockNumber = (<Link
      style={archived ? inverse : {}}
      to={`${process.env.PUBLIC_URL}/assets/${assetid}`}
      className="btn link"
    >{_.get(asset,'stockNumber')}</Link>);
    let identifier = (<Link
      style={archived ? inverse : {}}
      to={`${process.env.PUBLIC_URL}/assets/${assetid}`}
      className="btn link"
    >{id}</Link>);
    let lastLocation = _.get(assetInventory,'LotLocation');
    let status = (<ArchiveButton asset={asset} />);
    return {...asset,stockNumber,identifier,archived,lastLocation,status};
  });
};

export const AssetNormalize = (assets)=> {
  return _.map(assets,(asset)=>{
    let assetInventory = _.get(asset,'inventory');
    let lastLocation = _.get(assetInventory,'LotLocation');
    let color = _.head(_.values(_.pickBy(assetInventory,(value, key)=> {
      return _.some(colors, (str)=> _.includes(key, str));
    })));
    let condition = _.get(asset,'condition');
    let archived = _.get(asset,'isArchived') || _.get(asset,'archived');
    let stockNumber = _.get(asset,'stockNumber');
    let identifier = _.get(asset,'Identifier') || _.get(asset,'identifier');
    return {...asset,...assetInventory,condition,color,lastLocation,stockNumber,identifier,archived};
  });
};
