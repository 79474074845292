import {get,map,remove} from 'lodash';

const initialState = {
  list: []
};

export default function bins(state = initialState, action) {
  switch(action.type) {
    case 'RECEIVE_BINGETLIST':
      return receiveBinGetList(state, action);
    case 'RECEIVE_BINLOOKUP':
      return receiveBinLookUp(state, action);
    case 'RECEIVE_BINNEW':
      return receiveBinNew(state, action);
    case 'RECEIVE_BINDELETE':
      return receiveBinDelete(state, action);
    case 'RECEIVE_BINRENAME':
      return receiveBinRename(state, action);
    case 'RECEIVE_BINCHANGEDKIOSK':
      return receiveBinChangeKiosk(state, action);
    default:
      return state;
  }
}

function receiveBinGetList(state, action) {
  let list = get(action,'data.list');
  return {...state,list}
}

function receiveBinLookUp(state, action) {
  let bin = get(action,'data.bin.0');
  return {...state,list:[...state.list,bin]}
}

function receiveBinNew(state, action) {
  console.log('receiveBinNew',action);
  let bin = get(action,'data.bin.0');
  return {...state,list:[...state.list,bin]};
}

function receiveBinDelete(state, action) {
  let uuid = get(action,'data.uuid');
  let list = remove(state.list, bin => {
    return bin.kioskUID === uuid;
  });
  return {...state,list}
}

function receiveBinRename(state, action) {
  let {uuid,name} = get(action,'data');
  let list = map(state.list,bin=> {
    if(bin.bin_uuid === uuid) {
      return {...bin,bin_uuid:uuid,bin_name:name}
    }
    return bin
  });
  return {...state,list}
}

function receiveBinChangeKiosk(state, action) {
  let {bin} = get(action, 'data');
  let list = map(state.list, b => {
    if (b.bin_uuid === bin.bin_uuid) {
      return {...bin, kioskid: bin.kioskid}
    }
    return b
  });
  return {...state, list};
}
