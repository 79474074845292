import { combineReducers } from 'redux';
import adminErrorLog from './adminErrorLog';
import auditlog from './auditlog';
import bins from './bins';
import assemblyinventory from './assemblyinventory';
import newboardconfig from './newboardconfig';
import creditcard from './creditcard';
import configuration from './configuration';
import containers from './containers';
import consumable from './consumable';
import customer from './customer';
import deviceconfig from './deviceconfig';
import device from './device';
import document from './document';
import drawercommunication from './drawercommunication';
import ioptix from './ioptix';
import newkios from './newkios';
import reports from './reports';
import reportsv2 from './reportsv2';
import currentUser from './currentUser';
import users from './users';
import useralert from './userAlert';
import errors from './errors';
import groups from './groups';
import kiosks from './kiosks';
import integrations from './integrations';
import language from './language';
import lotlocations from './lotlocations';
import locationstall from './locationstall';
import permissions from './permissions';
import patches from './patches';
import tagRanges from './tagRanges';
import tagProgram from './tagProgram';
import twofractorauthenication from './twofractorauthenication';
import assets from './assets';
import dealers from './dealers';
import bookin from './bookin';
import gps from './gps';
import userVerification from './userVerification';
import vnc from './vnc';
import userinfo from './userinfo';
import service from './service';
import setting from './setting';
import support from './support';
import solo from './solo';
import securityEvent from './securityEvent';
import connectionStatus from './connectionStatus';
import dashboard from './dashboard';
import transactions from './transactions';
import terminal from './terminal';
import companyInfo from './companyInfo';
import watchdog from './watchdog';
import version from './version';
import windowsize from './windowsize';
import timesheets2 from './timesheets2';
import {createMultilanguageReducer} from 'redux-multilanguage'
import { routerReducer } from 'react-router-redux';
import {reducer as toastrReducer} from 'react-redux-toastr';

const rootReducer = combineReducers({
  adminErrorLog,
  auditlog,
  bins,
  assemblyinventory,
  reports,
  reportsv2,
  newboardconfig,
  creditcard,
  customer,
  configuration,
  containers,
  currentUser,
  consumable,
  document,
  drawercommunication,
  device,
  errors,
  users,
  groups,
  kiosks,
  ioptix,
  integrations,
  language,
  lotlocations,
  locationstall,
  newkios,
  patches,
  permissions,
  securityEvent,
  service,
  setting,
  support,
  tagRanges,
  twofractorauthenication,
  tagProgram,
  assets,
  bookin,
  dealers,
  deviceconfig,
  gps,
  vnc,
  userVerification,
  useralert,
  userinfo,
  connectionStatus,
  dashboard,
  terminal,
  transactions,
  companyInfo,
  multilanguage: createMultilanguageReducer({currentLanguageCode: 'en'}),
  routing: routerReducer,
  toastr: toastrReducer,
  watchdog,
  windowsize,
  solo,
  version,
  timesheets2,
});

export default rootReducer;
