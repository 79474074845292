import _, {drop, each, get, head, last, round, size, take, takeRight} from 'lodash';
import moment from 'moment';
import { RECEIVE_GPS_LOCATIONS, RECEIVE_GPS_LOCATIONS_BY_USER,RECIEVE_MOCK_GPS_TEST_DRIVES,GET_HISTORY_LOCATION} from './../actions/gps';
import {buildTripTable} from '../TrackingTestDrive/trackingfunction';
const initialState = {
  gpsActive: false,
  notallow: false, // permission
  locations: {}, // live mobile location trip
  assetsDriveTest: [],
  assets: {},
  assetHistory: [],
  tripLocationsHistory: {}, // Asset trip locations
  locationHistory:[] // one whole trip location
};

const normalizeLocation = location =>
  _.map(location, l =>
    ({latitude:l.x,longitude:l.y,radius:l.r,date:moment(l.d).format( 'MM/DD/YYYY hh:mm:ss A z' )}));

export default function gps(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_GPS_LOCATIONS:
    case RECEIVE_GPS_LOCATIONS_BY_USER:
      let userID;
      let userLocations = _.reverse(_.map(action.data.locations, (location) => {
        userID = location.userID;
        return {longitude: location.longitude, latitude: location.latitude, timestamp: location.when}
      }));
      let userLocationsSorted = _.orderBy(userLocations, (location) => {
        return new Date(location.timestamp);
      }, ['desc']);
      if (_.isEmpty(userLocationsSorted)) {
        return state;
      }
      return {
        ...state,
        locations: {
          ...state.locations,
          [userID]: userLocationsSorted
        }
      };
    case RECIEVE_MOCK_GPS_TEST_DRIVES:
      return recieveMockgpstestdrives(state, action);
    case 'RECEIVE_GPSTRACKINGMOCKHISTORY':
      return recieveGpsTrackingMockghistory(state, action);
    case 'RECEIVE_GPSGETTRIPHISTORY':
      return recieveGpsGetTripHistory(state, action);
    case 'RECEIVE_GPSGETASSETLIST':
      return recieveGpsGetAssetList(state, action);
    case 'RECEIVE_GPSLIVETRACK':
      return receiveGpsLiveTrack(state,action);
    case 'RECEIVE_GPSACTIVE':
      return receiveGpsActive(state,action);
    case 'RECEIVE_GPSTRIPTODAY':
      return receiveGpsTripToday(state,action);
    case GET_HISTORY_LOCATION:
      return getHistoryLocation(state,action);
    case 'HANDLE_LOGOUT':
      return _.cloneDeep(initialState);
    default:
      return state;
  }
}

// dummy function
function recieveMockgpstestdrives(state, action) {
  let data = _.get(action,'data');
  let currentAssets = _.assign({},state.assets);
  let assets = {...data,...currentAssets};
  return {...state,assets};
}

function recieveGpsTrackingMockghistory(state, action) {
  let assets = _.get(action,'data.assets');
  let assetHistory = _.map(assets,'assetID');
  return {...state,assetHistory};
}

function receiveGpsActive(state, action) {
  let gpsActive = _.get(action,'data.gps');
  let notallow = _.get(action,'data.notallow');
  return {...state,gpsActive,notallow};
}

function recieveGpsGetAssetList(state, action) {
  let {assets} = _.get(action,'data');
  // when switch container
  // clear locations when asset is empty
  let locations = _.isEmpty(assets) ? state.locations : {};
  return {...state,assets,locations};
}

function recieveGpsGetTripHistory(state, action) {
  let triphistory = _.map(_.get(action,'data.triphistory'),t =>
    ({...t,trip:normalizeLocation(t.trip)}));
  // store asset all trips location with tracking id
  let tripLocationsHistory = _.reduce(triphistory,(obj,value)=> {
    let id = value.trackingID;
    obj[id] = value.trip;
    return obj;
  },{});
  let trackingid = _.get(triphistory,'0.trackingID');
  // store one trip location which should be the recent trip for current asset
  let locationHistory = tripLocationsHistory[trackingid];
  // asset trip data with details
  let triptable = buildTripTable(triphistory);
  return {...state,triptable,tripLocationsHistory,locationHistory};
}

function getHistoryLocation(state,action) {
  let trackingid = action.trackingid;
  let tripLocationsHistory = state.tripLocationsHistory;
  let locationHistory = tripLocationsHistory[trackingid];
  return {...state,locationHistory}
}

function receiveGpsLiveTrack(state,action) {
  let data = _.get(action,'data.assets');
  let assetid = _.get(data,'assetid');
  let tracking = _.get(data,'tracking');
  console.log('tracking ****',tracking);
  let location = normalizeLocation(_.get(data,'tracking'));
  let assets = _.assign({},state.assets);
  assets[assetid] = {...data,location};
  return {...state,assets}
}

function receiveGpsTripToday(state,action) {
  let tripstoday = _.get(action,'data.tripstoday');
  console.log('tripstoday',tripstoday);
  return {...state,tripstoday}
}


