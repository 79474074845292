import {get,map,isEmpty,cloneDeep,forEach,last,reduce} from 'lodash';
import moment from 'moment';
import {OPTIX_SECURITY_EVENTS_REMOVE_IMAGE} from "../actions/ioptix";

const initialState = {
  imagelist: [],
  eventsGraph: [],
  kiosks: [],
  sensoreventurl: null,
  accelerometerEvents: [],
  accelerometerExports: [],
  isafeEvents: [],
  isafeExports: [],
  setting: {
    gforcerange:[
      {value:2,label:'2'},
      {value:3,label:'3'},
      {value:4,label:'4'},
      {value:5,label:'5'},
      {value:6,label:'6'},
      {value:7,label:'7'},
      {value:8,label:'8'},
      {value:9,label:'9'},
      {value:10,label:'10'},
      {value:11,label:'11'},
      {value:12,label:'12'},
      {value:13,label:'13'},
      {value:14,label:'14'},
      {value:15,label:'15'},
      {value:16,label:'16'}
    ],
  }
};

export default function ioptix(state = initialState, action) {
  switch (action.type) {
    case 'RECEIVE_SENSOREVENTS':
      return receiveSensorEvents(state, action);
    case 'OPTIX_SECURITY_EVENTS_REMOVE_IMAGE':
      return {...state,imagelist:[]};
    case 'RECEIVE_SENSOREVENTSGETKIOSKIMAGE':
      return receiveSensoreventsGetKioskImage(state, action);
    case 'RECEIVE_SENSOREVENTSGETKIOSKGRAPH':
      return receiveSensoreventsGetKioskGraph(state, action);
    case 'RECEIVE_SECURITYEVENTLIST':
      return receiveSensoreventsGetKioskGraph(state, action);
    case "RECEIVE_SENSOREVENTDOWNLOAD":
      return receiveSensoreventUrl(state, action);
    case "RECEIVE_SENSOREVENTSGETKIOSKURL":
      return receiveSensoreventUrl(state, action);
    case "RECEIVE_COMPANYINFOGETIOPTIX":
      return receiveCompanyInfoGetIoptix(state, action);
    case "RECEIVE_SENSOREVENTUPDATESETTING":
      return receiveSensorEventUpdateSetting(state, action);
    case 'HANDLE_LOGOUT':
      return cloneDeep(initialState);
    default:
      return state;
  }
}

function receiveSensorEventUpdateSetting(state,action) {
  let setting = state.setting;
  let label = action.data.currentvalue;
  let oldkey = action.data.key;
  let name = oldkey.includes('ISAFE') ? 'isafe' : 'accelerometer';
  let key = oldkey.split('_').at(-1).toLowerCase();
  let value = key === 'start' || key === 'end' ?
    moment.utc(label, 'HH:mm').format('hh:mm a')
    : Number(label);
  setting[name][key] = {...setting[name][key],label,value,oldkey};

  return {
    ...state,
    setting: {...setting}
  }
}

function receiveCompanyInfoGetIoptix(state,action) {
  let list = action.data.ioptix.list;
  let setting = action.data.ioptix.values.reduce((obj,v) => {
    let value = list.find((s)=> s.key === v.key);
    let name = v.key.includes('ISAFE') ? 'isafe' : 'accelerometer';
    if(!obj[name]) {
      obj[name] = {}
    }
    let key = v.key.split('_').at(-1).toLowerCase();
    let data =  value || v;
    if(key === 'start' || key ==='end') {
      data.value = moment.utc(data.value, 'HH:mm').format('hh:mm a')
    }
    let oldkey = value ? value.key : null;
    obj[name][key] = {...data,label:data.value,oldkey};
    // if(key === 'start' || key ==='end') {
    //   if(!obj.isafe) {
    //     obj.isafe = {}
    //   }
    //   if(!obj.isafe[key]) {
    //     obj.isafe[key] = {...data,label:data.value};
    //   }
    // }
    return obj
  },{});
  return {...state,setting:{...state.setting,...setting}}
}

function receiveSensoreventUrl(state,action) {
  return {...state,sensoreventurl:action.data.url}
}

function receiveSensoreventsGetKioskGraph(state,action) {
  let eventsGraph = [...action.data.events];
  return {
    ...state,
    eventsGraph
  }
}

function receiveSensoreventsGetKioskImage(state,action) {
  let imagelist = [...action.data.urls];
  return {
    ...state,
    imagelist
  }
}

function receiveSensorEvents(state,action) {
  let quiettime = action.data.quiettime;
  let endDate = quiettime.queitendtime;
  let startDate = quiettime.queitstarttime;
  let accelerometerEvents = [];
  let accelerometerExports = [];
  let isafeEvents = [];
  let isafeExports = [];

  for(let event of action.data.events) {
    let utctime = moment(event.when).utc().toISOString();
    // let when = moment(event.when);
    // let when = moment(event.when).format('Y-MM-DD h:mm:ss a');
    let reportstartdate = moment(event.when).format('Y-MM-DD h:mm:ss a');
    let reportenddate = moment(event.when).format('Y-MM-DD h:mm:ss a');
    event = {...event,startdate:utctime,enddate:utctime,reportstartdate,reportenddate};
    let dataFile = {
      eventname: event.eventname,
      kioskname: event.kioskname,
      magnitude: event.magnitude,
      threshold: event.threshold,
      username: event.username,
      when:utctime,
    };

    let time = moment(utctime).format('hh:mm');
    if (startDate <= time && time <= endDate) {
      isafeEvents = [...isafeEvents,event];
      isafeExports = [...isafeExports,dataFile]
    } else {
      accelerometerEvents = [...accelerometerEvents,event];
      accelerometerExports = [...accelerometerExports,dataFile];
    }
  }

  return {
    ...state,
    accelerometerEvents,
    accelerometerExports,
    isafeEvents,
    isafeExports
  }
}
