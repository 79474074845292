import socket from '../socket';

export const  LOG_ERROR = 'LOG_ERROR';
export const logError = (error,errorinfo,componentName) =>{
  return (dispatch,ownProps)=> {
    const containerid = ownProps().containers?.currentContainer;
    socket.send({
      command: 'errorlog',
      error,
      errorinfo,
      componentName,
      containerid
    });
    dispatch({type: 'LOG_ERROR',error,errorinfo,containerid,componentName});
  };
};
