import _ from 'lodash';

const initialState = {
  assets: {},
  currentAssetID:null,
  currentCode:null,
  lastreceived: {},
};

export default function bookin(state = initialState, action) {
  switch(action.type) {
    case 'RECEIVE_BOOKINAUTOMATED':
      return receiveBookIn(state, action);
    default:
      return state;
  }
}

function receiveBookIn(state, action) {
  // let assets= _.get(state,'assets');
  let containerid = _.get(action,'data.containerid');
  let data = _.get(action,'data.bookinlist');
  let assets = state.assets;
  assets[containerid]={...assets[containerid],...data};
  // let assets = {...state.assets,...data};
  let currentAssetID = _.last(_.map(data,'assetid'));
  let currentCode = _.last(_.map(data,'code'));
  let currentExisted = _.last(_.map(data,'existed'));
  // let assetid = _.get(assets,'assetid');
  // let assetid = _.toNumber(_.get(data,'assetid'));
  // let code = _.toNumber(_.get(data,'code'));
  // let existed = _.get(data,'existed');
  // let identifier = _.get(data,'identifier');
  // let stocknumber = _.get(data,'stocknumber');
  // let assetname = _.get(data,'assetname');
  // assets[assetid] ={assetname,assetid,code,existed,identifier,stocknumber};
  return {...state,assets,currentAssetID,currentCode,currentExisted,
            lastreceived: data };
}
