import _, {cloneDeep, get } from 'lodash';

const initialState = {
  data: {},
  scale: .8,
  h: 0,
  w: 0,
  // { server_connected, client_connected, repeater_alive }
  repeater_state: {},
};

export default function vnc(state = initialState, action) {
  switch (action.type) {
    case 'RECEIVE_REMOTEVIEWSCREENSTATE':
      return recieveGetCurrentPage(state, action);
    case 'RECEIVE_REPEATERADDID':
      return receiveLaunchVNC(state, action);
    case 'RECEIVE_REPEATERUPDATE':
      return updateVNCRepeaterState(state, action);
    case 'VNC_REPEATERSTART':
      // reset repeater state for button display
      return { ...state, repeater_state: {} };
    case 'HANDLE_LOGOUT':
      return cloneDeep(initialState);
    default:
      return state;
  }
}

function recieveGetCurrentPage(state, action) {
  const data = _.get(action,'data');
  return {
    ...state,
    data,
  }
}

function receiveLaunchVNC(state, action) {
  const url = get( action, 'data.url' ) + '?repeaterid=client:' +
    get( action, 'data.repeaterid' );
  window.open( url, url );
  return { ...state, repeater_state: {
    ...state.repeater_state,
    repeater_alive: true,
  } };
}

function updateVNCRepeaterState(state, action) {
  let next_state = { ...state, repeater_state: { ...state.repeater_state } };
  let server_connected = get( action, 'data.server_connected' );
  if( server_connected !== undefined )
    next_state.repeater_state.server_connected = server_connected;
  let client_connected = get( action, 'data.client_connected' );
  if( client_connected !== undefined )
    next_state.repeater_state.client_connected = client_connected;
  return next_state;
}
