import _ from 'lodash';

const initialState = {};

export default function uploadVideoAndImage(state = initialState, action) {
  switch (action.type) {
    case 'RECEIVE_TRANSACTIONVIDEOLIST':
      return receiveTransactionVideoList(state, action);
    case 'RECEIVE_IMAGE_UPLOAD_KEY':
      return receiveImageUploadKey(state, action);
    case 'HANDLE_LOGOUT':
      return _.cloneDeep(initialState);
    default:
      return state;
  }
}

function receiveTransactionVideoList (state,action) {
  let newState = _.assign({},state);
  let id = _.get(action,'data.transactionid');
  let videos = _.get(action,'data.videos');
  let downloadurl = _.get(action,'data.opaque.downloadurl');
  let kioskid = _.get(action,'data.opaque.kioskid');
  newState[id] = {videos,opaque:{downloadurl,kioskid}};

  return newState;
}

function receiveImageUploadKey (state,action) {
  let images = _.get(action,'data.images');
  return {...state,images};
}
