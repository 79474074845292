import {cloneDeep,reduce} from 'lodash';

const initialState = {
  users: {}
};

export default function userVerification(state = initialState, action) {
  switch (action.type) {
    case 'RECEIVE_USERVERIFIEDCODE':
      return recieveCode(state, action);
    case 'RECEIVE_USERAUTHSGET':
      return receiveAuthentications(state, action.data);
    case 'HANDLE_LOGOUT':
      return cloneDeep(initialState);
    default:
      return state;
  }
}

function recieveCode(state, action) {

  let confirmation = action.data;
  let {users} = state;
  if(!users[confirmation.userid]) {
    users[confirmation.userid] = {};
  }

  users[confirmation.userid][confirmation.type] = {...users[confirmation.userid][confirmation.type],...confirmation};
  return {
    ...state,
    users: {...cloneDeep(users)},
  }
}

function receiveAuthentications(state,data) {
  let {userinfo} = data;
  let users = reduce(userinfo,(obj,value)=> {
    if(!obj[value.userid]) {
      obj[value.userid] = {}
    }
    obj[value.userid][value.key] = value;
    return obj
  },{});
  return {
    ...state,
    users:{...users},
  }
}
