import React,{useEffect,useState,useRef} from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap';
import {valueNullToString} from "../languageTranslate";
import {multilanguage} from "redux-multilanguage";
import './LoginTwoFactorAuthentication.css';

const LoginTwoFactorAuthenticationModal = props => {
  const [isOpen,setIsOpen] = useState(props.isOpen);
  const [code2fa,setCode2fa] = useState('');
  const [invalidTimer,setInvalidTimer] = useState(null);
  const [invalidPasscode,setInvalidPasscode] =useState(false);
  const inputRef = useRef(null);

  useEffect(()=> {
    // when unmount remove interval
    return ()=>clearInvalidTimer();
  },[]);

  useEffect(()=> {
    setIsOpen(props.isOpen);
  },[props.isOpen]);

  useEffect(() => {
    if(inputRef.current) {
      inputRef.current.focus()
    }
  },[inputRef.current]);

  if(!props.isOpen) {
    return null
  }

  const lang = string => valueNullToString(props.strings,string);

  const onClickSubmit = e => {
    e.preventDefault();
    if(props.onSubmit) {
      props.onSubmit(code2fa);
        if(invalidTimer) {
          clearTimeout(invalidTimer);
        }
        let timer = setTimeout(() => {
          setInvalidPasscode(true)
        }, 2000);
        setInvalidTimer(timer);
      }
  };

  const clearInvalidTimer = ()=> {
    if(invalidTimer) {
      clearTimeout(invalidTimer);
      setInvalidTimer(null);
    }
  };

  const onCancel =()=> {
    if(props.onCancel) {
      props.onCancel()
    }
    clearInvalidTimer();
    setIsOpen(false)
  };

  const onChangeValue = e => {
    let value = e.target.value;
    setCode2fa(value)
  };

  return (
    <Modal isOpen={isOpen} >
      <ModalHeader>{lang('Two Factor Authentications')}</ModalHeader>
      <ModalBody>
        <form onSubmit={onClickSubmit}>
          <input
            className="authenticatoinInput"
            onChange={onChangeValue}
            value={code2fa}
            ref={inputRef}
          />
        </form>
        {
          invalidPasscode ?
            <p style={{color:'red'}}>{lang('Invalid passcode')}</p>
            : null
        }
      </ModalBody>
      <ModalFooter>
        <Button
          disabled={code2fa.length !== 6}
          onClick={onClickSubmit}>
          {lang('Ok')}
        </Button>{' '}
        <Button
          color="primary"
          onClick={onCancel}
        >{lang('Close')}</Button>
      </ModalFooter>
    </Modal>
  )
};

export default multilanguage(LoginTwoFactorAuthenticationModal);
