import _ from 'lodash';
import { REPORT_TYPES } from '../actions/dashboard';

const initialState = {
  dashBoardStats: [],
  transactions: [],
  totalKeysOut: 0,
  keyActivity: [],
  transactionsStatus: 'unfeteched',
  keyActivityStatus: 'unfeteched',
  dashBoardStatsStatus: 'unfetched'
};

const TRANSACTION_TYPES = {
  TAKE_ASSET: 1,
  RETURN_ASSET: 2,
  ASSIGN: 3,
  UNASSIGN: 4,
  SERVICE_ASSIGN: 5,
  SERVICE_UNASSIGN: 6
}

const TRANSACTION_STATUSES = {
  OPEN: 1,
  COMPLETE: 2,
  CANCELED: 3,
  EXPIRED: 4
}

export default function dashboard(state = initialState, { type, data }) {
  switch (type) {
    case 'FETCH_DASHBOARD_REPORT':
      return fetchDashboardReport(state, data);
    case 'RECEIVE_DASHBOARDGETSTATS':
      return receiveDashBoardStats(state,data);
    case 'RECEIVE_REPORT':
      return receiveReport(state, data);
    case 'RECEIVE_SYNCTRANSACTIONS':
      return receiveTransaction(state, data);
    case 'HANDLE_LOGOUT':
      return _.cloneDeep(initialState);
    default: {
      return state;
    }
  }
}

function receiveDashBoardStats(state, data) {
  let dashBoardStats = _.get(data,'item');
  return {
    ...state,
    dashBoardStats,
    dashBoardStatsStatus: 'fetched'
  };
}

function fetchDashboardReport(state, data) {
  if (data.id === REPORT_TYPES.DASHBOARD_KEYS_CHECKED_OUT) {
    return {
      ...state,
      transactionsStatus: 'unfeteched'
    }
  } else if (data.id === REPORT_TYPES.DASHBOARD_EMPLOYEE_KEY_ACTIVITY) {
    return {
      ...state,
      keyActivityStatus: 'unfeteched'
    }
  } else {
    return state;
  }
}

function receiveTransaction(state, data) {
  let transaction = _.get(data, `transactions[0]`)
  let isTake   = parseInt(transaction.transactionType, 10) === TRANSACTION_TYPES.TAKE_ASSET,
      isReturn = parseInt(transaction.transactionType, 10) === TRANSACTION_TYPES.RETURN_ASSET,
      isCompleted = parseInt(transaction.transactionStatus, 10) === TRANSACTION_STATUSES.COMPLETE;

  if ((isReturn || isTake) && isCompleted) {
    let newTransaction = {
      "Asset": data.asset.assetName,
      "Designation": "Normal",
      "Kiosk": transaction.kioskID,
      "Stock Number": data.asset["Stock Number"],
      "Tag ID": transaction.tagID.padStart(16, '0'),
      "User": `${data.user.lname}, ${data.user.fname}`,
      "VIN": data.asset.Identifier,
      "assetID": data.asset.assetID,
      "userID": data.user.userid,
      "checkoutDate": transaction.closedDT
    }

    let newTransactions = [];
    let totalKeysOut = state.totalKeysOut;
    if (isTake) {
      newTransactions = [newTransaction];
      totalKeysOut += 1;
    } else if (isReturn) {
      totalKeysOut -= 1;
    }

    let transactions =  _.concat(newTransactions, _.reject(state.transactions, (transaction) => {
      return transaction["Tag ID"] === newTransaction["Tag ID"] || transaction.userID === newTransaction.userID;
    }));

    return {
      ...state,
      totalKeysOut,
      transactions: transactions
    }
  } else {
    return state;
  }
}

function receiveReport(state, data) {
  if (data.id === REPORT_TYPES.DASHBOARD_KEYS_CHECKED_OUT) {
    let transactions = _.zipWith(data.data, data.metadata, (data, metadata) => {
      return _.assign({}, data, metadata);
    });
    return {
      ...state,
      transactions,
      totalKeysOut: data.total ? parseInt(data.total, 10) : '',
      transactionsStatus: 'fetched'
    }
  } else if (data.id === REPORT_TYPES.DASHBOARD_EMPLOYEE_KEY_ACTIVITY) {
    return {
      ...state,
      keyActivity: data.data,
      keyActivityStatus: 'fetched'
    }
  } else {
    return state;
  }
}
