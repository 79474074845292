import {serverHost} from '../constants';

export const VERSION_GET = 'VERSION_GET';
export const getVersion = () => {
  return async (dispatch) => {
    try {
      let text;
      if(window.location.hostname === 'localhost') {
        text = await (await fetch(`http://localhost:3001/version.txt`)).text();
      } else {
        text = await (await fetch(`https://${serverHost()}/version.txt`)).text();
      }
      dispatch({ type: VERSION_GET, text });
    } catch(e) {
      console.log('version fetching error',e)
    }
  }
};
