import socket from './../socket';

export const RECEIVE_GPS_LOCATIONS = 'RECEIVE_LOCATIONPULL';
export const RECEIVE_GPS_LOCATIONS_BY_USER = 'RECEIVE_LOCATIONHISTORY';

export const FETCH_GPS_LOCATION_HISTORY = 'FETCH_GPS_LOCATION_HISTORY';
export const fetchGPSLocationsByUserID = (userid) => {
  return (dispatch, getState) => {
    let payload = {
      command: "locationhistory",
      userid
    };
    socket.send(payload);
    dispatch({ type: FETCH_GPS_LOCATION_HISTORY });
  }
};

export const RECIEVE_MOCK_GPS_TEST_DRIVES = 'RECIEVE_MOCK_GPS_TEST_DRIVES';
export const receiveGpsTestdrives = (param) => {
  return (dispatch, getState) => {
    dispatch({ type: RECIEVE_MOCK_GPS_TEST_DRIVES,data:param });
  }
};

export const GPS_GET_ASSET_LIST = 'GPS_GET_ASSET_LIST';
export const gpsGetAssetList = (param) => {
  return (dispatch, getState) => {
    let payload = {
      command: 'gpsgetassetlist',
      ...param
    };
    socket.send(payload);
    dispatch({type:GPS_GET_ASSET_LIST,...param});
  }
};


export const GPS_GET_TRIP_HISTORY = 'GPS_GET_TRIP_HISTORY';
export const gpsGetTripHistory = (param) => {
  return (dispatch, getState) => {
    let payload = {
      command: 'gpsgettriphistory',
      ...param
    };
    socket.send(payload);
    dispatch({type:GPS_GET_TRIP_HISTORY});
  }
};

export const GPS_MOCK_ASSET_LIST = 'GPS_MOCK_ASSET_LIST';
export const gpsMockAssetList = (param) => {
  return (dispatch, getState) => {
    let payload = {
      command: 'gpstrackingmockhistory',
      ...param
    };
    socket.send(payload);
    dispatch({type:GPS_MOCK_ASSET_LIST});
  }
};

export const GET_HISTORY_LOCATION = 'GET_HISTORY_LOCATION';
export const getHistoryLocation = trackingid => {
  return dispatch => {
    dispatch({type:GET_HISTORY_LOCATION,trackingid});
  }
};

export const GPS_ON = 'GPS_ON';
export const gpsOn = containerid => {
  return dispatch => {
    let payload = {
      command: 'gpson',
      containerid
    };
    socket.send(payload);
    dispatch({type:GPS_ON});
  }
};

export const GPS_OFF = 'GPS_OFF';
export const gpsOff = containerid => {
  return dispatch => {
    let payload = {
      command: 'gpsoff',
      containerid
    };
    socket.send(payload);
    dispatch({type:GPS_OFF});
  }
};

export const GPS_TRIP_TODAY = 'GPS_TRIP_TODAY';
export const gpsGetTodayTrip = containerid => {
  return dispatch => {
    let payload = {
      command: 'gpstripstoday',
      containerid
    };
    socket.send(payload);
    dispatch({type:GPS_TRIP_TODAY});
  }
};
