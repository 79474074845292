import {cloneDeep, split, orderBy} from 'lodash';
const initialState = {
  filenames: [],
  hardwareinfo: {},
  loadedFiles: {}
};

export default function watchdog(state = initialState, action) {
  switch (action.type) {
    case 'RECEIVE_WATCHDOGLOGFILELIST':
      return recieveWatchDogLogFileList(state, action);
    // case 'RECEIVE_LOGFILE':
    //   return recieveLogFile(state, action);
    case 'RECEIVE_WATCHDOGHARDWARELIST':
      return recieveWatchDogHardwareList(state, action);
    case 'WATCHDOGLOGFILEGET':
      let {loadedFiles} = state;
      let {log_file_path} = action;
      if (loadedFiles[log_file_path]) {
        loadedFiles[log_file_path].url = '';
        loadedFiles[log_file_path].loading = true;
      } else {
        loadedFiles[log_file_path] = {url:'',loading:true}
      }
      setTimeout(()=> {
        let {loadedFiles} = state;
        if(loadedFiles[log_file_path]) {
          loadedFiles[log_file_path].loading = false;
          return {...state,loadedFiles:{...loadedFiles}};
        }
      },30000);
      return {...state,loadedFiles:{...loadedFiles}};
    case 'RECEIVE_WATCHDOGLOGFILEGET':
      return recieveWatchDogLogFileGet(state, action);
    case 'HANDLE_LOGOUT':
      return cloneDeep(initialState);
    default:
      return state;
  }
}

function recieveWatchDogLogFileList(state, action) {
  const { filenames } = action.data;
  return {
    ...state,
    filenames: orderBy(filenames, name => name, 'asc'),
  }
}

function recieveWatchDogLogFileGet(state, action) {
  const { get_in_url, log_file_path } = action.data;
  const {loadedFiles} = state;
  return {
    ...state,
    loadedFiles: {...loadedFiles,[log_file_path]:{url:get_in_url,loading:false}},
  }
}

// function recieveLogFile(state, action) {
//   const {put_in_url:url} = action.data;
//   return {
//     ...state,
//     url,
//   }
// }

function recieveWatchDogHardwareList(state, action) {
  const {info,kioskid} = action.data;
  const statehardwareinfo = state.hardwareinfo;
  const hardwareinfo = {...statehardwareinfo,[kioskid]:split(info,/\r\n|\r|\n/)};
  return {
    ...state,
    hardwareinfo,
  }
}
