import {cloneDeep, get, unset} from 'lodash';
import buildTable from '../Customer/customerHelperFunctions';

const initialState = {
  info: {},
  detail: {},
  customers: [],
  tableheader: [],
  tablecolumn: [],
  document: {},
  unverifiesDocuments: [],
  mergeMessage: false,
  mergecurrentcustomerid: null,
  mergecustomerexistid: null,
  documentExistMessage: false
};

export default function customer(state = initialState, action) {
  switch (action.type) {
    case 'RECEIVE_CUSTOMERINFOLISTGET':
      return customerToTable(state, action);
    case 'RECEIVE_CUSTOMERINFOSEARCH':
      return customerToTable(state, action);
    case 'RECEIVE_CUSTOMEREVENTLISTGET':
      return customerToTable(state, action);
    case 'RECEIVE_CUSTOMERUNVERIFYLISTGET':
      return customerToTable(state, action);
    case 'RECEIVE_CUSTOMERINFOMATIONGET':
      return recieveCustomerInfoGet(state, action);
    case 'RECEIVE_CUSTOMERINFODETAILGET':
      return recieveCustomerDetailGet(state, action);
    case 'RECEIVE_CUSTOMERINFOMODIFY':
      return recieveCustomerInfoModify(state, action);
    case 'RECEIVE_CUSTOMERDOCUMENTTEMPLATEGET':
      return recieveCustomerDocumentTemplateGet(state, action);
    case 'RECEIVE_CUSTOMERDOCUMENTVERIFIEDMODIFY':
      return receiveCustomerDocumentVerifyModify(state, action);
    case 'RECEIVE_CUSTOMERUNVERIFIEDDOCUMENTSGET':
      return receiveCustomerDocumentUnverifiedGet(state, action);
    case 'RECEIVE_CUSTOMERINFOUNVERIFIEDTOVERIFYMODIFY':
      return recieveCustomerInfoGet(state, action);
    case 'RECEIVE_CUSTOMERINFOSETTODOCUMENTMODIFY':
      return recieveCustomerInfoGet(state, action);
    case 'RECEIVE_CUSTOMERMERGEMESSAGE':
      return receiveCustomerMergeMessage(state, action);
    case 'RECEIVE_CUSTOMERMERGE':
      return recieveCustomerMerge(state, action);
    case 'RECEIVE_CUSTOMERDOCUMENTMERGE':
      return receiveCustomerDocumentMerge(state, action);
    case 'CUSTOMERDOCUMENTMERGECANCEL':
      return {...state, mergecurrentcustomerid: null, mergecustomerexistid: null};
    case 'RECEIVE_CUSTOMEREXISTDOCUMENTMESSAGE':
      return receiveCustomerExistDocumentMessage(state, action);
    case 'RECEIVE_CUSTOMERDOCUMENTDELETE':
      return receiveCustomerDocumentDelete(state, action);
    case 'HANDLE_LOGOUT':
      return cloneDeep(initialState);
    default:
      return state;
  }
}

function customerToTable(state, action) {
  const { list } = get(action,'data');
  const { header, table } = buildTable(list);
  return { ...state, tableheader: header,tablecolumn: table }
}

function recieveCustomerInfoGet(state, action) {
  const { info } = get(action,'data');
  return { ...state,detail:{...state.detail,info}, info }
}

function recieveCustomerDetailGet(state, action) {
  const { detail } = get(action,'data');
  const { info } = detail;
  unset(detail,'info');
  const events = buildTable(detail.events);
  // return { ...state, detail:{...detail,events,info}}
  return { ...state, detail:{...detail,events,info} ,info}
}

function recieveCustomerInfoModify(state, action) {
  const {info} = get(action,'data');
  const { detail } = state;
  return { ...state, detail:{...detail,info} };
}

function recieveCustomerDocumentTemplateGet(state, action) {
  const { document, customers } = get(action,'data');
  return { ...state, document, customers };
}

function receiveCustomerDocumentVerifyModify(state, action) {
  const { document } = state;
  const { contents, verified } = get(action,'data.document');
  return {...state,document: {...document,contents,verified}}
}

function receiveCustomerDocumentUnverifiedGet(state, aciton) {
  const { unverifiesDocuments } = get(aciton,'data');
  return { ...state, unverifiesDocuments };
}

function receiveCustomerMergeMessage(state, aciton) {
  const { mergecurrentcustomerid, mergecustomerexistid } = get(aciton,'data');
  return {...state, mergeMessage: !state.mergeMessage, mergecurrentcustomerid, mergecustomerexistid};
}

function receiveCustomerDocumentMerge(state, aciton) {
  const { document, customerid } = get(aciton,'data');
  const documentMerge = { ...state.document, customerid, ...document };
  return { ...state, document: documentMerge, mergecurrentcustomerid: null, mergecustomerexistid: null };
}

function receiveCustomerDocumentDelete(state, aciton) {
  const { documentid } = get(aciton,'data');
  const document = documentid === state.document.documentid ?
    { isdeleted: true } : state.document;
  return { ...state, document };
}

function receiveCustomerExistDocumentMessage(state, action) {
  return {...state,documentExistMessage:!state.documentExistMessage}
}

function recieveCustomerMerge(state, action) {
  const {customerid} = action.data;
  return {...state,detail:{...state.detail,customerid}}
}
