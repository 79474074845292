import {cloneDeep, get } from 'lodash';

const initialState = {
  version: '1.28.0.88'
};

export default function version(state = initialState, action) {
  switch (action.type) {
    case 'VERSION_GET':
      return versionGet(state, action);
    case 'HANDLE_LOGOUT':
      return cloneDeep(initialState);
    default:
      return state;
  }
}

function versionGet(state, action) {
  const version = get(action,'text');
  return {
    ...state,
    version,
  }
}
