import socket from './../socket';
import {browserHistory} from 'react-router';

export const HANDLE_REGISTER_SESSION_ATTEMPT = 'HANDLE_REGISTER_SESSION_ATTEMPT';
export const handleRegisterSessionAttempt = (username, password) => {
  return {
    type: HANDLE_REGISTER_SESSION_ATTEMPT,
    username,
    password
  }
};
export const SWITCH_SERVER = 'SWITCH_SERVER';
export const switchServer = () => {
  return (dispatch) => {
    dispatch({type:SWITCH_SERVER});
    dispatch({type: HANDLE_LOGOUT});
    window.socket.reconnect(true)
  }
};

export const REGISTER_SESSION = 'REGISTER_SESSION';
export const registerSession = (params) => {
  if (!params.PHPSESSID)
    params.PHPSESSID = params.token || '';
  return (dispatch, getState) => {
    let state = getState();
    /// Handling connection status checking in send.
    if (state.connectionStatus==='opened') {
      socket.send({ command: 'register', PHPSESSID: '', ...params, type: 'web' });
      storeCredentials(params.username, params.PHPSESSID);
      dispatch(handleRegisterSessionAttempt(params.username, params.password))
    } else {
      dispatch(invalidSession())
    }
  }
};

export const PASSWORD_RESET = 'PASSWORD_RESET';
export const passwordReset = (username) => {
  return (dispatch, getState) => {
    socket.send({ command: 'register', passwordreset: true, username, type: 'web' });
  }
};

export const USER_INFO_GET_CONTACT = 'USER_INFO_GET_CONTACT';
export const userInfoGetContact = (param) => {
  return (dispatch, getState) => {
    socket.send({ command: 'userinfogetcontact',...param });
    // socket.send({ command: 'ping'});
    dispatch({
      type: USER_INFO_GET_CONTACT
    });
  }
};

export const HANDLE_REGISTER_SESSION_SUCCESS = 'HANDLE_REGISTER_SESSION_SUCCESS';
export const handleRegisterSessionSuccess = (phpsessid) => {
  return {
    type: HANDLE_REGISTER_SESSION_SUCCESS,
    phpsessid
  }
};

export const CLEAR_ALTERNATE_SERVER = 'CLEAR_ALTERNATE_SERVER';
export const clearServer = () => {
  return {
    type: CLEAR_ALTERNATE_SERVER
  }
};

export const CANCEL_TWO_FACTOR_AUTHENTICATION = 'CANCEL_TWO_FACTOR_AUTHENTICATION';
export const cancelTwoFactorAuthentication = () => {
  return dispatch => {
      dispatch({type:CANCEL_TWO_FACTOR_AUTHENTICATION})
  }
};

export const storeSession = (phpsessid) => {
  return (dispatch, getState) => {
    let state = getState();
    storeCredentials(state.currentUser.username, phpsessid || state.currentUser.sessionId );
    dispatch(handleRegisterSessionSuccess(phpsessid))
  }
};

export const HANDLE_LOGOUT = 'HANDLE_LOGOUT';

export const logout = () => {
  return dispatch => {
    clearCredentials();
    browserHistory.push('/');
    socket.send({
      command: 'logout'
    });
    socket.reconnect( true );
    dispatch({
      type: HANDLE_LOGOUT
    });
  }
};

export const HANDLE_SWITCHLOGINTYPE = 'HANDLE_SWITCHLOGINTYPE';
export const switchLoginType = (loginType) => {
  return dispatch => {
    dispatch({type: HANDLE_SWITCHLOGINTYPE, loginType })
  };
};

export const HANDLE_RETRYIDENTITYPROVIDERLOGIN = 'HANDLE_RETRYIDENTITYPROVIDERLOGIN';
export const retryIdentityProviderLogin = () => {
  return ( dispatch, getState ) => {
    const state = getState();
    socket.send({ command: 'newoauth', provider: state.currentUser.activeLoginType, type: 'user' });
    dispatch({type: HANDLE_RETRYIDENTITYPROVIDERLOGIN })
  };
};

export const invalidSession = () => {
  clearCredentials();
  return {
    type: 'HANDLE_REGISTER_SESSION_FAILURE'
  }
};

function clearCredentials() {
  localStorage.removeItem('username');
  localStorage.removeItem('password');
  localStorage.removeItem('alternate_server');
  localStorage.removeItem('currentContainer');
  localStorage.removeItem('token');
}

function storeCredentials(username, token) {
  localStorage.setItem('username', username);
  localStorage.setItem('token', token);
}
