import {get,cloneDeep,map,findIndex,isUndefined,filter} from 'lodash';

const initialState = {
  status: 'unfetched',
  stalls: [],
  stallAssets:{},
  faketoreal:[],
  editStallid:null,
  submitEditcheck:false,
};

export default function locationstall(state = initialState, action) {
  switch(action.type) {
    // case 'RECEIVE_GPSSTALLGET':
    //   return recieveGpsStallGet(state, action);
    case 'RECEIVE_GPSSTALLSSET':
      return recieveGpsStallSet(state, action);
    case 'RECEIVE_GPSSTALLSMODIFY':
      return receiveGpsStallModify(state, action);
    case 'EDIT_LOCATION_STALL':
      return receiveEditLocationStall(state, action);
    case "RECEIVE_GPSSTALLSDELETE":
      return receiveGpsStallsDelete(state, action);
    case 'RECEIVE_GPSSTALLSBYCONTAINER':
      return receiveGpsStallsByContainer(state, action);
    case 'RECEIVE_GETSTALLASSETS':
      return receiveGetStallAssets(state, action);
    case 'HANDLE_LOGOUT':
      return cloneDeep(initialState);
    default:
      return state;
  }
};

// function recieveGpsStallGet(state, action) {
//   // const data = get(action,'data');
//   return{state}
// }

function receiveGetStallAssets(state, action) {
  const stallAssets = get(state,'stallAssets');
  const assets = get(action,'data.assets');
  const geofenceid = get(action,'data.geofenceid');
  stallAssets[geofenceid] = assets;
  return{...state,stallAssets}
}

function recieveGpsStallSet(state, action) {
  let data = get(action,'data');
  let stalls = get(state,'stalls');
  let faketoreal = get(state,'faketoreal');
  let bounds = map(get(data,'bounds'),bound=>({lat:bound.latitude,lng:bound.longitude}));
  let center = {lat:get(data,'center.latitude'),lng:get(data,'center.longitude')};
  stalls = [...stalls,{...data,bounds,center}];
  faketoreal =[...faketoreal,{...data,bounds,center}];

  return {...state,stalls,faketoreal};
}

function receiveGpsStallModify(state, action) {
  let stalls = get(state,'stalls');
  let data = get(action,'data');
  let stallid = get(data,'id');
  let index = findIndex(stalls,stall=> stall.id === stallid);
  stalls[index] = data;

  return{...state,stalls,editStallid:stallid}
}

function receiveEditLocationStall(state,action) {
  let stalls = get(state,'stalls');
  let editStallid = get(action,'stallid');
  let bounds = map(action.bounds,bound=>({lat:get(bound,'latitude'),lng:get(bound,'longitude')}));
  let center = {lat:get(action,'center.latitude'),lng:get(action,'center.longitude')};
  let index = findIndex(stalls,stall=> stall.id === editStallid);
  let submitEditcheck = get(action,'submitEditcheck');
  stalls[index] = {...action,id:editStallid,bounds,center};
  if(isUndefined(submitEditcheck)) {
    return {...state,editStallid};
  } else {
    return {...state,editStallid,submitEditcheck};

  }
}

function receiveGpsStallsDelete(state, action) {
  let stalls = get(state,'stalls');
  let stallid = get(action,'data.stallid');
  let removeStalls = filter(stalls,stall=>stall.id !== stallid);
  return{...state,stalls:removeStalls}
}

function receiveGpsStallsByContainer(state, action) {
  let stalls = get(action,'data.stalls');
  return{...state,stalls,status:'fetched'}
}
