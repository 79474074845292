import socket from './../socket';
import _ from 'lodash';

export const EMPLOYEE_KEY_ACTIVITY_REPORT = 'EMPLOYEE_KEY_ACTIVITY_REPORT';
export const KEYS_EXTENDED_CHECKED_OUT = 'KEYS_EXTENDED_CHECKED_OUT';
export const KEYS_CHECKED_OUT = 'KEYS_CHECKED_OUT';
export const KEY_LOCATIONS = 'KEY_LOCATIONS';
export const DRAWER_LAYOUT = 'DRAWER_LAYOUT';
export const USERS_CLOCKED_IN = 'USERS_CLOCKED_IN';
export const TIMESHEETS = 'TIMESHEETS';

export const REPORT_TYPES = {
  EMPLOYEE_KEY_ACTIVITY_REPORT: '1',
  KEYS_EXTENDED_CHECKED_OUT: '8',
  KEYS_CHECKED_OUT: '2',
  KEY_LOCATIONS: '3',
  DRAWER_LAYOUT: '4',
  EMPIRE_VIEW: '6',
  TIMESHEETS: '7',
  KIOSKVERSIONS: 'kioskversions',
  ASSET_NOT_ASSIGNED: '12',
  TAG_NOT_HOME: '13',
};

export const REPORT_NAMES = {
  [REPORT_TYPES.EMPLOYEE_KEY_ACTIVITY_REPORT]: "Employee Key Activity",
  [REPORT_TYPES.KEYS_EXTENDED_CHECKED_OUT]: "Keys Out Extended",
  // [REPORT_TYPES.KEYS_EXTENDED_CHECKED_OUT]: "Keys Extended Checked Out",
  [REPORT_TYPES.KEYS_CHECKED_OUT]: "Keys Checked Out",
  [REPORT_TYPES.KEY_LOCATIONS]: "Key Locations",
  [REPORT_TYPES.DRAWER_LAYOUT]: "Drawer Layout",
  [REPORT_TYPES.EMPIRE_VIEW]: "empireview",
  [REPORT_TYPES.TIMESHEETS]: "Timesheet",
  [REPORT_TYPES.KIOSKVERSIONS]: "Kiosk Versions",
};

export const FETCH_REPORT = 'FETCH_REPORT';
export const fetchReport = (id, parameters) => {
  return {
    type: FETCH_REPORT,
    id,
    parameters
  }
};


export const SET_REPORT_DATE_RANGE = 'SET_REPORT_DATE_RANGE';
export const setReportDateRange = (startDate,endDate,report) => {
  return (dispatch) => {

    dispatch({type:SET_REPORT_DATE_RANGE,data:{startDate,endDate,report}});
  }
};

export const fetchEmployeeKeyActivityReport = (parameters = {}) => {
  return (dispatch, getState) => {

    let id = REPORT_TYPES.EMPLOYEE_KEY_ACTIVITY_REPORT;
    let currentContainer = _.get(getState(), 'containers.currentContainer')
    if (currentContainer) {
      parameters.containerid = currentContainer;
    }
    let payload = {
      command: "report",
      id,
      name: REPORT_NAMES[id],
      parameters: parameters,
    };
    socket.send(payload);
    // dispatch(fetchReport(REPORT_TYPES.EMPLOYEE_KEY_ACTIVITY_REPORT, parameters));
  }
};

export const FETCH_KEYS_EXTENDED_CHECKED_OUT_REPORT = 'FETCH_KEYS_EXTENDED_CHECKED_OUT_REPORT';

export const fetchKeysExtendedCheckedOutReport = (parameters = {}) => {
  return (dispatch, getState) => {
    let id = REPORT_TYPES.KEYS_EXTENDED_CHECKED_OUT;
    let currentContainer = _.get(getState(), 'containers.currentContainer');
    if (currentContainer) {
      parameters.containerid = currentContainer;
    }
    let payload = {
      command: "report",
      id,
      name: REPORT_NAMES[id],
      parameters: parameters,
    };
    socket.send(payload);
    dispatch(fetchReport(REPORT_TYPES.KEYS_EXTENDED_CHECKED_OUT, parameters));
  }
};


export const FETCH_KEYS_CHECKED_OUT_REPORT = 'FETCH_KEYS_CHECKED_OUT_REPORT';

export const fetchKeysCheckedOutReport = (parameters = {}) => {
  return (dispatch, getState) => {
    let id = REPORT_TYPES.KEYS_CHECKED_OUT;
    let currentContainer = _.get(getState(), 'containers.currentContainer')
    if (currentContainer) {
      parameters.containerid = currentContainer;
    }
    let payload = {
      command: "report",
      id,
      name: REPORT_NAMES[id],
      parameters: parameters,
    };
    socket.send(payload);
    dispatch(fetchReport(REPORT_TYPES.KEYS_CHECKED_OUT, parameters));
  }
};

export const TAGMARKASLOST = 'TAGMARKASLOST';

export const tagMarkAsLost = (parameters ) => {
  return (dispatch, getState) => {
    let payload = {
      command: "tagmarkaslost",
      ...parameters,
    };
    socket.send(payload);
    dispatch({type:TAGMARKASLOST, ...parameters});
  }
};


export const FETCH_EMPIRE_VIEW_REPORT = 'FETCH_EMPIRE_VIEW_REPORT';

export const fetchEmpireViewReport = (parameters = {}) => {
  return (dispatch, getState) => {
    let id = REPORT_TYPES.EMPIRE_VIEW;
    let currentContainer = _.get(getState(), 'containers.currentContainer')
    if (currentContainer) {
      parameters.containerid = currentContainer;
    }
    let payload = {
      command: "report",
      id,
      name: REPORT_NAMES[id],
      parameters: parameters
    };
    socket.send(payload);
    dispatch(fetchReport(REPORT_TYPES.KEYS_CHECKED_OUT, parameters));
  }
};


export const FETCH_KEY_LOCATIONS_REPORT = 'FETCH_KEY_LOCATIONS_REPORT';

export const fetchKeyLocationsReport = (parameters = {}) => {
  return (dispatch, getState) => {
    let id = REPORT_TYPES.KEY_LOCATIONS;

    let currentContainer = _.get(getState(), 'containers.currentContainer')
    if (currentContainer) {
      parameters.containerid = currentContainer;
    }
    let payload = {
      command: "report",
      id,
      name: REPORT_NAMES[id],
      parameters: parameters
    };
    socket.send(payload);
    dispatch(fetchReport(REPORT_TYPES.KEY_LOCATIONS, parameters));
  }
};

export const FETCH_DRAWER_LAYOUT_REPORT = 'FETCH_DRAWER_LAYOUT_REPORT';

export const fetchDrawerLayoutReport = (parameters = {}) => {
  return (dispatch, getState) => {
    let id = REPORT_TYPES.DRAWER_LAYOUT;

    let currentContainer = _.get(getState(), 'containers.currentContainer')
    let payload = {
      command: "report",
      id,
      name: REPORT_NAMES[id],
      parameters
    };
    if (currentContainer) {
      payload.containerid = currentContainer;
    }
    socket.send(payload);
    dispatch(fetchReport(REPORT_TYPES.DRAWER_LAYOUT, parameters));
  }
};

export const SOLDKEYLIST = 'SOLDKEYLIST';
export const soldkeylist = (containerid) => {
  return (dispatch, getState) => {
    let payload = {
      command: "soldkeylist",
      containerid
    };
    socket.send(payload);
    dispatch({type:SOLDKEYLIST});
  }
};

export const LOSTTAGS = 'LOSTTAGS';
export const getLostTags = (containerid) => {
  return (dispatch, getState) => {
    let payload = {
      command: "lostkeylist",
      containerid
    };
    socket.send(payload);
    dispatch({type:LOSTTAGS});
  }
};

export const LOAD_MORE_REPORT_DATA = 'LOAD_MORE_REPORT_DATA';
export const loadMoreReportData = (reportId, parameters) => {
  return (dispatch, getState) => {
    let currentContainer = _.get(getState(), 'containers.currentContainer')
    if (currentContainer) {
      parameters.containerid = currentContainer;
    }
    let payload = {
      command: "report",
      id: reportId,
      parameters: parameters,
      name: REPORT_NAMES[reportId],
    };
    socket.send(payload);
    dispatch(fetchReport(reportId, parameters));
  }
};


export const FETCH_TIMESHEET = 'FETCH_TIMESHEET';
export const fetchTimeSheet = (parameters = {}) => {
  return (dispatch, getState) => {
    let id = REPORT_TYPES.TIMESHEETS;

    let currentContainer = _.get(getState(), 'containers.currentContainer')
    let payload = {
      command: "report",
      id,
      name: REPORT_NAMES[id],
      parameters
    };
    if (currentContainer) {
      payload.containerid = currentContainer;
    }
    socket.send(payload);
    dispatch(fetchReport(REPORT_TYPES.TIMESHEETS, parameters));
  }
};

export const FETCH_KIOSKVERSIONS = 'FETCH_KIOSKVERSIONS';
export const fetchKioskVersions = (parameters = {}) => {
  return (dispatch, getState) => {
    socket.send({
      command: "kioskversions",
      id: REPORT_TYPES.KIOSKVERSIONS,
      name: 'kioskversions',
    });
    dispatch(fetchReport(REPORT_TYPES.KIOSKVERSIONS));
  }
};

export const FETCH_ASSET_NOT_ASSIGNED = 'FETCH_ASSET_NOT_ASSIGNED';
export const fetchAssetsNotAssigned = containerid => {
  return (dispatch, getState) => {
    socket.send({
      command: "assetsnotassigned",
      containerid
    });
    dispatch(fetchReport(REPORT_TYPES.ASSET_NOT_ASSIGNED));
  }
};

export const FETCH_KEYS_NOT_HOME = 'FETCH_KEYS_NOT_HOME';
export const fetchKeysNotHome = containerid => {
  return (dispatch) => {
    socket.send({ command: 'reportkeysnothome', containerid });
    dispatch({type:FETCH_KEYS_NOT_HOME});
  };
};

export const REPORT_IN_STOCK_INVENTORY = 'REPORT_IN_STOCK_INVENTORY';
export const getInStockInventoryReport = containerid => {
  return dispatch => {
    socket.send({ command: 'instockinventory', containerid });
    dispatch({type:REPORT_IN_STOCK_INVENTORY,containerid});
  };
};


export const REPORT_GET_NOT_SOLD_NO_TAG_ASSTES = 'REPORT_GET_NOT_SOLD_NO_TAG_ASSTES';
export const getAssetsWithNoTagsNotSold = containerid => {
  return dispatch => {
    socket.send({ command: 'notsoldnotagasset', containerid });
    dispatch({type:REPORT_GET_NOT_SOLD_NO_TAG_ASSTES,containerid});
  };
};

export const REPORT_GET_FTP_INTERFACE = 'REPORT_GET_FTP_INTERFACE';
export const getFtpInterface = containerid => {
  return dispatch => {
    socket.send({ command: 'ftpgetinterface', containerid });
    dispatch({type:REPORT_GET_FTP_INTERFACE,containerid});
  };
};
