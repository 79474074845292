import {cloneDeep,remove,forEach,findIndex,map,get,isEmpty,reverse,dropRight,orderBy} from 'lodash';
import moment from 'moment-timezone';

const initialState = {
  transactions: {pending:[],live:[],returnTransaction:[],missed:[],closed:[]},
  addTransaction:[],
  kiosks:[],
  kiosksOpenSlots:[],
  startdate: null,
  enddate: null,
  currentContainerid: null,
  kiosksDropOff:[],
  transactionsPendingConfirm: [],
  userExist: null,
  viewClosedDate: moment().toISOString(),
};

export default function solo(state = initialState, action) {
  switch (action.type) {
    case 'RECEIVE_SOLOGETTRANSACTIONS':
      return soloGetTransactions(state,action);
    case 'RECEIVE_SOLOADDTRANSACTION':
      return soloAddTransaction(state,action);
    case 'RECEIVE_SOLOEDITTRANSACTION':
      return soloEditTransaction(state,action);
    case 'RECEIVE_SOLOVOIDTRANSACTION':
      return soloVoidTransactions(state,action);
    case 'SOLO_VOID_KEY_CHECKOUT':
      return soloVoidTransactions(state,action);
    case 'RECEIVE_SOLOREMOVETRANSACTION':
      return soloRemoveTransactions(state,action);
    case 'RECEIVE_SOLOGETTAGS':
      return soloGetTags(state,action);
    case 'RECEIVE_KIOSKSCUSTOMERDROPOFF':
      return kisokCustomerDropoff(state,action);
    case 'RECEIVE_SOLOCONFIRMTRANSACTION':
      return soloTransactionConfirm(state,action);
    case 'RECEIVE_SOLOGETCLOSEDTRANSACTION':
      return soloGetTransactionClosed(state,action);
    case 'SOLO_CLEAR_TRANSACTION_CLOSED':
      let transactions = {...state.transactions,closed:[]};
      return {...state,transactions};
    case 'RECEIVE_SOLOADDRETURN':
      return soloAddReturn(state,action);
    case 'RECEIVE_SOLOTRANSACTIONGETDATEANDTYPE':
      return soloGetTransactionByDateAndType(state,action);
    case 'RECEIVE_SOLOACTIVETRANSACTION':
      return soloActiveTransaction(state,action);
    case 'RECEIVE_SOLOREACTIVATETRANSACTION':
      return soloReactivateTransaction(state,action);
    case 'RECEIVE_ASSETSCUSTOMERPICKUP':
      return assetsCustomerPickup(state,action);
    case 'SOLO_PENDING_CONFIRM_ADD':
      return soloPendingConfirmAdd(state,action);
    case 'SOLO_PENDING_CONFIRM_UPDATE':
      return soloPendingConfirmUpdate(state,action);
    case 'SOLO_PENDING_CONFIRM_REMOVE':
      return soloPendingConfirmRemove(state,action);
    case 'SOLO_GET_TRANSACTION_CLOSED':
      return { ...state, viewClosedDate: action.date };
    case 'RECEIVE_SOLOGETNEXTSTOCKNUMBER':
      return soloGetNextStockNumber(state,action);
    case 'RECEIVE_SOLOGETKIOSKOPENSLOTS':
      return soloGetKioskOpenSlots(state,action);
    case 'RECEIVE_SOLOGETASSETSFORRETURN':
      return { ...state, transactions: { ...state.transactions,
        returnTransaction: reverse(map( get( action, 'data.transactions' ),
          v => ({ ...v, type: 'return' }) )) } };
    case 'RECEIVE_SOLOCHECKUSEREXIST':
      return soloCheckUserExist(state,action);
    case 'SOLO_REACTIVATE_TRANSACTION':
      return soloReactiveTransaction(state,action);
    case 'HANDLE_LOGOUT':
      return cloneDeep(initialState);
    default:
      return state;
  }
}

function soloGetTransactions(state,action) {
  let {transactions,kiosks} = action.data;
  let containerid = state.currentContainerid;
  let pending = [];
  let live = [];
  let returnTransaction = [];
  let missed = [];

  if (!isEmpty(kiosks)) {
    containerid = get(kiosks,'0.containerID');
  }

  let closed = state.currentContainerid == containerid ?
    [...state.transactions.closed] : [];

  kiosks = map(kiosks, kiosk => {
    let value = kiosk.KioskId;
    let label = kiosk.kioskName;
    return {...kiosk,value,label};
  });
  let today = moment().utc().toISOString();
  forEach(transactions, function(t) {
    if( t.type == 11 )
      t.type = "pickup";
    else if( t.type == 12 )
      t.type = "dropoff";
    else if( t.type == 2 )
      t.type = "return";

    switch (t.status) {
      case 1:
        if( t.type == "return" )
          returnTransaction.push( t );
        else
          pending = [...pending, t];
        break;
      case 2:
        if(today <  t.enddate) {
          live = [...live, t];
        } else {
          missed = [...missed, t];
        }
        break;
      default:
        break;
    }
  });

  return {
    ...state,
    currentContainerid:containerid,
    transactions:
      {
        pending,
        live,
        returnTransaction,
        missed,
        closed
      },
    kiosks
  };
}

function soloAddTransaction(state,action) {
  let { transaction, type } = action.data;

  // Mutates state.transactions?
  // state.transactions.pending = [...state.transactions.pending,transaction];

  let transactionsPendingConfirm = [];

  for( let t of state.transactionsPendingConfirm ) {
    if( get( t, 'kiosk.value.tagid' ) != transaction.tagid )
      transactionsPendingConfirm.push( t );
  }

  // When Keyscheckout is comfirm. Remove the key checkout
  let returnTransaction = type === 14 ? remove(state.transactions.returnTransaction, t=> {
    return transaction.transactionid !== t.transactionid
  }) : state.transactions.returnTransaction;

  return { ...state,
    transactions: {
      ...state.transactions,
      returnTransaction,
      pending: [
        ...state.transactions.pending,
        action.data,
      ]
    },
    transactionsPendingConfirm,
  };
}

function soloEditTransaction(state,action) {
  let {transaction,type} = action.data;
  let {transactions} = state;

  if(type === 'return') {
    type = 'returnTransaction'
  }
  let index = findIndex(transactions[type],['transactionid', transaction.transactionid]);
  transactions[type][index] = transaction;

  return {...state,transactions: {...transactions}};
}

function soloVoidTransactions(state,action) {
  let {transactions} = state;
  let {transactionid} = action.data;
  forEach(transactions,(t,key)=> {
    let removeTransaction = remove(transactions[key],(t)=> {
      return t.transactionid !== transactionid
    });
    transactions[key] = [...removeTransaction]
  });
  return {...state,transactions:{...transactions}};
}

function soloRemoveTransactions(state,action) {
  let {trainsactionid} = action.data;
  let {transactions} = state;
  let pending = remove(transactions.pending,(t)=>t.transacionid !== trainsactionid);
  return {...state,transactions:{...transactions,pending}};
}

function soloGetTags(state,action) {
  let {tags} = action.data;
  // only get tags that do not have asset assign
  tags = remove(tags,({assetid})=>null !== assetid); // should filter userid?
  return {...state,tags};
}

function kisokCustomerDropoff(state,action) {
  // let {tagid, kioskid, kioskname, drawernumber} = action.data.kiosk;
  // only get tags that do not have asset assign
  let kiosksDropOff = map(action.data.kiosks, kiosk => {
    let value = kiosk.kioskid;
    let label = kiosk.kioskname;
    return {...kiosk,value,label};
  });
  kiosksDropOff = orderBy(kiosksDropOff,['label'],['asc', 'desc']);
  return {...state,kiosksDropOff};
}

function soloTransactionConfirm(state,action) {

  let {transactionid} = action.data;
  let {transactions} = state;

  let pending = remove(transactions.pending, function(t) {
    return t.transactionid !== transactionid;
  });
  transactions.live = [...transactions.live,transactions];
  return {...state,transactions:{...transactions,pending}};
}

function soloGetTransactionClosed(state,action) {
  let {transactions,startdate,enddate,currentContainerid} = state;
  let recieveStartdate = action.data.startdate;
  let recieveEnddate = action.data.enddate;
  let contaienrid = action.data.containerid;
  let closed = map(action.data.transactions,t=> {
    if( t.type === 11 )
      t.type = "pickup";
    else if( t.type === 12 )
      t.type = "dropoff";
    else if( t.type === 2 )
      t.type = "return";
    t.closedtime = moment(t.closedtime).format('MM/DD/YYYY hh:mm a');
    return {...t}
  });
  if(
    recieveStartdate === startdate
    && recieveEnddate === enddate
    && currentContainerid === contaienrid
  ) {
    closed = [...reverse(closed),...transactions.closed];
    // closed = [...transactions.closed,...closed];
    return {...state,transactions:{...transactions,closed}};
  }
  startdate = recieveStartdate;
  enddate = recieveEnddate;
  transactions = {...transactions,closed};
  return {
    ...state,
    transactions,
    startdate,
    enddate,
    currentContainerid:contaienrid
  };
}

function soloAddReturn(state,action) {
  let {transactions} = state;
  let transaction = action.data.transaction;
  transactions.return =[...transactions.return,transaction];
  let closed = remove(transactions.closed,({transacionid})=>
    transaction.transactionid !== transacionid);
  return {...state,transactions:{...transactions,closed}};
}
function soloGetTransactionByDateAndType(state,action) {
  let {transactions} = state;
  let {transactions:t,type} = action.data;
  switch (type) {
    case 0:
      //missing
      transactions.missed=[...transactions.missed,t];
      break;
    case 4:
      // return
      transactions.returnTransaction=[...transactions.returnTransaction,t];
      break;
    default:
      return state;
  }
  return {...state,transactions:{...transactions}};
}

function soloActiveTransaction (state,action) {
  let {transaction} = state;
  let {transactionid,startdate,enddate} = action.data;
  transaction.missed = remove(transaction.missed,t=> {
    if(t.transactionid === transactionid) {
      let activeTransaction = {...t,startdate,enddate};
      transactionid.pending = [...transactionid.pending,activeTransaction]
    }
    return t.transactionid !== transactionid;
  });

  return {...state,transaction:{...transaction}}
}

function soloReactivateTransaction( state, action ) {
  const transactionid = get( action, 'data.transactionid' );

  let missed = [];

  for( let t of state.transactions.missed ) {
    if( t.transactionid != transactionid )
      missed.push( t );
  }

  return { ...state, transactions: { ...state.transactions, missed } };
}

function assetsCustomerPickup( state, action ) {
  let assetsCustomerPickup = orderBy(action.data.kiosks,
    ['kioskname','drawernumber','stocknumber'],['asc', 'desc']);
  return {
    ...state,
    assetsCustomerPickup,
  };
}

function soloPendingConfirmAdd( state, action ) {
  let transactionsPendingConfirm = [
    ...state.transactionsPendingConfirm,
    action.data,
  ];
  return {
    ...state,
    transactionsPendingConfirm,
    generatedStockNumber: '',
  };
}

function soloPendingConfirmUpdate( state, action ) {
  let transactionsPendingConfirm = [ ...state.transactionsPendingConfirm ];
  for( let i in transactionsPendingConfirm ) {
    let t = transactionsPendingConfirm[i];
    if( t.tagid == action.data.tagid && t.assetid == action.data.assetid
      && t.kioskid == action.data.kioskid ) {
      transactionsPendingConfirm[i] = action.data;
      break;
    }
  }
  return {
    ...state,
    transactionsPendingConfirm,
  };
}

function soloPendingConfirmRemove( state, action ) {
  let transactionsPendingConfirm = [];
  for( let i in state.transactionsPendingConfirm ) {
    let t = state.transactionsPendingConfirm[i];
    if( !( t.tagid == action.data.tagid && t.assetid == action.data.assetid
      && t.kioskid == action.data.kioskid ) ) {
      transactionsPendingConfirm.push( t );
    }
  }
  return {
    ...state,
    transactionsPendingConfirm,
  };
}

function soloGetNextStockNumber( state, action ) {
  return {
    ...state,
    generatedStockNumber: get( action, 'data.stocknumber' ),
  };
}

function soloGetKioskOpenSlots( state, action ) {
  let kiosksOpenSlots = get(action,'data.kiosks');
  kiosksOpenSlots = map(kiosksOpenSlots,k=> {
    return {...k,label:k.kioskname,value:k.kioskid}
  });
  kiosksOpenSlots = orderBy(kiosksOpenSlots,['kioskname'],['asc', 'desc']);
  return {
    ...state,
    kiosksOpenSlots
  };
}

function soloCheckUserExist( state, action ) {
  let userExist = get(action,'data.solouserid');
  let {transactionsPendingConfirm} = state;

  if (!userExist) {
    transactionsPendingConfirm = dropRight(transactionsPendingConfirm)
  }

  return {...state,userExist,transactionsPendingConfirm};
}

function soloReactiveTransaction( state, action ) {
  let userExist = get(action,'data.solouserid');
  let {transactionsPendingConfirm} = state;

  if (!userExist) {
    transactionsPendingConfirm = dropRight(transactionsPendingConfirm)
  }

  return {...state,userExist,transactionsPendingConfirm};
}
