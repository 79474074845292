import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Input, FormGroup } from 'reactstrap';
import { defaultTo } from 'lodash/fp';
import { fetchHierarchy } from '../actions/containers';
import _ from 'lodash';
import './ContainerSelection.css';

class ContainerSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      containerID: props.initialContainerID,
    };
  }

  UNSAFE_componentWillMount() {
    const { isUnloaded, fetchHierarchy, onChange } = this.props;
    if (isUnloaded) {
      fetchHierarchy();
    } else {
      onChange(this.state.containerID);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.isUnloaded && !nextProps.isUnloaded) {
      const containerID = nextProps.currentContainer;
      this.setState({ containerID });
    }

    if(this.props.containerID !== nextProps.currentContainer) {
      const containerID = nextProps.currentContainer;
      this.setState({ containerID });
    }
  }

  onChangeSelection = event => {
    const containerID = event.target.value;
    this.setState({ containerID });
    this.props.onChange(containerID);
  };

  render() {
    const { isUnloaded, containers } = this.props;
    let options = '';
    if (isUnloaded) {
      options = (<option>Loading...</option>)
    } else {
      options = _.chain(containers)
        .sortBy([(c) => c.containerName])
        .map(({ containerID, containerName }) => (
          <option key={containerID} value={containerID}>
            {containerName}
          </option>
        ))
        .value();
    }
    return (
      <FormGroup>
        <Input
          type="select"
          className="container-selection"
          onChange={this.onChangeSelection}
          disabled={isUnloaded}
          value={defaultTo('', this.state.containerID)}
          bsSize="sm"
          // size="sm"
        >
          {options}
        </Input>
      </FormGroup>
    );
  }
}

const mapStateToProps = state => ({
  isUnloaded: state.containers.status !== 'fetched',
  defaultContainerID: state.containers.containerroot,
  containers: state.containers.containers,
  currentContainer: state.containers.currentContainer,
});

const mapDispatchToProps = { fetchHierarchy };

export default connect(mapStateToProps, mapDispatchToProps)(ContainerSelection);
