// import {get} from 'lodash';

import {cloneDeep} from "lodash";

const initialState = {
  file: '',
  name: '',
  size: '',
  type: '',
  url: '',
};

export default function document(state = initialState, action) {
  switch (action.type) {
    case 'UPLOADPDF':
      return uploadPDF(state, action);
    case 'RECEIVE_UPLOADDOCUMENTTEMPLATE':
      return { ...state, url: action.data.url} ;
    case 'CLEAR_URL':
      return {...state, url: ''};
    case 'HANDLE_LOGOUT':
      return cloneDeep(initialState);
    default:
      return state;
  }
}

function uploadPDF(state, action) {
  const {file,name,size,type, url } = action.doc;
  return { ...state, file, name, size, type, url: url ? url : state.url }
}
