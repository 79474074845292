import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { compose } from 'lodash/fp';
import { toggleAssetArchived } from '../actions/assets';

const ArchiveButton = ({ asset, onClick }) => {
  if (asset.isArchived) {
    return (
      <Button
        color="secondary"
        style={{ width: '8.0em' }}
        onClick={() => onClick(asset)}
      >
        Sold
      </Button>
    );
  }
  return (
    <Button
      color="primary"
      style={{ width: '8.0em' }}
      onClick={() => onClick(asset)}
    >
      In Stock
    </Button>
  );
};

export default connect(null, (dispatch, ownProps) => ({
  onClick: compose(dispatch, toggleAssetArchived),
}))(ArchiveButton);
