import {cloneDeep,findIndex,reduce,map,filter,isEmpty,find,get} from 'lodash';
import moment from 'moment';
const initialState = {
  alertypes: [],
  schedules: [],
  metaSchedules: [],
  contactTypes: [],
  containerGroups: [],
  contacts: [],
  groups: [],
  users: [],
  newSchedules: [],
  deleteSchedules: [],
  deleteGroupUsers:[],
  timezone: {}
};

export default function useralert(state = initialState, action) {
  switch (action.type) {
    case 'RECEIVE_USERGETSCHEDULEBYCONTAINER':
      return recieveGetScheduleByContainer(state, action);
    case 'RECEIVE_USERADDALERTSCHEDULEBYCONTAINER':
      return recieveUserAddAlertScheduleByContainer(state, action);
    case 'RECEIVE_USERALERTADDSCHEDULE':
      return recieveUserAlertAddSchedule(state, action);
    case 'RECEIVE_USERSETALERTSCHEDULE':
      return recieveUserSetAlertSchedule(state, action);
    case 'RECEIVE_USERALERSCHEDULEDELETEDYID':
      return recieveUserAlertScheduleDeleteById(state, action);
    case 'RECEIVE_USERGETALERTGROUP':
      return recieveUserGetAlertGroup(state, action);
    case 'RECEIVE_USERGETSCHEDULEGROUPS':
      return recieveUserGetScheduleGroup(state, action);
    case 'RECEIVE_USERADDALERTGROUP':
      return recieveUserAddAlertGroup(state, action);
    case 'RECEIVE_USERCOPYGROUPUSERSTONEWGROUP':
      return recieveUserCopyGroupUsersToNewGroup(state, action);
    case 'RECEIVE_USERREMOVEALERTGROUP':
      return recieveUserRemoveAlertGroup(state, action);
    case 'RECEIVE_USERALERTADDUSERTOGROUP':
      return recieveUserAlertAddUserToGroup(state, action);
    case 'RECEIVE_USERALERTDELETEUSERFROMGROUP':
      return recieveUserAlertDeleteUserFromGroup(state, action);
    case 'RECEIVE_USERALERTADDGROUPNAME':
      return recieveUserAlertAddGroupName(state, action);
    case 'RECEIVE_USERALERTDELETEGROUPNAMEBYID':
      return recieveUserAlertDeleteGroupNameById(state, action);
    case 'RECEIVE_USERALERTEDITGROUPNAME':
      return recieveUserAlertEditGroupName(state, action);
    case 'RECEIVE_USERALERTSETTIMEZONE':
      return recieveUserAlertSetTimezone(state, action);
    case 'SET_ALERT_NEW_SCHEDULES':
      let newSchedules = action.data;
      return {...state,newSchedules};
    case 'SET_ALERT_DELETE_SCHEDULES':
      let deleteSchedules = action.data;
      return {...state,deleteSchedules};
    case 'SET_ALERT_DELETE_GROUPUSER':
      let deleteGroupUsers = action.data;
      return {...state,deleteGroupUsers:[...deleteGroupUsers]};
    case 'HANDLE_LOGOUT':
      return cloneDeep(initialState);
    default:
      return state;
  }
}

function recieveUserAlertSetTimezone(state,action) {
  let value = action.data.value || '';
  return {...state,timezone:{...state.timezone,value,label:value}}
}

function getNumberToDay(day) {
  switch(day) {
    case 1:
      return 'Sunday';
    case 2:
      return 'Monday';
    case 3:
      return 'Tuesday';
    case 4:
      return 'Wednesday';
    case 5:
      return 'Thursday';
    case 6:
      return 'Friday';
    default:
      return 'Saturday'
  }
}

function recieveGetScheduleByContainer(state, action) {
  const {schedules,types,groupname, } = action.data.userInfo;
  const timezone = action.data.timezone || {value:'', label:''};
  const alertypes = types.map(({alerttype,description})=> {
    return {value:alerttype,label:description};
  });

  const contactTypes = action.data.userInfo.contactTypes.map(({contacttypeid,description})=> {
    return {value:contacttypeid,label:description};
  });

  const users = map(action.data.userInfo.users, user => {
    return {...user,value:user.userid,label:user.username}
  });
  const scheduleUser = nomalizeScheduleUserGroups(schedules,users);

  const scheduleWithDays = normalizeScheduleWithDays(schedules);

  const groups = normailizeGroup(groupname,alertypes,users);

  let userGroupAndSchedule = normalizeUserGroupAndSchedule(groups,alertypes,scheduleWithDays);
  // let userGroupAndSchedule = normalizeUserGroupAndSchedule(groups,alertypes,scheduleWithDays);

  return {...state, metaSchedules:schedules,schedules:scheduleUser, alertypes, users, userGroupAndSchedule, groups ,contactTypes, timezone }
}

function recieveUserAddAlertScheduleByContainer(state, action) {
  const {schedules,users} = state;
  const {addedschedule} = action.data;
  const scheduleUser = nomalizeScheduleUserGroups([addedschedule],users);
  return {...state, schedules:[...schedules,scheduleUser[0]] }
}

function recieveUserSetAlertSchedule(state, action) {
  const schedule = action.data.schedule;
  const {schedules} = state;
  const index = findIndex(schedules,['alertscheduleid',schedule.alertscheduleid]);
  schedules[index] = schedule;
  return {...state, schedules:[...schedules] }
}

function recieveUserGetAlertGroup(state, action) {
  const {groups,contacttypes} = action.data;
  const contacts = contacttypes.map(type=>({value:type.contacttypeid,label:type.description}));
  return {...state, groups,contacts }
}

function recieveUserGetScheduleGroup(state, action) {
  const {schedulegroups} = action.data;
  const containerGroups = schedulegroups
    .map(group=>({...group,value:group.alertgroupid,label:group.description}));
  return {...state, containerGroups }
}

function recieveUserAddAlertGroup(state, action) {
  const {groups,schedules} = state;
  const {userid,contacttype,description,alertgroupid} = action.data;
  if(groups[userid]) {
    let contacts = groups[userid].contacts;
    groups[userid] = { ...groups[userid], contacts: {...contacts,[contacttype]: description } };
  }

  // Remove user from schedule user list
  let index = findIndex(schedules,['alertgroupid',alertgroupid]);
  if(index !== -1) {
    let users = schedules[index].users;
    let userIndex =  findIndex(users,['value',userid]);
    if(userIndex !== -1) {
      let users = schedules[index].users.filter(user =>user.value !== userid);
      schedules[index] = {...schedules[index],users}
    }
  }

  return {...state, groups:{...groups},schedules:[...schedules] }
}

function recieveUserCopyGroupUsersToNewGroup(state, action) {
  const {groups} = state;
  const {schedulegroups} = action.data;
  const newGroups = reduce(schedulegroups,(obj,group)=>{
    let contacts = obj[group.userid] ?
      {...obj[group.userid].contacts,[group.contacttype]:group.description}
      :
      {[group.contacttype]:group.description};

    obj[group.userid] = {...obj[group.userid],...group,contacts};
    return obj
  },groups);
  return {...state, groups:{...newGroups} }
}

function recieveUserRemoveAlertGroup(state, action) {
  const {groups} = state;
  const {userid,contacttype} = action.data;
  if(groups[userid]) {
    delete groups[userid].contacts[contacttype];
    let contacts = groups[userid].contacts;
    groups[userid] = {...groups[userid],contacts:{...contacts}}
  }
  return {...state, groups:{...groups} }
}

function recieveUserAlertScheduleDeleteById(state, action) {
  const id = action.data.alertscheduleid;
  const metaSchedules = filter(state.metaSchedules,({alertscheduleid})=> alertscheduleid !==id);
  const schedules = filter(state.schedules,({alertscheduleid})=> alertscheduleid !==id);
  const userGroupAndSchedule = reduce(state.userGroupAndSchedule,(array,obj)=> {
    let index = schedules.indexOf(id);

    if(index !== -1 || obj.alertscheduleid !==id ) {
      array = [...array,obj]
    }
    return array;
  },[]);

  return {
    ...state,
    schedules,
    userGroupAndSchedule:[...userGroupAndSchedule],
    metaSchedules
  }
}

function recieveUserAlertAddUserToGroup(state, action) {
  let data = get(action,'data.data');
  if(isEmpty(data)) {
    return state;
  }
  let groups = map(state.groups,group=> {
    let alertgroupid = group.alertgroupid;
    if(alertgroupid === data.alertgroupid) {
      let userIndex = findIndex(state.users,{userid:data.userid});
      let userInfo = state.users[userIndex];
      let contactinfo = '';
      if(userInfo) {
        contactinfo = data.contacttype === 2 ? userInfo.email : userInfo.phone;
      }

      let alertTypeIndex = findIndex(state.alertypes,{value:data.alerttype});
      let alertType = state.alertypes[alertTypeIndex];
      let alertinfo = '';
      if(alertType) {
        alertinfo =  alertType.label;
      }
      console.log('data ****',data);
      console.log('group ****',group);
      console.log('group.users[0] ****',group.users[0]);
      data = {...data,contactinfo,alertinfo};
      let users = group.users[0] ? [...group.users,data] : [data];
      group = {...group,users}
    }
    return group;
  });
  return {...state,groups}
}

function recieveUserAlertDeleteUserFromGroup(state, action) {
  let {alertgroupid,contacttype,userid} = action.data;
  let groups = map(state.groups,group=> {
    if(alertgroupid === group.alertgroupid) {
      let users = reduce(group.users,(array,user)=>{
        if(
          user.alertgroupid === alertgroupid &&
          user.contacttype === contacttype &&
          user.userid === userid
        ) {
          return array
        }
        array = [...array,user];
        return array
      },[]);
      group = {...group,users}
    }
    return group;
  });
  return {...state,groups}
}

function recieveUserAlertAddSchedule(state, action) {
  let metaSchedules = state.metaSchedules;
  let {userInfo} = action.data;

  metaSchedules = [...metaSchedules,userInfo];

  let scheduleWithDays = normalizeScheduleWithDays(metaSchedules);
  let userGroupAndSchedule = normalizeUserGroupAndSchedule(state.groups,state.alertypes,scheduleWithDays);

  return {...state,metaSchedules,userGroupAndSchedule}
}

function recieveUserAlertAddGroupName(state, action) {
  let data = action.data.data;
  let newGroup = {...data,label:data.name,value:data.alertgroupid,users:[]};
  return {...state,groups:[...state.groups,newGroup]}
}

function recieveUserAlertDeleteGroupNameById(state, action) {
  let alertgroupid = action.data.alertgroupid;
  let groups = reduce(state.groups,(array,group)=> {
    if(group.alertgroupid === alertgroupid)
      return array;
    array = [...array,group];
    return array;
  },[]);
  return {...state,groups}
}

function recieveUserAlertEditGroupName(state, action) {
  let {alertgroupid,name} = action.data;
  let groups = map(state.groups,group=>{
    if(group.alertgroupid === alertgroupid)
      group = {...group,name,label:name};
    return {...group};
  });

  let userGroupAndSchedule = map(state.userGroupAndSchedule,schedule=>{
    if(schedule.alertgroupid === alertgroupid)
      schedule.group = {...schedule.group,name,label:name};
    return {...schedule};
  });
  return {...state,groups,userGroupAndSchedule}
}

function normailizeGroup(data,alertypes,users) {
  return reduce(data,(array,group) => {
    let {
      alertgroupid,
      alerttype,
      contacttype,
      containerid,
      description,
      name,
      userid,
      username,
    } = group;
    let index = findIndex(array,{alertgroupid});
    let data = {alertgroupid,containerid,name};
    let userIndex = findIndex(users,{userid});
    let userInfo = users[userIndex];
    let contactinfo = '';
    if(userInfo) {
      contactinfo = contacttype === 2 ? userInfo.email : userInfo.phone;
    }

    let alertTypeIndex = findIndex(alertypes,{value:alerttype});
    let alertType = alertypes[alertTypeIndex];
    let alertinfo = '';
    if(alertType) {
      alertinfo =  alertType.label;
    }
    let user = {userid,username,description,alerttype,contacttype,alertgroupid,contactinfo,alertinfo};

    if(index !== -1) {
      array[index].users = [...array[index].users,user];
      array = [...array];
    } else {
      data.users = userid ? [user] : [];
      data.value = alertgroupid;
      data.label = name;
      array = [...array,{...data}]
    }
    return array;
  },[]);
}

function normalizeUserGroupAndSchedule(groups,alertypes,scheduleWithDays) {
  return reduce(scheduleWithDays,(array,value) => {
    let alertgroupid = value.alertgroupid;
    let group = find(groups,{alertgroupid});
    if(group) {
      let {alerttype,hour,minute} = value;
      let types = find(alertypes,{value:alerttype});

      let time = moment().utcOffset(0)
        .set({hour, minute})
        .local()
        .format('h:mm a');
      let obj =  {
        ...value,
        time,
        group: {value:alertgroupid,label:group.name},
        alertypes:[types]
      };
      array = [...array,obj]
    }

    return array
  },[]);
}

// function normalizeUserGroupAndSchedule(data,alertypes,scheduleWithDays) {
//   return reduce(data,(array,group) => {
//     let alertgroupid = group.alertgroupid;
//     let schedule = find(scheduleWithDays,{alertgroupid});
//     if(schedule) {
//       let {alerttype,hour,minute} = schedule;
//       let types = find(alertypes,{value:alerttype});
//
//       let time = moment().utcOffset(0)
//         .set({hour, minute})
//         .utc()
//         .format('h:mm a');
//       // moment.tz(moment.utc(item.createdOn), moment.tz.guess())
//       let obj =  {
//         ...schedule,
//         time,
//         group: {value:alertgroupid,label:group.name},
//         alertypes:[types]
//       };
//       array = [...array,obj]
//     }
//
//     return array
//   },[]);
// }


function normalizeScheduleWithDays(data) {
  return reduce(data,(obj,schedule)=>{
    let {alertgroupid,alertscheduleid,dayofweek,alerttype,hour,minute} = schedule;
    let key = `${alertgroupid}-${alerttype}-${hour}-${minute}`;
    if(!obj[key]) {
      obj[key] = schedule;
    }
    if(obj[key]) {
      let time = moment().utcOffset(0)
        .set({hour, minute}).day(dayofweek)
        .local().day();
      let day = getNumberToDay(time);

      console.log(day);
      
      if(!obj[key].days) {
        obj[key].days = {}
      }
      obj[key].days[day] = true;
      obj[key] = {...obj[key]};

      if(!obj[key].schedules) {
        obj[key].schedules = []
      }
      obj[key].schedules = [...obj[key].schedules,alertscheduleid];
    }
    return obj;
  },{});
}

function nomalizeScheduleUserGroups(schedules, users) {
  return schedules.map(schedule => {
    let usersgroups = reduce(users,(array,user)=>{
      if(!user.groups.includes(schedule.alertgroupid)) {
        array =[...array,{value:user.userid,label:user.username}]
      }
      return array;
    },[]);
    return {...schedule,users:usersgroups}
  });
}
