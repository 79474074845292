import { get, values,map } from 'lodash';

const test_state = {
  nodes: [
    {
      id: 'kiosk_2045',
      kioskid: 2045,
      type: 'kiosk',
      symbolType: 'square',
      mouseCursor: 'not-allowed',
    },
  ],
  edges: [
  ],
};

const initial_state = {

};

export default function newboardconfig( state = test_state, action ) {
  switch( action.type ) {
    case 'RECEIVE_BOARDCONFIGLIST':
      return receiveBoardConfigList( state, action );
    default:
      return state;
  };
};

const receiveBoardConfigList = ( state, action ) => {
  // console.log( action.data );
  const kiosks = get( action, 'data.kiosks' );
  const devices = [ ...values( get( action, 'data.linkeddevices' ) ),
    ...values( get( action, 'data.unlinkeddevices' ) ) ];

  let nodes = {};
  let edges = [];
  let nodeids = {};
  let linkids = {};

  nodes['container'] ={
    id: 'container' ,
    label: '',
    hidden: true,
    mass: 20,
  };

  for( let i in kiosks ) {
    let kiosk = kiosks[i];
    let id = 'kiosk_' + get( kiosk, 'kioskid' );
    nodeids[id] = 1;
    nodes[id] = {
      id,
      label: get( kiosk, 'kioskname' ) + ' : ' + get( kiosk, 'serialnumber' ),
      mass: 10,
    };

    edges.push({
      from: 'container',
      to: id,
      hidden: true,
    });
  }

  for( let i in devices ) {
    let device = devices[i];
    let id = 'device_' + get( device, 'deviceid' );
    let parentid = 'kiosk_' + get( device, 'parentkiosk' );
    let linkedid = 'kiosk_' + get( device, 'linkedkiosk' );

    if( parentid in nodeids ) {
      nodes[id] = {
        id,
        label: get( device, 'devicetype' ),
        mass: 1,
      };

      if( linkedid && linkedid in nodeids ) {
        edges.push({
          from: linkedid,
          to: id,
          color: 'blue',
          id: 'link_' + id + '_' + linkedid,
        });
        linkids[( id + ',' + linkedid )] = 1;
      }

      edges.push({
        from: parentid,
        to: id,
        arrows: {
          to: { enabled: false },
        },
      });
      linkids[( id + ',' + parentid )] = 1;

      nodeids[id] = 1;
    }
  }

    /*
    let target = 'kiosk_' + get( device, 'parentkiosk' );
    if( target in kioskids ) {
      links.push({
        source: 'device_' + get( device, 'deviceid' ),
        target,
      });
    }
  for( let target in nodeids ) {
    links.push({
      source: 'container',
      target,
    });
  }
    */

  return { ...state, nodes:map(nodes), edges:[...edges], };
};
