import { pull, cloneDeep, reduce} from 'lodash';

const initialState = {};

export default function groups(state = initialState, action) {
  switch (action.type) {
    case 'RECEIVE_GROUPLIST':
      return groupList (state,action);
    case 'RECEIVE_GROUPADD':
      return addGroup(state,action);
    case 'RECEIVE_GROUPUSERADD':
      return addUserToGroup(state,action);
    case 'RECEIVE_GROUPNAMESET':
      return groupSetName(state,action);
    case 'RECEIVE_GROUPUSERSET':
      return groupUserSet(state,action);
    case 'RECEIVE_GROUPREMOVE':
      return groupRemove(state,action);
    case 'RECEIVE_GROUPUSERREMOVE':
      return groupUserRemove(state,action);
    case 'RECEIVE_GROUPTAGLIMITGET':
      return groupTaglimitGet(state,action);
    case 'RECEIVE_GROUPTAGLIMITSET':
      return groupTaglimitSet(state,action);
    case 'HANDLE_LOGOUT':
      return cloneDeep(initialState);
    default:
      break;
  }
  return state;

}
function groupList (state,action) {
  return reduce(action.data.groups, (obj,group) => {
    let g = normalizeGroup(group);
    obj[g.groupID] = {...state[g.groupID],...g};
    return obj;
  },{});
}

function addGroup(state,action) {
  let group = normalizeGroup(action.data);
  state[group.groupID] = group;
  return {...state};
}

function addUserToGroup(state,action) {
  let {groupid,userid} = action.data;
  if(state[groupid]) {
    state[groupid] = {
      ...state[groupid],
      members : [...state[groupid].members,userid]
    };
  }
  return {...state};
}

function groupSetName(state,action) {
  let {groupid,groupname:groupName} = action.data;
  state[groupid] = {...state[groupid],groupName};
  return {...state};
}

function groupUserSet(state,action) {
  let {groupid,targetuserid} = action.data;
  let userid = parseInt(targetuserid, 10);
  if(state[groupid]) {
    return reduce(state,(obj,group)=> {
      let id = group.groupID;
      if(id === groupid) {
        obj[id] = {...group,members:[...group.members,userid]};
      } else {
        let members = pull(group.members,userid);
        obj[id] = {...group,members};
      }
      return obj;
    },{});
  }
  return state;
}

function groupRemove(state,action) {
  let {groupid} = action.data;
  delete state[groupid];
  return {...state};
}

function groupUserRemove(state,action) {
  let {groupid,userid} = action.data;
  if(state[groupid]) {
    let members = pull(state[groupid].members,userid);
    state[groupid] = {...state[groupid],members};
  }
  return {...state};
}

function groupTaglimitGet(state,action) {
  let {groupid,taglimit} = action.data;
  state[groupid].taglimit = taglimit;
  return {...state};
}

function groupTaglimitSet(state,action) {
  let {groupid,taglimit} = action.data;
  state[groupid].taglimit = taglimit;
  return {...state};
}

function normalizeGroup(group) {
  let groupID = group.groupid || group.groupID || group.i;
  let containerID = group.containerid || group.containerID || group.container || group.c;
  return {
    containerID: `${containerID}`,
    groupID: `${groupID}`,
    groupName: group.groupname || group.groupName || group.n,
    members: group.members || group.m  || []
  }
}
