import React from 'react';
import { replace, get } from 'lodash';
import axios from 'axios';
import {serverHost} from './constants';
import {multilanguage} from "redux-multilanguage";

export const Tr = function () {
  let translate = arguments[0];
  for(let i = 1; arguments.length > i; i++) {
    translate = replace(translate, '%' + i, arguments[i]);
  }
  return translate;
};

export const Text = multilanguage(({strings,value})=> {
  return <>{get(strings,value,value)}</>
});

export const valueNullToString = (obj,string) => get(obj,string,string);

export const languageTranslations = async language => {
  return get( await axios.get(`https://${serverHost()}/language/${language}.json`,
      { timeout: 1000,}), 'data' );
};

export const languageList = async () =>{
  return get( await axios.get(`https://${serverHost()}/language/list.php`,
    { timeout: 3000 }), 'data' ) || [];
};
