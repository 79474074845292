import _, {cloneDeep} from 'lodash';

const initialState = {
  status: {},
  lotlocations: []
};

export default function lotlocations(state = initialState, action) {
  switch(action.type) {
    case 'RECEIVE_LOCATIONLIST':
      let locations = _.reduce( action.data.locations, ( a, v ) => { a[v.geofenceid] = v; return a; }, {} );
      let containerid = _.get(action,'data.containerid');
      let status = state.status;
      if(containerid) {
        status[containerid] = 'fetched';
      }

      return {
        locations,
        status
      };
    case 'RECEIVE_LOCATIONADD':
      return recieveLocationAdd(state, action);
    case 'RECEIVE_LOCATIONDELETE':
      return recieveLocationDelete(state, action);
    case 'RECEIVE_LOCATIONUPDATE':
      return receivelocationUpdate(state, action);
    case 'RECEIVE_LOCATIONEDIT':
      return receivelocationEdit(state, action);
    case 'SAVE_LOCATION':
      return locationSave(state, action);
    case 'HANDLE_LOGOUT':
      return cloneDeep(initialState);
    default:
      return state;
  }
};

function recieveLocationAdd(state, action) {
  let data = _.assign({}, _.get(_.get(action,'data'),'locations'));
  let index = data.geofenceid;
  return {
    locations: {
      ...state.locations,
      [index]: data
    },
    status: 'addlocation'
  };
}
function recieveLocationDelete(state, action) {
  let data = _.assign({},_.get(action,'data'));
  let geofenceid = _.get(data,'geofenceid');
  let newState = _.filter(_.get(state,'locations'),location=> _.get(location,'geofenceid') !== geofenceid);
  let setstate = _.reduce(newState,(accum,lot)=>{
    _.assign(accum,{...accum,[_.get(lot,'geofenceid')]:lot});
    return accum
  },{});
  return {
    locations: {
    ...setstate,
  },
    status: 'deletelocation'
  };
}
function receivelocationEdit(state, action) {
  let data = _.assign({},_.get(action,'data'));
  let index = _.get(_.head(_.get(data,'locations')),'geofenceid');
  let newdata = _.assign({}, _.get(_.get(state,'locations'),index), _.head(_.get(data,'locations')));
  return {
    ...state,
    locations: {
      ...state.locations,
      [index]: newdata
    },
    status: 'locationEdit'
  }
}
function receivelocationUpdate(state, action) {
  let actionData = _.assign([],_.get(action,'data.locations'));

  // When new Primary Location is changes it will get all data
  if(actionData.length > 1 ) {
    let data = _.reduce(actionData,(obj,location)=>{
      _.assign(obj,{[_.get(location,'geofenceid')]:location});
      return obj;
    },{});
    return {
      ...state,
      locations: {
        ...data
      },
      status: 'locationUpdate'
    }
  }
  // Edit name and location only get single data
  let index = _.get(_.head(actionData),'geofenceid');
  let data = _.assign({}, _.get(_.get(state,'locations'),index), _.head(actionData));
  return {
    ...state,
    locations: {
      ...state.locations,
      [index]: data
    },
    status: 'locationUpdate'
  }
}
function locationSave(state, action) {
  let data = _.assign({}, _.get(action,'data'));
  let index = _.get(data,'geofenceid');
  return {
    locations: {
      ...state.locations,
      [index]: data
    },
    status: 'locationsave'
  };
}
