import {cloneDeep} from 'lodash';

const initialState = {
  consumable: []
};

export default function consumable(state = initialState, action) {
  switch(action.type) {
    case 'RECEIVE_CONSUMABLEGET':
      return receiveConsumableGet(state, action);
    case 'RECEIVE_CONSUMABLEMODIFY':
      return receiveConsumableModify(state, action);
    case 'HANDLE_LOGOUT':
      return cloneDeep(initialState);
    default:
      return state;
  }
}

function receiveConsumableGet(state, action) {
  const {consumable} = action.data;
  return {...state,consumable}
}

function receiveConsumableModify(state, action) {
  const {consumable} = action.data;
  const newConsumable = state.consumable.map((data)=> {
    if(data.containerid === consumable.containerid && data.consumabletype === consumable.consumabletype) {
      return {...data,...consumable};
    }
    return data
  });
  return {...state,consumable:newConsumable}
}
