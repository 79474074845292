import _ from 'lodash';

const initialState = {
  listStatus:'unfetched',
  verifiedStatus:'unfetched',
  sourcesStatus: 'unfetched',
  open:[],
  close:[],
  notFound:[],
  verified:[],
  sources:[],
  auditid: null,
};

export default function auditlog(state = initialState, action) {
  switch(action.type) {
    case 'RECEIVE_AUDITLOGGETLIST':
      return receiveAuditLogList(state, action);
    case 'RECEIVE_AUDITSOURCEDATALIST':
      return receiveAuditLogSourceList(state, action);
    case 'RECEIVE_AUDITLOGBYID':
      return receiveAuditLogByID(state, action);
    case 'RECEIVE_AUDITLOGBYID2':
      return receiveAuditLogByID2(state, action);
    case 'RECEIVE_AUDITNEW':
      return receiveAuditAddNew(state, action);
    case 'RECEIVE_AUDITCLOSE':
      return receiveAuditClose(state, action);
    case 'RECEIVE_AUDITADDASSET':
      return receiveAuditAddAsset(state, action);
    case 'RECIEVE_AUDITNOTESET':
      return receiveAuditNoteSet(state, action);
    case 'REIEVE_AUDITUPLOADFILE':
      return recieveAuditUploadFile(state, action);
    case 'HANDLE_LOGOUT':
      return _.cloneDeep(initialState);
    default:
      return state;
  }
}

function receiveAuditLogList(state,action) {
  let open = _.get(action,'data.listofsources.open');
  let close = _.get(action,'data.listofsources.close');
  let selectthisaudit = _.get(action,'data.selectthisaudit');
  return {...state,open,close,selectthisaudit,listStatus:'fetched'};
}

function receiveAuditLogSourceList(state,action) {
  let sources = _.get(action,'data.sources');
  return {...state,sources,sourcesStatus:'fetched'};
}

function receiveAuditAddNew(state,action) {
  let open = _.get(action,'data.listofsources');
  return {...state,open};
}

function receiveAuditClose(state, action) {
  let auditID = _.get(action,'data.auditid');
  let removeOpen = _.find(state.open,{auditID});
  let open = _.filter(state.open,(audit)=>audit.auditID !== auditID);
  let close = [...state.close,removeOpen];
  return {...state,open,close};
}

function receiveAuditLogByID(state,action) {
  // debugger;
  let notFound = _.get(action,'data.audit.notfound');
  let verified = _.get(action,'data.audit.verified');
  let auditid = _.get(action,'data.auditid');
  return {...state,notFound,verified,auditid,verifiedStatus:'fetched'};
}

function receiveAuditLogByID2(state,action) {
  let notFound = normailizeAuditStatus(_.get(action,'data.audit.notfound',[]))
  let verified = normailizeAuditStatus(_.get(action,'data.audit.verified',[]));
  let auditid = _.get(action,'data.auditid');
  return {...state,notFound,verified,auditid,verifiedStatus:'fetched'};
}

function normailizeAuditStatus (data) {
  return data.map((audit)=> {
    let status = audit.status === 2 ? 'Not Imported: Duplicate Asset' :
      audit.status === 3 ? 'Not Imported: Marked Sold' :
        audit.status === 4 ? 'Not Imported: Not Found In Inventory' :'Scanned';
    return {...audit,status}
  });
}

function receiveAuditAddAsset(state, action) {
  let assetid = _.get(action,'data.assetid');
  let username = _.get(action,'data.username');
  let scanuserid = _.get(action,'data.scanuserid');
  let latitude = _.get(action,'data.latitude');
  let longitude = _.get(action,'data.longitude');
  let when = _.get(action,'data.when');
  let location = {latitude,longitude};

  let currentNotFound = _.get(state,'notFound');

  // To prevent audit list from breaking
  if(_.isEmpty(currentNotFound)) {
    return state;
  }

  let notFound = _.filter(currentNotFound,asset=>asset.assetid !== assetid);
  let removeNotFound = _.find(currentNotFound,{assetid});

  if(!removeNotFound) {
    return state;
  }

  let verified;
  _.isEmpty(state.verified) ?
    verified = [{...removeNotFound,username,scanuserid,location,when}] :
    verified = [...state.verified,{...removeNotFound,username,scanuserid,location,when}];

  return {...state,notFound,verified}
}

function receiveAuditNoteSet(state, action) {
  let assetid = _.get(action,'assetid');
  let note = _.get(action,'note');
  if(!assetid && !note) {
    assetid = _.get(action,'data.assetid');
    note = _.get(action,'data.note');
  }
  let verified = state.verified;
  let isVerified = _.find(verified,{assetid});
  if(isVerified) {
    let index = _.findIndex(verified,{assetid});
    verified[index].notes = note;
     return {...state,verified};
  }
  let notFound = state.notFound;
  let isNotFound = _.find(notFound,{assetid});
  if(isNotFound) {
    let index = _.findIndex(notFound,{assetid});
    notFound[index].notes = note;
    return {...state,notFound};
  }
  return state;
}

function recieveAuditUploadFile(state, action) {
  let sources = _.get(action,'data.sources');
  return {...state,sources};
}
