import {cloneDeep,mapKeys, map} from 'lodash';
import moment from 'moment';
let tags = [
  [null, 2, 3, null, 5, null, 7, null, null, null, null, 12,null,null,null,null,null,null,null,null,null,null,null,null],
  [null, 2, 3, null, 5, null, 7, null, null, null, null, 12,null,null,null,null,null,null,null,null,null,null,null,null],
  [null, 2, 3, null, 5, null, 7, null, null, null, null, 12,null,null,null,null,null,null,null,null,null,null,null,null],
  [null, 2, 3, null, 5, null, 7, null, null, null, null, 12,null,null,null,null,null,null,null,null,null,null,null,null],
  [null, 2, 3, null, 5, null, 7, null, null, null, null, 12,null,null,null,null,null,null,null,null,null,null,null,null],
];
const initialState = {
  drawer: null,
  cabinet: null,
  drawers: {},
  //tags: normalizeTags(tags,{drawer:0,cabinet:0}),
  tags: {},
  programTags: {},
  doingRefresh: false,
  doingProgram: false,
  programMode: 'stop',
  duplicateCheck: false,
  kioskDrawerBuildTimer: false,
  duplicateRefreshQueue: {},
  duplicateRefreshDone: {},
  duplicateList: {},  // { [tagid]: [{slot}, {slot}, {slot}]
};

export default function tagProgram(state = initialState, action) {
  switch (action.type) {
    case 'RECEIVE_KIOSKDETAILS':
      return receiveKioskDetail(state,action);
    case 'TAG_REFRESH':
      return TagRefresh(state, action);
    case 'TAG_PROGRAM':
      return TagProgram(state, action);
    case 'TAG_PROGRAM_CANCEL':
      return TagProgramCancel(state, action);
    case 'TAG_DUPLICATE_CHECK_TOGGLE':
      return tagDuplicateCheckToggle(state, action);
    case 'RECEIVE_TAGREFRESH':
      return recieveTagRefresh(state, action);
    case 'RECEIVE_TAGPROGRAM':
      return recieveTagProgram(state, action);
    case 'HANDLE_LOGOUT':
      return cloneDeep(initialState);
    default:
      return state;
  }
}

function receiveKioskDetail(state,action) {
  let {kioskid,drawerlayout} = action.data;
  let drawers = drawerlayout.reduce((obj,drawer)=> {
    if(drawer.securityTier !== 0) {
      let drawerLowerCase = mapKeys(drawer,(v,k)=>k.toLowerCase());
      obj[drawerLowerCase.drawerid] = drawerLowerCase
    }
    return obj;
  },{});

  // build duplicate check queue and results
  const duplicateRefreshQueue = {...state.duplicateRefreshQueue};
  let drawerkeys = Object.keys(drawers);
  if( state.duplicateCheck && drawerkeys.length > 0 ) {
    const drawers_copy = {...drawers};
    const drawer = drawers[drawerkeys[0]].drawerid;
    delete drawers_copy[drawerkeys[0]];
    duplicateRefreshQueue[kioskid] = {drawers: drawers_copy, lastRefresh: moment()};
    window.socket.send({ command: "tagrefresh", kioskid, drawer });
  }

  return {...state,
    drawers: {...state.drawers,[kioskid]:drawers},
    duplicateRefreshQueue,
  };
}

function TagRefresh(state,action) {
  let {cabinet,drawer} = action.data;
  return {...state,drawer,cabinet, doingRefresh: true }
}

function set_program_state( tags, t, program_state ) {
    if( !( t.d in tags ) )
        tags[t.d] = {};
    if( !( t.r in tags[t.d] ) )
        tags[t.d][t.r] = {};
    if( !( t.s in tags[t.d][t.r] ) )
        tags[t.d][t.r][t.s] = {};

    tags[t.d][t.r][t.s].program_state = program_state;
    if( t.v )
        tags[t.d][t.r][t.s].target_value = t.v;
}

function TagProgram( state, action ) {
    const { tags: programTags, mode: programMode, sent_tag } = action.data;

    if( programMode == 'all' || programMode == 'fix' ) {
        let tags = { ...state.tags };
        for( let t of programTags )
            set_program_state( tags, t, 'waiting' );

        tags[sent_tag.d][sent_tag.r][sent_tag.s].program_state = 'writing';

        return { ...state, programMode, programTags, doingProgram: true, tags };
    }

    return state;  // no change if no mode
}

function TagProgramCancel( state, action ) {
    return { ...state, programMode: 'stop', doingProgram: false, };
}

function recieveTagRefresh(state,action) {
  if( state.duplicateCheck ) {
    const { tags, drawer, kioskid } = action.data;
    const duplicateList = {...state.duplicateList};
    const duplicateRefreshDone = {...state.duplicateRefreshDone,
      [`${kioskid}_${drawer}`]: 1 };
    const duplicateRefreshQueue = {...state.duplicateRefreshQueue};

    for( let row in tags ) {
      for( let slot in tags[row] ) {
        const tagid = tags[row][slot];
        if( tagid && tagid != "null" ) {
          if( !(tagid in duplicateList) ) {
            duplicateList[tagid] = [];
          }
          duplicateList[tagid].push({ kioskid, drawer, row, slot });
        }
      }
    }

    console.log('duplicateRefreshQueue', duplicateRefreshQueue);
    console.log('kioskid', kioskid);
    const drawers = {...(duplicateRefreshQueue[kioskid].drawers)};
    const drawerkeys = Object.keys(drawers);
    if( drawerkeys.length > 0 ) {
      const drawer = drawers[drawerkeys[0]].drawerid;
      delete drawers[drawerkeys[0]];
      duplicateRefreshQueue[kioskid] = {drawers, lastRefresh: moment()};
      window.socket.send({ command: "tagrefresh", kioskid, drawer });
    }

    return {...state, duplicateList, duplicateRefreshDone, duplicateRefreshQueue };
  } else {
    let {tags} = state;
    let normailTags = normalizeTags(action.data.tags,state);
    return {...state,tags:{...tags,...normailTags}, doingRefresh: false, };
  }
}

function recieveTagProgram(state,action) {
  let {d,r,s,v} = action.data;
  if( !state.programMode ) {
    let {tags} = state;
    tags[d][r][s] = {...tags[d][r][s],value:v};
    delete tags[d][r][s].pending;
    return {...state,tags:{...tags}};
  } else {
    let tags = { ...state.tags };
    tags[d][r][s] = { ...tags[d][r][s] };
    if( v ) {
      tags[d][r][s].value = v;
      if( v == tags[d][r][s].target_value ) {
        tags[d][r][s].program_state = 'written';
      } else {
        tags[d][r][s].program_state = 'incorrect';
      }
    } else {
      tags[d][r][s].program_state = 'failed';
    }

    let kioskid = null;
    let programTags = [];
    for( let i = 0; i < state.programTags.length; ++i ) {
        let t = state.programTags[i];
        if( t.d != d || t.r != r || t.s != s )
            programTags.push( t );
        else
            kioskid = t.k;
    }

    if( programTags.length > 0 ) {
        let t = programTags[0];
        set_program_state( tags, programTags[0], 'writing' );
        window.socket.send({ command: "tagprogram", ...programTags[0] });
        return { ...state, tags, programTags, doingProgram: true };
    }

    return { ...state, tags, programTags, doingProgram: false };
  }
}

function normalizeTags (tags,{drawer,cabinet}) {
  return tags.reduce((obj,values,row) =>{
    if(!obj[drawer]) {
      obj[drawer] = {}
    }
    for(let s in values) {
      let slot = Number(s);
      if(!obj[drawer][row]) {
        obj[drawer][row] = {}
      }
      obj[drawer][row][slot] = {value:values[slot],slot,row,drawer,cabinet};
    }
    return obj;
  },{});
}

function tagDuplicateCheckToggle(state, action) {
  if( state.kioskDrawerBuildTimer ) clearInterval( state.kioskDrawerBuildTimer );
  if( state.duplicateCheck ) {
    return {...state,
      kioskDrawerBuildTimer: false,
      duplicateCheck: false,
      duplicateRefreshQueue: {},
    };
  } else {
    let remaining = map( action.data.kiosks, 'kioskID' );
    let kioskDrawerBuildTimer = setInterval( () => {
      window.socket.send({ command: "kioskdetails", kioskid: remaining[0] });
      remaining.shift();
      if( remaining.length <= 0 ) {
        clearInterval( kioskDrawerBuildTimer );
      }
    }, 1000 );
    return {...state,
      kioskDrawerBuildTimer,
      duplicateCheck: true,
      duplicateRefreshQueue: {},
      duplicateRefreshDone: {},
      duplicateList: {},
    };
  }
}
