const initialState = 'pending';

export default function connectionStatus(state = initialState, action) {
  switch (action.type) {
    case 'OPEN_SOCKET':
      return 'opened';
    case 'CLOSE_SOCKET':
      return 'closed';
    default:
      return state;
  }
}
