import {cloneDeep} from 'lodash';

const initialState = {
  data: {
    nodes:[{ id: 'select item' }],
    links: [],
    focusedNodeId: "nodeIdToTriggerZoomAnimation",
    enableFocusAnimation:true,
    // nodes: [
    //   { id: 'Serial # 12345' },
    //   { id: 'Serial # 67890' },
    //   { id: 'salestouch # 12345' },
    //   { id: 'cameraarchiver # 12345' },
    //   { id: 'drawerserver # 12345' },
    //   { id: 'security # 12345'},
    //   { id: 'salestouch # 67890' },
    //   { id: 'cameraarchiver # 67890' },
    //   { id: 'drawerserver # 67890' },
    //   { id: 'security # 67890' }
    //   ],
    // links: [
    //   { source: 'Serial # 12345', target: 'salestouch # 12345' },
    //   { source: 'Serial # 12345', target: 'cameraarchiver # 12345' },
    //   { source: 'Serial # 12345', target: 'drawerserver # 12345' },
    //   { source: 'Serial # 12345', target: 'security # 12345' },
    //   { source: 'Serial # 67890', target: 'cameraarchiver # 67890' },
    //   { source: 'Serial # 67890', target: 'drawerserver # 67890' },
    //   { source: 'Serial # 67890', target: 'salestouch # 67890' },
    //   { source: 'Serial # 67890', target: 'security # 67890' },
    // { source: 'Serial # 67890', target: 'security' },
    // { source: 'Serial # 12345', target: 'security' },
    // ]
  },
  inventory: {}
};

export default function assemblyinventory(state = initialState, action) {
  switch (action.type) {
    case 'RECEIVE_ASSEMBLYITEMDETAILGET':
      return receiveAssemblyItemDetailGet(state,action);
    case 'HANDLE_LOGOUT':
      return cloneDeep(initialState);
    default:
      return state;
  }
}

function receiveAssemblyItemDetailGet(state,action) {
  console.log('receive assembly inventory',action.data);
  let inventory = action.data.list;
  let data = inventoryNormalizer(action.data.list);
  return {...state,inventory,data}
}

function inventoryNormalizer (data) {
  let {item,description,parents,children} = data;
  let id = `${item} ${description}`;
  let parentItems = parents.reduce((obj,v)=> {
    let node = { id:`${v.item} ${v.description}`, ...v} ;
    let link = { source: `parent ${id}`, target: node.id };
    if(!obj.nodes) {
      obj.nodes = [];
      obj.links = [];
    }
    obj.nodes.push(node);
    obj.links.push(link);
    return obj;
  },{});

  let childItems = children.reduce((obj,v)=> {
    let node = { id:`${v.item} ${v.description}`, ...v} ;
    let link = { source: `child ${id}`, target: node.id };
    if(!obj.nodes) {
      obj.nodes = [];
      obj.links = [];
    }
    obj.nodes.push(node);
    obj.links.push(link);
    return obj;
  },{});
  let parentNode = {id:`parent ${id}`};
  let parentLink = {source:parentNode.id,target:id};
  let childNode = {id:`child ${id}`};
  let childLink = {source:childNode.id,target:id};
  let nodes = [{id,item,description},parentNode,childNode,...parentItems.nodes,...childItems.nodes];
  let links = [...parentItems.links,parentLink,childLink,...childItems.links];
  return {nodes,links,focusedNodeId: "nodeIdToTriggerZoomAnimation",enableFocusAnimation:true};
}
