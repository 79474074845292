import {cloneDeep,map,get,concat,filter,assign,each,findIndex,reduce,head,find,toNumber,isString,toString} from 'lodash';
import {FETCH_HIERARCHY, SWITCH_CONTAINERS} from './../actions/containers';

const initialState = {
  currentContainer: localStorage.getItem( 'currentContainer' ),
  containerroot: '',
  containers: [],
  permissiontuples: [],
  status: 'unfetched'
};

export default function containers(state = initialState, action) {
  switch (action.type) {
    case SWITCH_CONTAINERS:
      return {
        ...state,
        currentContainer: action.containerID
      };
    case 'RECEIVE_PERMISSIONS':
      let storedContainer = localStorage.getItem('currentContainer');
      let rootContainerid = isString(action.data.rootContainerID) ?
        action.data.rootContainerID : toString(action.data.rootContainerID);
      return {
        ...state,
        containerroot: rootContainerid || state.containerroot,
        // stop resetting currentContainer to root container when socket resets
        currentContainer: state.currentContainer !== '' ? state.currentContainer : rootContainerid || storedContainer ,
        // currentContainer: rootContainerid || storedContainer ,
      };
    case FETCH_HIERARCHY:
      return {
        ...state,
        status: 'pending'
      };
    case 'RECEIVE_HIERARCHYGET':
      return hierarchyGet(state,action);
    case 'RECEIVE_HIERARCHYGETPERMISSIONS':
      let { permissiontuples } = state;

      let newPermissiontuples = map(action.data.permissiontuples, permissiontuple => {
        return {
          permissions: map(permissiontuple.permissions, permission => permission),
          ...permissiontuple
        }
      });

      for(let permission of newPermissiontuples) {
        let {containerID,groupID} = permission;
        let exist = find(permissiontuples,{containerID,groupID});
        if(!exist) {
          permissiontuples.push(permission);
        }
      }

      return {
        ...state,
        permissiontuples: [...permissiontuples]
      };
    case 'RECEIVE_CONTAINERADD':
      state.containers = [normalizeContainer(action.data),...state.containers ];
      // state.containers = concat(state.containers, normalizeContainer(action.data));
      return {
        ...state
      };

    case 'RECEIVE_CONTAINERREMOVE':
      let removeContainerID = normalizeContainer(action.data).containerID;
      state.containers = filter(state.containers, (container) => {
        return container.containerID !== removeContainerID;
      });
      return {
        ...state
      };

    case 'RECEIVE_CONTAINERNAMESET':
      let updatedContainer = normalizeContainer(action.data);
      let updatedContainerID = updatedContainer.containerID;
      let updatedContainerName = updatedContainer.containerName;
      state.containers = map(state.containers, (container) => {
        if (container.containerID === updatedContainerID) {
          container = assign({}, container, { containerName: updatedContainerName });
        }
        return container;
      });
      return {
        ...state
      };
    case 'RECEIVE_CONTAINERSETCONTAINERROOT':
      return receiveContainerSetContainerRoot(state, action);
    case 'RECEIVE_KIOSKRENAME':
      return receiveKioskRename(state, action);
    case 'RECEIVE_PERMISSIONASSIGN':
      return receivePermissionAssign(state, action);

    case 'RECEIVE_TRANSACTIONREASONSNAMESET':
      return receiveTransactionReasonsNameSet(state, action);

    case 'RECEIVE_TRANSACTIONREASONSGET':
      let containerID = get(action, 'data.reasons[0].company');
      state.containers = map(state.containers, (container) => {
        if (container.containerID === containerID) {
          container = assign({}, container, { reasons: action.data.reasons });
        }
        return container;
      });
      return {
        ...state
      };
    case 'RECEIVE_DRAWERTIERNAMESGET':
      return receiveDrawerTierNamesGet(state,action);
    case 'RECEIVE_DRAWERTIERCHANGE':
      return receiveDrawerTierChangeName(state,action);
    case 'HANDLE_LOGOUT':
      return cloneDeep(initialState);
    default:
      return state;
  }
}

function receiveDrawerTierNamesGet(state, action) {
  let drawers = get(action,'data.drawers');
  return {
    ...state,
    drawers
  }
}

function receiveDrawerTierChangeName(state, action) {
  let drawers = get(action,'data.drawers');
  return {
    ...state,
    drawers
  }
}

function receiveKioskRename(state, action) {
  let next = { ...state };
  if( action && action.data ) {
    each( next.containers, ( container ) => {
      each( container.children, ( child ) => {
        if( toNumber(child.kioskID) === toNumber(action.data.kiosk) ) {
          child.kioskName = action.data.name;
        }
      } );
    } );
  }
  return next;
}

function receiveContainerSetContainerRoot(state, action) {
  let {data}= action;
  let {root,companyID,containerID,containerName} = data;
  let {containers} = state;
  let index = findIndex(containers,{companyID,containerID,containerName});
  let selectedContainer = get(containers,index);
  containers[index] = {...selectedContainer,root};
  return {
    ...state,
    containers: [...containers],
  };
}

function receiveTransactionReasonsNameSet(state, action) {
  let containerID = get(action, 'data.reasons[0].company');
  state.containers = map(state.containers, (container) => {
    if (container.containerID === containerID) {
      let oldReasons = get(container, 'reasons', []);
      let mergedReasons = reduce(action.data.reasons, (memo, updatedReason) => {
        let foundIndex = findIndex(memo, (oldReason) => { return oldReason.id === updatedReason.id});
        if (foundIndex) {
          memo[foundIndex] = updatedReason;
        }
        return memo;
      }, oldReasons);
      container = assign({}, container, { reasons: mergedReasons });
    }
    return container;
  });
  return {
    ...state
  };
}

// There are a few scenarios here:
// 1: Removing all the permissions from a permissionTuple (thus, deleting the permission tuple )
// 2: Adding a new permission tuple
// 3: Updating an existing permission tuple
function receivePermissionAssign(state, action) {
  let newPermissionTuple = normarlizePermissionTuple(action.data);

  // 1: Removing all permissions means we should remove the permission tuple
  if (newPermissionTuple.permissions.length === 0) {
    state.permissiontuples = filter(state.permissiontuples, (permissiontuple) => {
      return !isSamePermissionTuple(permissiontuple, newPermissionTuple);
    });
  } else {

    // 2: If we find an existing permission tuple, update it
    let found = find(state.permissiontuples, (permissiontuple) => {
      return isSamePermissionTuple(permissiontuple, newPermissionTuple);
    });
    if (found) {
      state.permissiontuples = map(state.permissiontuples, (permissiontuple) => {
        if (permissiontuple.containerID === newPermissionTuple.containerID && permissiontuple.groupID === newPermissionTuple.groupID) {
          permissiontuple.permissions = map(action.data.permissions, (permission) => {return `${permission}`}); // Turn it into a string
        }
        return cloneDeep(permissiontuple);
      });
    } else {
      // 3: We have a new permission tuple, add it
      state.permissiontuples = concat(state.permissiontuples, newPermissionTuple);
    }
  }
  return {
    ...state
  }
}

function isSamePermissionTuple(a, b) {
  return a.containerID === b.containerID && a.groupID === b.groupID;
}

function normarlizePermissionTuple(permissionTuple) {
  return {
    containerID: permissionTuple.containerID || `${permissionTuple.containerid}`,
    groupID: permissionTuple.groupID || `${permissionTuple.groupid}`,
    permissions: map(permissionTuple.permissions, (permission) => {return `${permission}`}) // Turn it into a string
  }
}

// We have to map the newly created container to what we expect a container to look like
// (and what they actually do look like when we receive the list of containers)
function normalizeContainer({containername,containerid,parentcontainer,companyid,root}) {
  return {
    containerName: containername,
    containerID: containerid ? containerid.toString() : containerid,
    parentContainer: parentcontainer ? parentcontainer.toString() : parentcontainer,
    companyID: companyid ? companyid.toString() : companyid,
    root: root ? root.toString() : root
  }
}

function hierarchyGet(state,action) {
  let hieryarchy = action.data.hierarchy;
  let containerroot = hieryarchy.containerroot;
  let containers = hieryarchy.containers;
  let storedContainer = localStorage.getItem('currentContainer');
  let index = findIndex(containers, {containerID:storedContainer});

  // localstore containerid on page load
  if(index !== -1) {
    // localstore containerid if container exist
    let container = containers[index];
    if(container) {
      let {containerID} = container;
      if(storedContainer !== containerID ) {
        if(containerID) {
          localStorage.setItem('currentContainer', containerID);
        } else {
          localStorage.setItem('currentContainer', containerroot);
        }
      }
    }
  } else {
    // reset localstore containerid if does not match localstore
    if(storedContainer !== containerroot ) {
      if(containerroot) {
        localStorage.setItem('currentContainer', containerroot);
      }
    }
  }
  return {
    ...state,
    currentContainer: containerroot || state.currentContainer,
    containerroot,
    containers,
    permissiontuples: map(hieryarchy.permissiontuples, (permissiontuple) => {
      return {
        permissions: map(permissiontuple.permissions, (permission) => permission),
        ...permissiontuple
      }
    }),
    status: 'fetched'
  };
}
