import React, { Component } from 'react';
import { Navbar, Nav, NavbarText } from 'reactstrap';
import moment from 'moment';
import './Footer.css';

class Footer extends Component {
  render() {
    return (
      <Navbar className="Footer" fixed="bottom" color="inverse">
        <Nav className="ml-auto">
          <span style={{paddingRight:'2em'}} className="navbar-text">
            U.S. Patent No. 10,665,047
          </span>
          <span style={{paddingRight:'2em'}} className="navbar-text">
            version: {this.props.version}
          </span>
          <span className="navbar-text">
            &copy; {moment().format('YYYY')} 1Micro, LLC
          </span>
        </Nav>
      </Navbar>
    )
  }
}

export default Footer;
