import geodist from "geodist";
import {drop, each, get, head, last, round, size, take, takeRight, toNumber,map,unset} from "lodash";
import moment from "moment/moment";

export const calculateLocation = (locations,inTime,outTime) => {
  let totalDistance = 0;
  let totalTimeStampByseconds = 0;
  let hour = 0;
  let minute = 0;
  let minuteTimeStamp = 0;
  let diffInMinutes = 0;
  let diffInHours = 0;
  let diffInSeconds = 0;

  each(locations,(location,index)=>{
    let locationDistance = take(drop(locations,index),2);
    if(size(locationDistance) === 1) {
      locationDistance =  takeRight(locations, 2);
    }
    let location1 = head(locationDistance);
    let timeStamp1 = get(location1,'date');
    let location2 = last(locationDistance);
    let timeStamp2 = get(location2,'date');
    let seconds = moment(timeStamp2).diff(moment(timeStamp1), 'seconds');
    let minutes = moment(timeStamp2).diff(moment(timeStamp1), 'minutes');
    totalDistance += geodist(location1, location2,{exact: true, unit: 'feet'});
    // If location time doesn't exist do not add
    if(timeStamp1 && timeStamp2) {
      totalTimeStampByseconds += seconds;
      minuteTimeStamp += minutes;
    }
  });

  let bogusDurationTime = false;
  // some location points do not have timestamp so this will determine how to calculate speed by
  if(totalTimeStampByseconds !== 0) {
    // Calculate speed by location points
    if(60 <= minuteTimeStamp) {
      hour = minuteTimeStamp / 60;
    }
    if(60 <= totalTimeStampByseconds) {
      minute = totalTimeStampByseconds / 60 ;
    }

    let startTime = get(head(locations),'date');
    let endTime = get(last(locations),'date');
    diffInMinutes = moment(endTime).diff(moment(startTime), 'minutes');
    diffInHours = moment(endTime).diff(moment(startTime), 'hours');
    diffInSeconds = moment(endTime).diff(moment(startTime), 'seconds');
  } else {
    // Calculate speed by in and out time
    let startTime = moment(outTime);
    let endTime = moment(inTime);
    totalTimeStampByseconds = toNumber(moment.utc(moment(endTime).diff(moment(startTime))).format("ss"));
    diffInMinutes = moment(endTime).diff(moment(startTime), 'minutes');
    diffInHours = moment(endTime).diff(moment(startTime), 'hours');
    diffInSeconds = moment(endTime).diff(moment(startTime), 'seconds');
    bogusDurationTime = true;
  }
  let d = round( ( totalDistance / 5280 ) / ( totalTimeStampByseconds / 3600 ) );
  let distance = isNaN(d) ? 0 : isFinite(d) ? d:'Too Fast';

  if( totalTimeStampByseconds === 0 ) {
    distance = 'n/a';
  } else {
    distance += ' mph';
  }
  // if(bogusDurationTime) {
  //   hour = parseInt(totalTimeStampByseconds / 3600) % 24;
  //   minute = parseInt(totalTimeStampByseconds / 60) % 60;
  //   totalTimeStampByseconds = totalTimeStampByseconds % 60;
  // } else {
  hour = diffInHours;
  minute = diffInMinutes;
  totalTimeStampByseconds = diffInSeconds;
  // }
  return {
    distance,
    hour,
    minute,
    totalTimeStampByseconds,
    bogusDurationTime
  }
};

const dur = (hour,minute,second) => {
  if(hour >= 1) {
    return `${round(hour)} hour`;
  } else if(minute >= 1) {
    return `${round(minute)} minute`;
  } else if( !isNaN(second) && isFinite(second) ){
    return `${second} second gps`;
  }
};

const dist = (totalDistance) => {
  if (5280 <= totalDistance){
    return `${round(totalDistance / 5280)} miles`;
  } else if(round(totalDistance/ 5280,2) !== 0) {
    return `${round(totalDistance/ 5280,2)} miles`;
  } else {
    return `${round(totalDistance/ 5280)} feet`;
  }
};

const startTime = (trip,time) => {
  let startTime = get(head(trip),'date');
  if(startTime) {
    return moment(startTime).format('Y-MM-DD hh:mm:ss a');
  } else {
    if(time) {
      let inTime = moment(time);
      return (
        inTime.format('Y-MM-DD hh:mm:ss a')
      )
    } else {
      return '';
    }
  }
};

const endTime = (trip,time) => {
  if(size(trip) <= 3) {
    return 'scan';
  }
  let endTime = get(last(trip),'date');
  if(endTime) {
    return moment(endTime).format('Y-MM-DD hh:mm:ss a');
  } else {
    if(time) {
      let inTime = moment(time);
      return (
        inTime.format('Y-MM-DD hh:mm:ss a')
      )
    } else {
      return '';
    }
  }
};

export const buildTripTable = triphistory =>
  map(triphistory,t => {
  let { trip: locations, inTime, outTime } = t;
  let {
    distance,
    hour,
    minute,
    totalTimeStampByseconds,
    bogusDurationTime
  } = calculateLocation(locations,inTime,outTime);

  let averagespeed = size(locations) <= 3 ? "" : distance;

  let totalDistance = 0;
  each(locations,(location,index)=>{
    let locationDistance = take(drop(locations,index),2);
    if(size(locationDistance) === 1) {
      locationDistance =  takeRight(locations, 2);
    }
    let location1 = head(locationDistance);
    let location2 = last(locationDistance);
    totalDistance +=  geodist(location1, location2,{exact: true, unit: 'feet'});
  });

  distance = dist(totalDistance);

  let second = round(totalTimeStampByseconds);

  if (bogusDurationTime) {
    minute = moment(inTime).diff(moment(outTime), 'minutes');
    hour = moment(inTime).diff(moment(outTime), 'hours');
    second = moment(inTime).diff(moment(outTime), 'seconds');
  }

  let duration = dur(hour,minute,second);
  let starttime = startTime(locations,outTime);
  let endtime = endTime(locations,outTime);

  unset(t,'trip');
  return {...t,averagespeed,distance,starttime,endtime,duration};
});
