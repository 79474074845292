import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './configureStore';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'core-js/fn/array/find';
import 'core-js/fn/array/includes';
import 'core-js/fn/string/includes';
import 'core-js/fn/number/is-nan';
import 'core-js/fn/string/pad-start';
import './index.css';
import './dist/toolkit-light.css';
import './dist/toolkit-light.css.map';
import 'react-table/react-table.css';
import App from './App';
import ErrorBoundary from './components/ErrorBoundary';

// Lazy Loading Componnet
const About = lazy(() => import('./About/About'));
const AssemblyInventory = lazy(() => import('./AssemblyInventory/AssemblyInventory'));
const DashboardIndex = lazy(() => import('./Dashboard/DashboardIndex'));
const AssetDashboard = lazy(() => import('./AssetDashboard/AssetDashboard'));
const Bins = lazy(() => import('./Bins/Bins'));
const BinsDetails = lazy(() => import('./Bins/BinsDetails'));
const ContainersIndex = lazy(() => import('./Containers/ContainersIndex'));
const CustomerIndex = lazy(() => import('./Customer/CustomerIndex'));
const CustomerEvents = lazy(() => import('./Customer/CustomerEvents'));
const CustomerDetail = lazy(() => import('./Customer/CustomerDetail'));
const CustomerUnverifiedDetail = lazy(() => import('./Customer/CustomerUnverifiedDetail'));
const CustomerUnverifiedTable = lazy(() => import('./Customer/CustomerUnverifiedTable'));
const CreditCardIndex = lazy(() => import('./CreditCard/CreditCardIndex'));
const GroupsIndex = lazy(() => import('./Groups/GroupsIndex'));
const AllGroups = lazy(() => import('./Groups/AllGroups'));
const Group = lazy(() => import('./Groups/Group'));
const UsersIndex = lazy(() => import('./Users/UsersIndex'));
const AllUsers = lazy(() => import('./Users/AllUsers'));
const User = lazy(() => import('./Users/User'));
const NewUser = lazy(() => import('./Users/NewUser/NewUser'));
const NewKiosIndex = lazy(() => import('./NewKiosk/NewKioskIndex'));
const AdminErrorLog = lazy(() => import('./AdminErrorLog/AdminErrorLogIndex'));
const AssetsIndex = lazy(() => import('./Assets/AssetsIndex'));
const AllAssets = lazy(() => import('./Assets/AllAssets'));
const Asset = lazy(() => import('./Assets/Asset'));
const AssetHistory = lazy(() => import('./Assets/AssetHistory'));
const AssetEventHistory = lazy(() => import('./Assets/AssetEventHistory'));
const BookInIndex = lazy(() => import('./BookIn/BookInIndex'));
const BookInSingle = lazy(() => import('./BookIn/BookInSingle'));
const DealersIndex = lazy(() => import('./Dealers/DealersIndex'));
const DocumentIndex = lazy(() => import('./Document/DocumentIndex'));
const DocumentCanvasPDF = lazy(() => import('./Document/DocumentCanvasPDF'));
const AuditLogIndex = lazy(() => import('./Audits/AuditLogIndex'));
const ReportScheduleAdd = lazy(() => import('./ScheduleAlert/ReportScheduleAdd'));
const CompanyAlertGroup = lazy(() => import('./ScheduleAlert/CompanyAlertGroup'));
const EmployeeKeyActivity = lazy(() => import('./Reports/EmployeeKeyActivity'));
const EmployeeKeyActivityV2 = lazy(() => import('./Reports/EmployeeKeyActivityV2'));
const KeysExtendedCheckOut = lazy(() => import('./Reports/KeysExtendedCheckOut'));
const KeysCheckedOut = lazy(() => import('./Reports/KeysCheckedOut'));
const KeyLocations = lazy(() => import('./Reports/KeyLocations'));
const InStockInventory = lazy(() => import('./Reports/InStockInventory'));
const FtpInterface = lazy(()=> import('./Reports/FtpInterface'));
const AccerlerometerActivityIndex = lazy(() => import('./iOptix/AccerlerometerActivityIndex'));
const ISafeWatchReport = lazy(()=>import('./iOptix/ISafeWatchIndex'));
const LostTags = lazy(() => import('./Reports/LostTags'));
const SoldAssetReport = lazy(() => import('./Reports/SoldAssetReport'));
const NotSoldNoTagAssets = lazy(() => import('./Reports/AssetAging'));
const SecurityEventIndex = lazy(() => import('./SecurityEvent/SecurityEventIndex'));
const ServiceIndex = lazy(() => import('./Service/ServiceIndex'));
const ServiceReport = lazy(() => import('./Service/ServiceReport'));
const ServiceTypes = lazy(() => import('./Service/ServiceTypes'));
const LotLocations = lazy(() => import('./LotLocations/LotLocations'));
const DrawerLayout = lazy(() => import('./Reports/DrawerLayout'));
const TagsNotHome = lazy(() => import('./Reports/TagsNotHome'));
const TestDriveIndex = lazy(() => import('./TestDrive/TestDriveIndex'));
const TestDriverInfo = lazy(() => import('./TestDrive/TestDriverInfo'));
const Timesheets = lazy(() => import('./Reports/Timesheets/Timesheets'));
const TwoFactorAuthenication = lazy(() => import('./TwoFactorAuthenication/TwoFactorAuthenication'));
const TimesheetIndex = lazy(() => import('./Reports/Timesheets2/TimesheetIndex'));
const TrackingTestDrive = lazy(() => import('./TrackingTestDrive/TrackingTestDriveIndex'));
const TrackingTestDriveHistory = lazy(() => import('./TrackingTestDrive/TrackingTestDriveHistory'));
const SupportVideos = lazy(() => import('./Support/SupportVideos'));
const SupportContact = lazy(() => import('./Support/SupportContact'));
const ValeTouchReport = lazy(() => import('./ValeTouch/ValeTouchReport'));
const KioskVersionReport = lazy(() => import('./Reports/KioskVersions'));
const AssetsNotAssign = lazy(() => import('./Reports/AssetsNotAssigned'));
const SettingIndex = lazy(() => import('./Setting/SettingIndex'));
const SoloCreateIndex = lazy(() => import('./Solo/SoloCreateIndex'));
const SoloViewIndex = lazy(() => import('./Solo/SoloViewIndex'));
const CompanyInfo = lazy(() => import('./CompanyInfo/CompanyInfo'));
const DuplicateReport = lazy(() => import('./Reports/DuplicateReport'));

ReactDOM.render(
  <Provider store={store}>
    <Router>
        <App>
          <Suspense fallback={<div>Loading...</div>}>
            <Switch>
              {/*    group  */}
              <Route path="/roles">
                <ErrorBoundary
                  componentName={'Route/roles GroupsIndex'}
                >
                  <GroupsIndex>
                    <ErrorBoundary
                      componentName={'Route/roles Group'}
                    >
                      <Route exact path="/roles/:groupID" render={(props) => <Group {...props}/>}/>
                    </ErrorBoundary>
                    <ErrorBoundary
                      componentName={'Route/roles AllGroups'}
                    >
                      <Route exact path="/roles" render={(props) => <AllGroups {...props}/>}/>
                    </ErrorBoundary>
                  </GroupsIndex>
                </ErrorBoundary>
              </Route>
              {/*    user    */}
              <Route exact path="/users/new">
                <ErrorBoundary
                  componentName={'Route/users/new GroupsIndex'}
                >
                  <GroupsIndex>
                    <ErrorBoundary
                      componentName={'NewUser'}
                    >
                      <Route exact path="/users/new" render={(props) => <NewUser {...props}/>}/>
                    </ErrorBoundary>
                  </GroupsIndex>
                </ErrorBoundary>
              </Route>
              <Route path="/users" components={UsersIndex}>
                <ErrorBoundary
                  componentName={'Route/users GroupsIndex'}
                >
                  <GroupsIndex>
                    <ErrorBoundary
                      componentName={'Route/users User'}
                    >
                      <Route path="/users/:userID" render={(props) => <User {...props}/>}/>
                    </ErrorBoundary>
                    <ErrorBoundary
                      componentName={'Route/users AllUsers'}
                    >
                      <Route exact path="/users/" render={(props) => <AllUsers {...props}/>}/>
                    </ErrorBoundary>
                  </GroupsIndex>
                </ErrorBoundary>
              </Route>
              {/*   Assets   */}
              <Route path="/assets" render={(props) => <AssetsIndex {...props}/>}>
                <ErrorBoundary
                  componentName={'Route/assets AssetsIndex'}
                >
                  <Route path="/assets/:assetID" render={(props) => <AssetsIndex {...props}/>}>
                    <ErrorBoundary
                      componentName={'Route/assets Asset'}
                    >
                      <Route exact path="/assets/:assetID/" render={(props) => <Asset {...props}/>}/>
                    </ErrorBoundary>
                    <ErrorBoundary
                      componentName={'Route/assets AssetHistory'}
                    >
                      <Route exact path="/assets/:assetID/tag-history" render={(props) => <AssetHistory {...props}/>}/>
                    </ErrorBoundary>
                    <ErrorBoundary
                      componentName={'Route/assets AssetEventHistory'}
                    >
                      <Route exact path="/assets/:assetID/event-history" render={(props) => <AssetEventHistory {...props}/>}/>
                    </ErrorBoundary>
                  </Route>
                </ErrorBoundary>
                <ErrorBoundary
                  componentName={'Route/assets AllAssets'}
                >
                  <Route exact path="/assets" render={(props) => <AllAssets {...props}/>}/>
                </ErrorBoundary>
              </Route>

              <Route path="/about" render={(props) =>
                <ErrorBoundary componentName={'About'}>
                  <About {...props}/>
                </ErrorBoundary>}
              />
              <Route path="/asset-dashboard" render={(props) =>
                <ErrorBoundary componentName={'AssetDashboard'}>
                  <AssetDashboard {...props}/>
                </ErrorBoundary>}
              />
              <Route exact path="/twofactorauthenication" render={(props) =>
                <ErrorBoundary componentName={'twofactorauthenication'}>
                  <TwoFactorAuthenication {...props}/>
                </ErrorBoundary>}
              />
              <Route exact path="/tracking-test-drive" render={(props) =>
                <ErrorBoundary componentName={'TrackingTestDrive'}>
                  <TrackingTestDrive {...props}/>
                </ErrorBoundary>}
              />
              <Route exact path="/tracking-test-drive/:assetID/history" render={(props) =>
                <ErrorBoundary componentName={'TrackingTestDriveHistory'}>
                  <TrackingTestDriveHistory {...props}/>
                </ErrorBoundary>}
              />
              <Route path="/audits" render={(props) =>
                <ErrorBoundary componentName={'AuditLogIndex'}>
                  <AuditLogIndex {...props}/>
                </ErrorBoundary>}
              />
              <Route path="/assembly-inventory" render={(props) =>
                <ErrorBoundary componentName={'AssemblyInventory'}>
                  <AssemblyInventory {...props}/>
                </ErrorBoundary>}
              />
              <Route exact path="/bins" render={(props) =>
                <ErrorBoundary componentName={'Bins'}>
                  <Bins {...props}/>
                </ErrorBoundary>}
              />
              <Route exact path="/bins/:uuid" render={(props) =>
                <ErrorBoundary componentName={'BinsDetails'}>
                  <BinsDetails {...props}/>
                </ErrorBoundary>}
              />
              <Route exact path="/optix/accerlerometer-activity" render={(props) =>
                <ErrorBoundary componentName={'AccerlerometerActivityIndex'}>
                  <AccerlerometerActivityIndex {...props}/>
                </ErrorBoundary>}
              />
              <Route exact path="/optix/isafewatch" render={(props) =>
                <ErrorBoundary componentName={'ISafeWatchReport'}>
                  <ISafeWatchReport {...props}/>
                </ErrorBoundary>}
              />
              <Route exact path="/reports/employee-key-activity" render={(props) =>
                <ErrorBoundary componentName={'EmployeeKeyActivity'}>
                  <EmployeeKeyActivity {...props}/>
                </ErrorBoundary>}
              />
              <Route exact path="/reports/employee-key-activity-v-2" render={(props) =>
                <ErrorBoundary componentName={'EmployeeKeyActivityV2'}>
                  <EmployeeKeyActivityV2 {...props}/>
                </ErrorBoundary>}
              />
              <Route exact path="/reports/extended-keys-checked-out" render={(props) =>
                <ErrorBoundary componentName={'KeysExtendedCheckOut'}>
                  <KeysExtendedCheckOut {...props}/>
                </ErrorBoundary>}
              />
              <Route exact path="/reports/keys-checked-out" render={(props) =>
                <ErrorBoundary componentName={'KeysCheckedOut'}>
                  <KeysCheckedOut {...props}/>
                </ErrorBoundary>}
              />
              <Route exact path="/reports/key-locations" render={(props) =>
                <ErrorBoundary componentName={'KeyLocations'}>
                  <KeyLocations {...props}/>
                </ErrorBoundary>}
              />
              <Route exact path="/reports/drawer-layout" render={(props) =>
                <ErrorBoundary componentName={'DrawerLayout'}>
                  <DrawerLayout {...props}/>
                </ErrorBoundary>}
              />
              <Route exact path="/reports/timesheets" render={(props) =>
                <ErrorBoundary componentName={'Timesheets'}>
                  <Timesheets {...props}/>
                </ErrorBoundary>}
              />
              <Route exact path="/reports/timesheets2" render={(props) =>
                <ErrorBoundary componentName={'TimesheetIndex'}>
                  <TimesheetIndex {...props}/>
                </ErrorBoundary>}
              />
              <Route exact path="/reports/lost-tags" render={(props) =>
                <ErrorBoundary componentName={'LostTags'}>
                  <LostTags {...props}/>
                </ErrorBoundary>}
              />
              <Route exact path="/reports/in-stock-inventory" render={(props) =>
                <ErrorBoundary componentName={'InStockInventory'}>
                  <InStockInventory {...props}/>
                </ErrorBoundary>}
              />
              <Route exact path="/reports/sold-assets" render={(props) =>
                <ErrorBoundary componentName={'SoldAssetReport'}>
                  <SoldAssetReport {...props}/>
                </ErrorBoundary>}
              />
              <Route exact path="/reports/asset-aging" render={(props) =>
                <ErrorBoundary componentName={'NotSoldNoTagAssets'}>
                  <NotSoldNoTagAssets {...props}/>
                </ErrorBoundary>}
              />
              <Route exact path="/reports/kiosk-versions" render={(props) =>
                <ErrorBoundary componentName={'KioskVersionReport'}>
                  <KioskVersionReport {...props}/>
                </ErrorBoundary>}
              />
              <Route exact path="/reports/assets-not-assign" render={(props) =>
                <ErrorBoundary componentName={'AssetsNotAssign'}>
                  <AssetsNotAssign {...props}/>
                </ErrorBoundary>}
              />
              <Route exact path="/reports/keys-not-home" render={(props) =>
                <ErrorBoundary componentName={'TagsNotHome'}>
                  <TagsNotHome {...props}/>
                </ErrorBoundary>}
              />
              <Route exact path="/reports/feedback-feed" render={(props) =>
                <ErrorBoundary componentName={'FtpInterface'}>
                  <FtpInterface {...props}/>
                </ErrorBoundary>}
              />
              <Route exact path="/book-in" render={(props) =>
                <ErrorBoundary componentName={'BookInSingle'}>
                  <BookInSingle {...props}/>
                </ErrorBoundary>}
              />
              <Route exact path="/book-in/mass" render={(props) =>
                <ErrorBoundary componentName={'BookInIndex'}>
                  <BookInIndex {...props}/>
                </ErrorBoundary>}
              />
              <Route exact path="/support/contact" render={(props) =>
                <ErrorBoundary componentName={'SupportContact'}>
                  <SupportContact {...props}/>
                </ErrorBoundary>}
              />
              <Route exact path="/support/creditcard" render={(props) =>
                <ErrorBoundary componentName={'CreditCardIndex'}>
                  <CreditCardIndex {...props}/>
                </ErrorBoundary>}
              />
              <Route exact path="/support/videos" render={(props) =>
                <ErrorBoundary componentName={'SupportVideos'}>
                  <SupportVideos {...props}/>
                </ErrorBoundary>}
              />
              <Route exact path="/support/videos/:type" render={(props) =>
                <ErrorBoundary componentName={'SupportVideos'}>
                  <SupportVideos {...props}/>
                </ErrorBoundary>}
              />
              <Route exact path="/support/videos/:type/:videoID" render={(props) =>
                <ErrorBoundary componentName={'SupportVideos'}>
                  <SupportVideos {...props}/>
                </ErrorBoundary>}
              />
              <Route path="/security-event/:kioskID" render={(props) =>
                <ErrorBoundary componentName={'SecurityEventIndex'}>
                  <SecurityEventIndex {...props}/>
                </ErrorBoundary>}
              />
              <Route exact path="/test-drive" render={(props) =>
                <ErrorBoundary componentName={'TestDriveIndex'}>
                  <TestDriveIndex {...props}/>
                </ErrorBoundary>}
              />
              <Route exact path="/test-drive/driver-info/:driverid" render={(props) =>
                <ErrorBoundary componentName={'TestDriverInfo'}>
                  <TestDriverInfo {...props}/>
                </ErrorBoundary>}
              />
              <Route exact path="/schedule-alert" render={(props) =>
                <ErrorBoundary componentName={'ReportScheduleAdd'}>
                  <ReportScheduleAdd {...props}/>
                </ErrorBoundary>}
              />
              <Route exact path="/setting" render={(props) =>
                <ErrorBoundary componentName={'SettingIndex'}>
                  <SettingIndex {...props}/>
                </ErrorBoundary>}
              />
              <Route exact path="/setting/:alerttype" render={(props) =>
                <ErrorBoundary componentName={'CompanyAlertGroup'}>
                  <CompanyAlertGroup {...props}/>
                </ErrorBoundary>}
              />
              <Route exact path="/service/types" render={(props) =>
                <ErrorBoundary componentName={'ServiceTypes'}>
                  <ServiceTypes {...props}/>
                </ErrorBoundary>}
              />
              <Route exact path="/service/dshs" render={(props) =>
                <ErrorBoundary componentName={'ServiceIndex'}>
                  <ServiceIndex {...props}/>
                </ErrorBoundary>}
              />
              <Route exact path="/service/report" render={(props) =>
                <ErrorBoundary componentName={'ServiceReport'}>
                  <ServiceReport {...props}/>
                </ErrorBoundary>}
              />
              <Route exact path="/solo/create" render={(props) =>
                <ErrorBoundary componentName={'SoloCreateIndex'}>
                  <SoloCreateIndex {...props}/>
                </ErrorBoundary>}
              />
              <Route exact path="/solo/view" render={(props) =>
                <ErrorBoundary componentName={'SoloViewIndex'}>
                  <SoloViewIndex {...props}/>
                </ErrorBoundary>}
              />
              <Route path="/valetouch" render={(props) =>
                <ErrorBoundary componentName={'ValeTouchReport'}>
                  <ValeTouchReport {...props}/>
                </ErrorBoundary>}
              />
              <Route path="/containers" render={(props) =>
                <ErrorBoundary componentName={'ContainersIndex'}>
                  <ContainersIndex {...props}/>
                </ErrorBoundary>}
              />
              <Route path="/companyInfo/:companyID" render={(props) =>
                <ErrorBoundary componentName={'CompanyInfo'}>
                  <CompanyInfo {...props}/>
                </ErrorBoundary>}
              />
              <Route exact path="/customer" render={(props) =>
                <ErrorBoundary componentName={'CustomerIndex'}>
                  <CustomerIndex {...props}/>
                </ErrorBoundary>}
              />
              <Route exact path="/customer/events" render={(props) =>
                <ErrorBoundary componentName={'CustomerEvents'}>
                  <CustomerEvents {...props}/>
                </ErrorBoundary>}
              />
              <Route exact path="/customer/unverified-documents" render={(props) =>
                <ErrorBoundary componentName={'CustomerUnverifiedTable'}>
                  <CustomerUnverifiedTable {...props}/>
                </ErrorBoundary>}
              />
              <Route exact path="/customer/detail/:customerid" render={(props) =>
                <ErrorBoundary componentName={'CustomerDetail'}>
                  <CustomerDetail {...props}/>
                </ErrorBoundary>}
              />
              <Route exact path="/customer/unverified-detail/:documentid" render={(props) =>
                <ErrorBoundary componentName={'CustomerUnverifiedDetail'}>
                  <CustomerUnverifiedDetail {...props}/>
                </ErrorBoundary>}
              />
              <Route path="/dealers" render={(props) =>
                <ErrorBoundary componentName={'DealersIndex'}>
                  <DealersIndex {...props}/>
                </ErrorBoundary>}
              />
              <Route exact path="/document" render={(props) =>
                <ErrorBoundary componentName={'DocumentIndex'}>
                  <DocumentIndex {...props}/>
                </ErrorBoundary>}
              />
              <Route exact path="/document/pdf" render={(props) =>
                <ErrorBoundary componentName={'DocumentCanvasPDF'}>
                  <DocumentCanvasPDF {...props}/>
                </ErrorBoundary>}
              />
              <Route path="/lotlocations/:containID" render={(props) =>
                <ErrorBoundary componentName={'LotLocations'}>
                  <LotLocations {...props}/>
                </ErrorBoundary>}
              />
              <Route path="/kiosk/:kioskID" render={(props) =>
                <ErrorBoundary componentName={'NewKiosIndex'}>
                  <NewKiosIndex {...props}/>
                </ErrorBoundary>}
              />
              <Route path="/admin-log-error" render={(props) =>
                <ErrorBoundary componentName={'AdminErrorLog'}>
                  <AdminErrorLog {...props}/>
                </ErrorBoundary>}
              />
              <Route exact path="/" render={(props) =>
                <ErrorBoundary componentName={'DashboardIndex'}>
                  <DashboardIndex {...props}/>
                </ErrorBoundary>}
              />
              <Route exact path="/reports/duplicates" render={(props) =>
                <ErrorBoundary componentName={'DuplicateReport'}>
                  <DuplicateReport {...props}/>
                </ErrorBoundary>}
              />
            </Switch>
          </Suspense>
        </App>
    </Router>
  </Provider>
  , document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
